import React, { useContext, useState, useEffect, useCallback, useRef } from "react";
import AppContext from '../contexts/AppContext'
import { useHistory } from "react-router-dom";
import {decode} from 'html-entities';

import { 
    FormGroup,
    FormLabel,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Button, 
    Tooltip, 
    Slider, 
    Switch,
    ListItemIcon, 
    Menu, 
    MenuItem, 
    IconButton, 
    InputLabel,
    Icon,
    Select,
    TextField
} from '@mui/material'
import {CircularProgress, Box, LinearProgress} from '@mui/material';
import { 
    Check,
    CheckCircleOutline,
    CancelOutlined
} from '@mui/icons-material';
import { position } from "dom-helpers";
import CustomSwitch from "../components/settingComponents/CustomSwitch";
import GetMiddleMonths from "../functions/GetMiddleMonths";
import Information from "../components/dialogs/Information";
import TranslateBytes from "../functions/TranslateBytes";
import MonthToString from "../functions/MonthToString";

import { LoadingButton } from "@mui/lab";

const Account = () => {
    const _AppContext = useContext(AppContext)


    const history = useHistory();

    const [showResetPassword, setShowResetPassword] = useState(false);
    const [resetPasswordEmailSent, setResetPasswordEmailSent] = useState(0);

    const [fakeUserFeburaryBandwidthData, setFakeFeburaryUserBandwidthData] = useState([
       
        {
            totalBytes: 15038583,
            mmDdYy: '020122'
        },
        {
            totalBytes: 63029483,
            mmDdYy: '020322'
        },
        {
            totalBytes: 109283758,
            mmDdYy: '020422'
        },
        {
            totalBytes: 19048358,
            mmDdYy: '020622'
        },
        {
            totalBytes: 15038583,
            mmDdYy: '020722'
        },
        {
            totalBytes: 63029483,
            mmDdYy: '020822'
        },
        {
            totalBytes: 109283758,
            mmDdYy: '020922'
        },
        {
            totalBytes: 19048358,
            mmDdYy: '021122'
        },
        {
            totalBytes: 15038583,
            mmDdYy: '021222'
        },
        {
            totalBytes: 63029483,
            mmDdYy: '021522'
        },
        {
            totalBytes: 109283758,
            mmDdYy: '021622'
        },
        {
            totalBytes: 19048358,
            mmDdYy: '021722'
        },
        {
            totalBytes: 15038583,
            mmDdYy: '021822'
        },
        {
            totalBytes: 45837486,
            mmDdYy: '021922'
        }
    ])

    const [fakeUserJanuaryBandwidthData, setFakeJanuaryUserBandwidthData] = useState([
       
        {
            totalBytes: 15038583,
            mmDdYy: '010122'
        },
        {
            totalBytes: 15038583,
            mmDdYy: '010222'
        },
        {
            totalBytes: 63029483,
            mmDdYy: '010322'
        },
        {
            totalBytes: 15038583,
            mmDdYy: '010422'
        },
        {
            totalBytes: 63029483,
            mmDdYy: '010522'
        },
        {
            totalBytes: 15038583,
            mmDdYy: '010722'
        },
        {
            totalBytes: 15038583,
            mmDdYy: '010822'
        },
        {
            totalBytes: 15038583,
            mmDdYy: '010922'
        },
        {
            totalBytes: 63029483,
            mmDdYy: '011122'
        },
        {
            totalBytes: 15038583,
            mmDdYy: '011222'
        },
        {
            totalBytes: 15038583,
            mmDdYy: '011322'
        },
        {
            totalBytes: 63029483,
            mmDdYy: '011522'
        },
        {
            totalBytes: 15038583,
            mmDdYy: '011622'
        },
        {
            totalBytes: 63029483,
            mmDdYy: '011722'
        },
        {
            totalBytes: 15038583,
            mmDdYy: '011822'
        },
        {
            totalBytes: 63029483,
            mmDdYy: '011922'
        },
        {
            totalBytes: 109283758,
            mmDdYy: '012022'
        },
        {
            totalBytes: 19048358,
            mmDdYy: '012122'
        },
        {
            totalBytes: 15038583,
            mmDdYy: '012222'
        },
        {
            totalBytes: 63029483,
            mmDdYy: '012322'
        },
        {
            totalBytes: 109283758,
            mmDdYy: '012422'
        },
        {
            totalBytes: 19048358,
            mmDdYy: '012522'
        },
        {
            totalBytes: 15038583,
            mmDdYy: '012622'
        },
        {
            totalBytes: 63029483,
            mmDdYy: '012722'
        },
        {
            totalBytes: 109283758,
            mmDdYy: '012822'
        },
        {
            totalBytes: 19048358,
            mmDdYy: '012922'
        },
        {
            totalBytes: 15038583,
            mmDdYy: '013022'
        },
        {
            totalBytes: 45837486,
            mmDdYy: '013122'
        }
    ])


    const [incomingUsageData, setIncomingUsageData] = useState([])

    const translateMonth = (mm) => {
        switch (mm) {
            case '01':
            return 'January';
            case '02':
            return 'February';
            case '03':
            return 'March';
            case '04':
            return 'April';
            case '05':
            return 'May';
            case '06':
            return 'June';
            case '07':
            return 'July';
            case '08':
            return 'August';
            case '09':
            return 'September';
            case '10':
            return 'October';
            case '11':
            return 'November';
            case '12':
            return 'December';
        }
    }

    const [availableMonths, setAvailableMonths] = useState([])
    const [processedAvailableMonths, setProcessedAvailableMonths] = useState([])


  

    const [unixCreated, setUnixCreated] = useState(null)

    const [thisMonth, setThisMonth]= useState('00')

    const [selectedMonth, setSelectedMonth]= useState('00')
    const [selectedYear, setSelectedYear]= useState('22')

    useEffect(() => {
        let todayDate = new Date()
        let month = todayDate.getUTCMonth() + 1;
        setThisMonth(MonthToString(month))
        setSelectedMonth(MonthToString(month))


        let year = todayDate.getUTCFullYear();
        let yearString = year.toString().substring(2,4)
        setSelectedYear(yearString)

    }, [])

    const [showSavedCheck, setShowSavedCheck] = useState(false)
    const [savingChanges, setSavingChanges] = useState(false)
    
    const delayChangeSavingState = useCallback(
    async (value) => {
        return new Promise(resolve => {
            setTimeout(() => {
                setSavingChanges(value)
                setShowSavedCheck(true)
                resolve()
    
            }, 1000)
        })
        
    }, [setSavingChanges, setShowSavedCheck])

    const delayRemoveSavedCheck = useCallback(
        async (value) => {
            return new Promise(resolve => {
                setTimeout(() => {
                    setShowSavedCheck(false)
                    resolve()
                }, 3000)
            })
            
        }, [setShowSavedCheck])

    const [processedUserBandwidthData, setProcessedUserBandwidthData] = useState([])
    const [selectedMonthUsage, setSelectedMonthUsage] = useState(0)
    

    useEffect(() => {
        let filteredByYear = incomingUsageData.filter(x => x.mmDdYy.substring(4,6) == selectedYear)
        let filteredByMonth = filteredByYear.filter(x => x.mmDdYy.substring(0,2) == selectedMonth)
        let sortedBandwitdhData = filteredByMonth.sort((a, b) => b.mmDdYy - a.mmDdYy)
        
        setProcessedUserBandwidthData(sortedBandwitdhData)

    }, [selectedMonth, selectedYear, incomingUsageData])

    useEffect(async () => {
       

        let monthTotal = 0;
        processedUserBandwidthData.map( (x) => 
        monthTotal += x.totalBytes
        );
        setSelectedMonthUsage(monthTotal)
    }, [processedUserBandwidthData])



    useEffect(() => {
        setProcessedAvailableMonths(availableMonths.sort((a, b) => b.yyMm - a.yyMm))
    }, [availableMonths])

    const handleMonthChange = (yyMm) => {
        setSelectedMonth(yyMm.substring(2,4))
        setSelectedYear(yyMm.substring(0,2))
    }



    useEffect(() => {
        //get initial data for current month
        // TODO: fetch the actual data from the backend but for testing use the fake data

        let theFakeData = selectedMonth == '01' ? fakeUserJanuaryBandwidthData : fakeUserFeburaryBandwidthData
        setIncomingUsageData(theFakeData)

    }, [selectedMonth, fakeUserFeburaryBandwidthData, fakeUserJanuaryBandwidthData])

    useEffect(async () => {
        if (!unixCreated) {
            return
        }
        setAvailableMonths([])
        let startDate = new Date(unixCreated)
        let endDate = new Date()
        setThisMonth(translateMonth(endDate.getUTCMonth()))
        let middleMonths = await GetMiddleMonths(startDate, endDate)
        setAvailableMonths(middleMonths)
        return
    }, [setAvailableMonths, unixCreated])

    useEffect(() => {
        if (!_AppContext.userInfo_Octospin) {
            return
        }
        if (!_AppContext.userInfo_Octospin.createdUnixTimestamp) {
            return
        }
        setUnixCreated(_AppContext.userInfo_Octospin.createdUnixTimestamp)
    }, [_AppContext.userInfo_Octospin])





    const [ stripeCustomerData, setStripeCustomerData] = useState(null)


    useEffect( async () => {
        if (_AppContext.userInfo_Octospin) {
            if (_AppContext.userInfo_Octospin.stripeCustomerId  ) {
                console.log('cust id', _AppContext.userInfo_Octospin)
                try {
                    const accessToken = await _AppContext.getAccessTokenSilently({
                        audience: `https://dev-backend.prosp.in/`,
                    });
                    fetch(`${_AppContext.baseBackendUrl}/api/Payments/GetCustomerFromStripe/${_AppContext.userInfo_Octospin.id}`, {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${accessToken}`,
    
                        },
                    })
                    .then(res => { 
                        switch (res.status) {
                            case 200:
                                console.log(res)
                                return res.json();
                            
                            case 404:
                                console.log('not found', res)
                                break;
                            case 401:
                                console.log('not auth', res)
                                break;
                            default:
                                console.log(res)
                                break;
                        }
                    })
                    .then(data => { 
                        setStripeCustomerData(data)
                    } )
                    .catch(error =>history.push(`/error?message=${error}`))
                } catch (err) {
                    console.log(err)
                }
                
            }
        }
        // fetch to backend to determine whether default payment exists


    }, [_AppContext.userInfo_Octospin])
    
   
    
 
    const handleAutoPayChange =  useCallback(
    () => {
        if (_AppContext.userInfo_Octospin) {
            _AppContext.editOctospinUser('autoPayMonthlyInvoices', !_AppContext.userInfo_Octospin.autoPayMonthlyInvoices)
            console.log(_AppContext.userInfo_Octospin)
        }
    }, [_AppContext, _AppContext.userInfo_Octospin]
    )

    const handleShowOnInspireChange = useCallback(
        () => {
            if (_AppContext.userInfo_Octospin) {
                _AppContext.editOctospinUser('showOnInspireByDefault', !_AppContext.userInfo_Octospin.showOnInspireByDefault)
            }
        }, [_AppContext, _AppContext.userInfo_Octospin]
    )

    const [checking, setChecking] = useState(false)
    const [userNameExists, setUserNameExists] = useState(false)

    const delayChecking = useCallback(
        async (e) => {
            // if (checking === false) {
            //     return
            // }
            // validate uniqueness of username
            const accessToken = await _AppContext.getAccessTokenSilently({
                audience: `https://dev-backend.prosp.in/`,
            });
            const response = await fetch(`${_AppContext.baseBackendUrl}/api/User/ValidateUserName?newUserName=${e.target.value}`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,

                },
            })
            
            const data = await response.json()
            console.log(data, response.status)
            if (response.status === 200 ) {
                setUserNameExists(data.userNameExists)
                setChecking(false)

            }

            // setChecking(true)
            
        }, [checking, _AppContext, _AppContext.userInfo_Octospin]
        )

       

        const conformUsername = (username) => {
            const allowedList = ['a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r','s','t','u','v','w','x','y','z','_','0','1','2','3','4','5','6','7','8','9',]
            const conformed = Array.from(username).map(x => {
                let newChar = allowedList.includes(x) ? x : '';
                return newChar
            }).join('')
            return conformed
        }
    

    const handlePublicUserNameChange =  useCallback(
        async (e) => {
            if (_AppContext.userInfo_Octospin) {
                _AppContext.editOctospinUser('userName', e.target.value.toLowerCase())
            }
            if (checking === true) {
                return
            }
            setChecking(true)
            const waitForIt = setTimeout(
                () => {
                    
                    // setChecking(true)
                    delayChecking(e)
                    clearTimeout(waitForIt)
                    
                }, 2000
            )
            
        }, [checking, _AppContext, _AppContext.userInfo_Octospin]
        )

    const handlePublicEmailChange =  useCallback(
        (e) => {
            if (_AppContext.userInfo_Octospin) {
                _AppContext.editOctospinUser('publiclyVisibleEmail', e.target.value)
            }
        }, [_AppContext, _AppContext.userInfo_Octospin]
        )




        const handlePasswordChangeRequest = useCallback(
                async () => {
                    if (resetPasswordEmailSent == 1) {
                        return
                    }
                    setResetPasswordEmailSent(2)
                    const accessToken = await _AppContext.getAccessTokenSilently({
                        audience: `https://dev-backend.prosp.in/`,
                      });

                      if (!_AppContext.userInfo_Auth) {
                          return
                      }
                      if (!_AppContext.userInfo_Auth.email) {
                          return
                      }

                    fetch(`${_AppContext.baseBackendUrl}/api/User/ResetPassword`, {
                        method: 'POST',
                        // redirect: 'follow',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${accessToken}`,
        
                        },
                        // body: JSON.stringify(formData)
                    }).then(res => {
                        console.log(res)
                        return res.text()
                    })
                    .then(data => {
                        console.log(data)   
                        if (data.toLowerCase() === 'true') {
                            setResetPasswordEmailSent(1)
                        } else {
                            setResetPasswordEmailSent(0)

                        }
                    })
                }, [_AppContext, _AppContext.userInfo_Auth, resetPasswordEmailSent]
                )

    const saveUserChanges = useCallback( 
        async () => {
            setSavingChanges(true)


            const accessToken = await _AppContext.getAccessTokenSilently({
                audience: `https://dev-backend.prosp.in/`,
              });
            fetch(`${_AppContext.baseBackendUrl}/api/User/${_AppContext.userInfo_Octospin.id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,

                },
                body: JSON.stringify(_AppContext.userInfo_Octospin)
            }).then(async res => {
                await delayChangeSavingState(false)
                await delayRemoveSavedCheck()
            })
        },[_AppContext.userInfo_Octospin])


        const handleManageBillingClick = useCallback(
        async () => {
            const accessToken = await _AppContext.getAccessTokenSilently({
                audience: `https://dev-backend.prosp.in/`,
              });
            fetch(`${_AppContext.baseBackendUrl}/api/Payments/create-portal-session?returnUrl=${window.location.href}`, {
                method: 'POST',
                // redirect: 'follow',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,

                },
                // body: JSON.stringify(formData)
            }).then(res => {
                console.log(res)
                return res.text()
            })
            .then(data => {
                console.log(data)
                window.location.href = data

            })
        }, [_AppContext]
        )


useEffect(() => {
    if (!_AppContext.userInfo_Octospin) {
        return
    }
    if (_AppContext.userInfo_Octospin.id && _AppContext.userInfo_Octospin.id.substring(0,5).toLowerCase() === "auth0") {
        setShowResetPassword(true)
    } else {
        setShowResetPassword(false)

    }
}, [_AppContext.userInfo_Octospin])

    return (
        <div
        style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'

        }}
        >

            <div
            style={{
                width: '100%',
                maxWidth: '800px',
                marginBottom: '20px'

    
            }}
            >
            
            <div
                style={{
                padding: '10px',
                position: 'sticky',
                    top: 0,
                    background: '#fff',
                    width: 'calc(100% - 20px)',
                    borderBottom: '1px solid rgb(192, 192, 192)',
                    marginBottom: '20px',
                    zIndex: 100,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
                >
                    <div
                    style={{
                        position: 'sticky',
                            width: 'calc(100% - 20px)',
                            maxWidth: '700px',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-end',
                            alignItems: 'center'
                        }}
                    >


           
                <LoadingButton 
                loading={savingChanges || showSavedCheck ? true : false} 
                disabled={showSavedCheck == true ? true : false}
                onClick={saveUserChanges}
                style={{
                    background: savingChanges || showSavedCheck ? '#fff' : '#28a745',
                    color: savingChanges || showSavedCheck ? 'transparent' : '#fff',
                    border: '1px solid #28a745',
                    transition: 'background 500ms, color 500ms'
                  }}
                loadingIndicator={ showSavedCheck == false ?
                   
                    <CircularProgress sx={{color: showSavedCheck == false ? '#28a745' : 'transparent', transition: 'color 500ms'}} size={16} />
                    :
                    <Check sx={{color: showSavedCheck == false ? 'transparent' : '#28a745', transition: 'color 500ms'}} size={16}/>
                  
                    
                }               
                variant="contained">
                Save
                </LoadingButton>

                    {/* <Button variant="contained" onClick={saveUserChanges}>Save Changes</Button> */}

                    </div>

                    </div>

            { _AppContext.loadingAuth === false && _AppContext.isAuth === true && _AppContext.userInfo_Octospin &&
            <div className="accountSectionOverarch">
                
                <div className="accountSection">
                    <div className="accountSectionHeader">
                        Usage
                        </div>
                    <div className="accountSectionContent"
                    style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'space-between',
                    
                        
                    }}
                    >
                        <div className="usageGlance">

                        { selectedMonth == thisMonth &&
                            <div
                            style={{
                                display: 'flex',
                                direction: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                fontWeight: '600',
                                gap: '8px'
                            }}
                            >
                            {/* // TODO: replace with actual limit */}
                            
                            {`Available Bandwidth ${100 - Math.round(selectedMonthUsage/100000000000)}%`}

                            <CircularProgress 
                            variant="determinate" 
                            size={20}
                            thickness={6}
                            value={100 - Math.round(selectedMonthUsage/100000000000)} 
                            sx={{
                                color: 100 - Math.round(selectedMonthUsage/100000000000) > 50 ? 'green' : 100 - Math.round(selectedMonthUsage/100000000000) > 25 ? 'orange' : 'red'
                            }}
                            />
                            

                            </div>

                            }

                        <FormControl 
                            sx={{
                            width: '100%',
                            maxWidth: '200px'
                            }}>
                                    <InputLabel 
                                    className="customSettingsSelect"
                                    
                                    >Select a Month</InputLabel>
                                    <Select
                                    value={`${selectedYear}${selectedMonth}`}
                                    // value=""
                                    // defaultValue=""
                                    size="small"
                                    label="Use saved setting"
                                    // onChange={handleSelectChange}
                                    sx={{
                                        minWidth: '200px',
                                        textOverflow: 'ellipsis'
                                    }}
                                    onChange={(e) => {
                                        handleMonthChange(e.target.value)
                                    }}
                                    >
                                        {
                                            processedAvailableMonths.length > 0 &&
                                            processedAvailableMonths.map(x =>
                                                <MenuItem value={x.yyMm}>
                                                    {`${translateMonth(x.yyMm.substring(2,4))} 20${x.yyMm.substring(0,2)}`}
                                                    
                                                    </MenuItem>
                                            )
                                        }
                                    {/* { _EditContext.userCustomSettingsFromDB &&
                                        _EditContext.userCustomSettingsFromDB.map(x => 
                                        <MenuItem value={x}>{x.customSetting.name}</MenuItem>
                                        )
                                    } */}
                                    </Select>
                                </FormControl>
                        </div>

                        <div
                        
                        >
                            

                            




                            

                        </div>

                        <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: '8px',
                            margin: '12px 0px',
                            minHeight: '120px'
                        }}
                        >
                            <div
                            style={{fontWeight: 600}}
                            >
                            {`${translateMonth(selectedMonth)}'s Usage`}

                            </div>
                            {processedUserBandwidthData.length > 0 ? 
                            <>
                            <div>
                            {`You ${ selectedMonth == thisMonth ? 'have used' : 'used'} ${TranslateBytes(selectedMonthUsage)} of your 100GB monthly limit.`}

                            </div>

                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                minHeight: '200px',
                                height: '200px',
                                resize: 'vertical',
                                transition: 'max-height 1s ease-out',
                                maxWidth: '500px',
                                width: '100%',
                                overflow: 'auto',
                            }}>
                                <div style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            position: 'sticky',
                                            top: 0,
                                            width: '100%',
                                            background: '#fff'

                                        }}>
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            flexWrap: 'wrap',
                                            gap: '20px',
                                            maxWidth: '400px',
                                            width: '100%',
                                            padding: '8px',
                                            flexDirection: 'row',
                                            fontWeight: 600

                                        }}>
                                            <div>
                                                Day
                                            </div>
                                            <div>
                                                Usage
                                            </div>
                                            </div>
                                        </div>

                                {processedUserBandwidthData.map((x, index) => {
                                    return (
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            
                                            width: '100%',
                                            background: index % 2 > 0 ? 'transparent' : '#eee'

                                        }}>
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            flexWrap: 'wrap',
                                            gap: '20px',
                                            maxWidth: '400px',
                                            width: '100%',
                                            padding: '8px',
                                            flexDirection: 'row',

                                        }}>
                                            <div>
                                                {`${translateMonth(x.mmDdYy.substring(0,2))} ${x.mmDdYy.substring(2,4)}, 20${x.mmDdYy.substring(4,6)}`}
                                            </div>
                                            <div>
                                                {TranslateBytes(x.totalBytes)}
                                            </div>
                                            </div>
                                        </div>

                                    )
                                    
                                })
                            
                            }
                            </div>
                            </>
                            :
                            <div style={{width: '100%', textAlign: 'center'}}>
                                No data available.
                                </div>
                        }
                        </div>

                       
                        
                       
                    </div>
                    
                </div>

               
                    <div className="accountSection">
                        <div className="accountSectionHeader">
                            Public Information
                            </div>
                        <div className="accountSectionContent">
                        { _AppContext.userInfo_Octospin.showOnInspireByDefault ? 'Show my spins on Inspire: Yes' : 'Show my spins on Inspire: No'}
                                
                                <Switch 
                        className="basicSwitch"
                        size="medium"
                        checked={ _AppContext.userInfo_Octospin.showOnInspireByDefault}
                        onChange={handleShowOnInspireChange}
                        // color="primary"
                        />
                        {_AppContext.userInfo_Octospin.showOnInspireByDefault &&
                        <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '16px'
                        }}
                        >
                            <Information
                            value="This is used for spins that are shown on the inspire page. You can choose to show spins on inspire on a spin by spin basis through their edit page. "
                            />

                    
                                <div>
                            <TextField 
                            value={decode(_AppContext.userInfo_Octospin.publiclyVisibleEmail)} 
                            onChange={handlePublicEmailChange} 
                            size="small"
                            sx={{
                                maxWidth: '300px', 
                                width: '100%'
                            }}
                            label="Publicly Visible Email or Website" 
                            variant="outlined" />
                                </div>

                            <div style={{position: 'relative', maxWidth: '300px', width: '100%'}}>
                                <TextField 
                                value={conformUsername(decode(_AppContext.userInfo_Octospin.userName).toLowerCase())} 
                                onChange={handlePublicUserNameChange} 
                                size="small"
                                sx={{
                                    width: '100%'
                                }}
                                className="userNameChangeTextField"
                                label="Username" 
                                variant="outlined" />
                                <div 
                                title={checking == true ? "Checking if username is available" : userNameExists ? "Username not available" : "Username Available"}
                                style={{position: 'absolute', top: '50%', transform: 'translateY(-50%)', right: '4px'}}>
                                    <CircularProgress  size={20} style={{display: checking ? 'flex' : 'none', color: '#0094ff', marginRight: '2px'}} />
                                    <CheckCircleOutline style={{display: userNameExists === true || checking == true ? 'none' : 'flex', paddingTop: '0px', color: '#009706', height: '24px', width: '24px'}}/>
                                    <CancelOutlined   style={{display: userNameExists === true && checking == false  ? 'flex' : 'none', paddingTop: '0px', color: '#d32121', height: '24px', width: '24px'}}/>
                                    
                                </div>
                            </div>
                            
                        </div>
                        }
                    </div>
                            
                        
                    </div>
            

                <div className="accountSection">
                    <div className="accountSectionHeader">
                        Private Information
                        </div>
                    <div className="accountSectionContent">
                        <div>
                            This info is visible only to Octospin and you.
                            
                            </div>

                            <div>
                        Subscription Product Id: {_AppContext.userInfo_Octospin.subscriptionProductId}
                        </div>
               


                  
                        
                        <div>Email: {_AppContext.userInfo_Octospin.email}</div>
                        <div>Email Verified: {JSON.stringify(_AppContext.userInfo_Auth.email_verified)}</div>
                        <div>Id: {_AppContext.userInfo_Octospin.id}</div>

                        { showResetPassword &&
                        <>
                        <Button 
                        size="small"
                        variant={resetPasswordEmailSent === 0 ? 'contained' : 'outlined'}
                        style={{
                            pointerEvents: resetPasswordEmailSent === 1 ? 'none' : 'unset'
                        }}
                        onClick={handlePasswordChangeRequest}>
                            {resetPasswordEmailSent === 0 ? 'Reset Password' : resetPasswordEmailSent === 1 ?  'Sent!' : 'Sending...'}
                            </Button>
                            <div>
                            {resetPasswordEmailSent === 1 ? 'Please check your email for a password reset email.' : ''}

                            </div>
                            </>
                        }

                    </div>
                    
                </div>
                
                {
                _AppContext.userInfo_Octospin.stripeCustomerId &&

                <div className="accountSection">
                    <div className="accountSectionHeader">
                        Billing
                        </div>
                    <div className="accountSectionContent">
                       
                <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '16px'

                }}
                >

                    <div>
                    <Button 
                    variant="contained" 
                    onClick={handleManageBillingClick} 
                    type="submit"
                    
                    >
                        Manage Billing
                    </Button>
                    </div>

                    <div>
                    Auto-pay monthly invoices with default payment on file
          
                    { 
                            stripeCustomerData && stripeCustomerData.invoice_settings &&
                     
                        <div>
                           
                            <FormControl component="fieldset" variant="standard">
                            <FormGroup>
                             
                                <FormControlLabel
                                control={
                                    <Switch 
                                    checked={_AppContext.userInfo_Octospin.autoPayMonthlyInvoices ? _AppContext.userInfo_Octospin.autoPayMonthlyInvoices : false} 
                                    disabled={
                                        stripeCustomerData 
                                        && stripeCustomerData.invoice_settings 
                                        && stripeCustomerData.invoice_settings.default_payment_method 
                                        && stripeCustomerData.invoice_settings.default_payment_method.length > 0 
                                        ? false : true}
                                    onChange={handleAutoPayChange} 
                                    name="auto-pay" 
                                    />
                                }
                                label="Auto-pay"
                                />
                                
                               
                                             
                            </FormGroup>
                    </FormControl>
                          
                            { 
                            !stripeCustomerData.invoice_settings.default_payment_method &&
                                <div>
                                    {'You do not have a default payment method selected. Please go to Billing Portal > Payment Methods > Three Dots > Make Default. Then, refresh this page and you will be able to enabled automatic invoice payment. If you do not see "Payment Methods" or a payment method listed, please contact us. '}
                                </div>
                            }
                        </div>
                    }
                    </div>
                    </div>

                    </div>
                    
                </div>
            }
                
                


                


                <div>

                    <div>
                
                    </div>
                
                </div>


                <div>
                </div>
                
                
            </div>
            }


            </div>
            
        </div>
    )
};


export default Account;