const TranslateCreatedDate = (milliseconds) => {
    let created = new Date(milliseconds)
    let now = Date.now()
    if (now - milliseconds < 86400000) {
        return 'Today';

    }
    if (now - milliseconds < 172800000) {
        return 'Yesterday';

    }
    return created.toLocaleDateString();
}

export default TranslateCreatedDate;