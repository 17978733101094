import React, { useCallback, useContext, useState } from "react";
import { Link } from "react-router-dom";
import LoginButton from "./LoginButton";
import LogoutButton from "./LogoutButton";
import AppContext from '../contexts/AppContext'

import { useAuth0 } from "@auth0/auth0-react";

import LogoAnimated from "../assets/LogoAnimated";

import {
    Avatar,
    Menu,
    MenuItem,
    ListItemIcon,
    IconButton,
    Tooltip,
    Button,

} from '@mui/material'

import {
    Logout,
    List,
    InfoOutlined,
    LightbulbOutlined,
    QuestionAnswerOutlined,
    HomeOutlined,
    HelpOutline,
    PreviewOutlined,
    AddCircle,
    Menu as MenuIcon
} from '@mui/icons-material'



const NavBar = () => {



    const _AppContext = useContext(AppContext)

    const [anchorElNav, setAnchorElNav] = React.useState(null);

    const [anchorElUser, setAnchorElUser] = useState(null);
    const openNav = Boolean(anchorElNav);
    const handleOpenUserMenu = (event) => {
      setAnchorElUser(event.currentTarget);
    };
    const handleCloseUserMenu = () => {
      setAnchorElUser(null);
    };

const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };


  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };


    const { loginWithRedirect } = useAuth0();
    const { logout } = useAuth0();

    const feedbackUrl = 'https://docs.google.com/forms/d/e/1FAIpQLSezgwaEmqy9jnhLJ0vczsepTZQq6-eJB43mFfrQwdZGC4GIEA/viewform?usp=sf_link'

    const FeedbackButton = () => (
        <Link to={{pathname: feedbackUrl}} target="_blank">Feedback</Link>
    
    )
    
    
    const pagesAuth = [
                
      {full: <Link to='/'>Home</Link>, collapsed:<MenuItem component={Link} to="/" ><HomeOutlined/>Home</MenuItem>},
      {full: <Link className="createButtonFull" to='/create'><AddCircle/><span>Create</span></Link>, collapsed:<MenuItem className="createButtonInMenu" component={Link} to="/create" ><AddCircle/>Create</MenuItem>},
      {full: <Link to='/myspins'>My Spins</Link>, collapsed:<MenuItem component={Link} to="/myspins" ><List/>My Spins</MenuItem>},
      {full: <Link to='/pricing'>Pricing</Link>, collapsed:<MenuItem component={Link} to="/pricing" ><InfoOutlined/>Pricing</MenuItem>},
      {full: <Link to='/inspire'>Inspire</Link>, collapsed:<MenuItem component={Link} to="/inspire" ><LightbulbOutlined/>Inspire</MenuItem>},
      {full: <Link to='/howto'>How To</Link>, collapsed:<MenuItem component={Link} to="/howto" ><HelpOutline/>How To</MenuItem>},
      {full: <Link to={{pathname: feedbackUrl}} target="_blank">Feedback</Link>, collapsed:<MenuItem component={Link} to={{pathname: feedbackUrl}} target="_blank"><QuestionAnswerOutlined/>Feedback</MenuItem>},
      
    ];
    
    
    
    const pagesUnauth = [
                
      {full: <Link to='/'>Home</Link>, collapsed:<MenuItem component={Link} to="/" ><HomeOutlined/>Home</MenuItem>},
      {full: <Link to='/pricing'>Pricing</Link>, collapsed:<MenuItem component={Link} to="/pricing" ><InfoOutlined/>pricing</MenuItem>},
      {full: <Link to='/demo'>Demo</Link>, collapsed:<MenuItem component={Link} to="/demo" ><PreviewOutlined/>Demo</MenuItem>},
      {full: <Link to='/inspire'>Inspire</Link>, collapsed:<MenuItem component={Link} to="/inspire" ><LightbulbOutlined/>Inspire</MenuItem>},
      {full: <Link to='/howto'>How To</Link>, collapsed:<MenuItem component={Link} to="/howto" ><HelpOutline/>How To</MenuItem>},
      {full: <Link to={{pathname: feedbackUrl}} target="_blank">Feedback</Link>, collapsed:<MenuItem component={Link} to={{pathname: feedbackUrl}} target="_blank" ><QuestionAnswerOutlined/>Feedback</MenuItem>},
      
    ];
  

  return (
    <div className="primaryNavBarContainer" >
      
    <div className="primaryNavBar" >
            
<div
          className="leftNavWrapper"
          >
<div
          className="collapsedPageList"
          
          >
            <IconButton size="medium" aria-controls="menu-appbar" aria-haspopup="true" onClick={handleOpenNavMenu} color="inherit" >
              <MenuIcon />
            </IconButton>
            <Menu
            className="pagesMenuSmallScreen"
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              onClick={handleCloseNavMenu}

              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              { _AppContext.isAuth && pagesAuth.map((page, index) => page.collapsed)}
              { !_AppContext.isAuth && pagesUnauth.map((page, index) => page.collapsed)}
            </Menu>
          </div>

          <Link to='/' 
          className="logoTitle"
          >
      <LogoAnimated dimensions={{height: '32px', width: '40px'}}/>
        Octospin
      </Link>
          
          <div 
          className="fullPageList"
          >
          { _AppContext.isAuth && pagesAuth.map((page, index) => (
           
              <div key={index} className="linkItem">
              {page.full}
              </div>
                
              ))}

            { !_AppContext.isAuth && pagesUnauth.map((page, index) => (
            

              <div key={index} className="linkItem">
              {page.full}
              </div>
               
            ))}


          </div>
</div>
<div 
          // sx={{ flexGrow: 0 }}
          >
          { !_AppContext.isAuth && 
<>
<Button onClick={() => loginWithRedirect({ returnTo: window.location.origin })} > 
Log In
</Button>

  <Button style={{background: '#ff7900'}} size="small" variant="contained" onClick={() => loginWithRedirect({ screen_hint: 'signup', returnTo: window.location.origin })} >
    Sign Up
    </Button>
            
            </>
          }
          { _AppContext.isAuth && 
          <div
          style={{
            display: 'flex',
            gap: '8px'
          }}
          >
          <Link className="createButtonFull crateButtonSeparate" to='/create'><AddCircle/><span>Create</span></Link>
          <Tooltip title="Account settings">
           <IconButton onClick={handleOpenUserMenu} size="small" >
             <Avatar sx={{ width: 32, height: 32 }}>
              {_AppContext && _AppContext.userInfo_Octospin && _AppContext.userInfo_Octospin.userName && _AppContext.userInfo_Octospin.userName.length > 0 
              ? 
              _AppContext.userInfo_Octospin.userName.charAt(0).toUpperCase()
              : 
              null
            }
             </Avatar>
           </IconButton>
         </Tooltip>
         
       <Menu
        anchorEl={anchorElUser}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
        onClick={handleCloseUserMenu}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
          <MenuItem
        component={Link}
        to="/account"
        >
          <Avatar>
          {_AppContext && _AppContext.userInfo_Octospin && _AppContext.userInfo_Octospin.userName && _AppContext.userInfo_Octospin.userName.length > 0 
              ? 
              _AppContext.userInfo_Octospin.userName.charAt(0).toUpperCase()
              : 
              null
            }
            </Avatar> 
          My account
        </MenuItem>

   
        
        {/* <Divider /> */}
       
     

            <MenuItem
            onClick={() => logout({ returnTo: window.location.origin })}
            >
            <ListItemIcon>
                <Logout fontSize="small" />
            </ListItemIcon>
            Logout
            </MenuItem>
        
            </Menu>
            </div>
          }
          </div>
    </div>
    </div>
  );
};

export default NavBar;