


import React, { useContext, useState, useCallback, useEffect } from "react";

import {Backdrop, Button} from '@mui/material';

import {AddToPhotosOutlined, IosShareOutlined, ShareOutlined} from '@mui/icons-material'

import ViewSpin from "../spin/ViewSpin";
import AppContext from "../../contexts/AppContext"
import { internal_resolveProps } from "@mui/utils";

const Introduction = (props) => {
    const _AppContext = useContext(AppContext)

    const iconStyle = {
        fontSize: '1.2em !important',
        height: '1.2em !important',
        width: '1.2em !important'
    }

    const actions = [
        {title: 'Create', description: <>Upload images to create & edit 360&#xb0; interactive content</>, icon: <AddToPhotosOutlined sx={iconStyle}/>},
        {title: 'Share', description: 'Share spins via links or embed directly in your website', icon: <IosShareOutlined sx={iconStyle}/>},
        {title: 'Engage', description: 'Provide a way for customers to interact with and see your product', icon: <ShareOutlined sx={iconStyle}/>},
    ]

    return (
        <div 
        style={{
            color: '#333',
            fontSize: '36px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '60px',
            width: '100%',
            maxWidth: '1000px'
        }}
        >
            <div>
            Create interactive 360&#xb0; spins
            </div>

            <div
            style={{
                color: '#333',
                fontSize: '36px',
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                gap: '20px',
                rowGap: '40px',
                width: '100%',
                justifyContent: 'space-evenly',
                alignItems: 'center',
            }}
            >

                {
                    actions.map(item => 
                    <div
                    style={{
                        maxWidth: '200px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                    >
                        {item.icon}
  
                        <div style={{fontSize: '1.4rem' }}>
                            {item.title}
                        </div>
                        <div style={{fontSize: '1rem', marginTop: '20px'}}>
                            {item.description}
                        </div>
                    </div>
                    )
                }


            </div>
        </div>

        
    
    
    );
};

export default Introduction;