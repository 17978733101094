import React, { useCallback, useEffect, useRef, useState } from "react";

import { 
    Settings, 
    ZoomIn, 
    ZoomOut, 
    CompareArrows,
    Palette,
    Undo,
    Done,
    Colorize,
    Close
} from '@mui/icons-material';
import { 
    FormGroup,
    FormLabel,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Button, 
    Tooltip, 
    Slider, 
    Switch,
    ListItemIcon, 
    Menu, 
    MenuList,
    MenuItem, 
    IconButton, 
    Icon ,
    Snackbar
} from '@mui/material'

import { HexColorPicker, HexColorInput } from "react-colorful";


function ColorPicker(props) {

    const [persist, setPersist] = useState(false)
    const [showSnack, setShowSnack] = useState(false)

    const [throttle, setThrottle] = useState(false)

    



   var {color, getColorSetting, handleClick, handleChange, settingName, handleCancel, handleClose, anchor, open, title} = props
    return (
        <div>   
       

            <button
            tabIndex={0}
                id="color-button"
                aria-controls="basic-menu"
            aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={(e) => handleClick(e, color)}
            className="cursorSelectButton"
            >
                <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    // padding: '8px 12px 8px 5px'

                }}
                >
                    <div>
                    {title}
                    </div>
                    <div
                    style={{
                        // border: '2px solid rgba(100, 100, 100, .3)',
                        boxShadow: '0px 0px 4px -1px rgba(50, 50, 50, .8)',
                        height: '1.2em',
                        width: '1.2em',
                        borderRadius: '50%',
                        background: color
                    }}
                    >
                    
                    </div>
                </div>
            </button>



            <Menu
            id="color-menu"
            anchorEl={anchor}
            open={open}
            // onClose={(e) => handleCancel(settingName)}
            onClose={() => {
                setPersist(true)
                setShowSnack(true)
            }}
            
            PaperProps={{
                sx: { 
                    outline: persist === true ? '4px solid #a16bd6' : 'none',
                    borderRadius: '10px'
                }
            }}
         
            
            MenuListProps={{ 'aria-labelledby': 'basic-button',
            sx: {
                padding: 0
            }
        }}
            anchorOrigin={{ vertical: 'top', horizontal: 'left'}}
            transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            >
              
                <div 
                className="colorPickerInnerWrapper"
                onClick={() => {
                    setPersist(false)

                }}
                >
                <div>

                <HexColorPicker color={color} onChange={(value) => {
                    setThrottle(true)
                    setTimeout(() => {
                        setThrottle(false)
                    }, 100)
                    if (throttle === false) {
                        handleChange(value, settingName)
                    }
                    }} />
                </div>
                <div>

                <HexColorInput className="hexColorInput" prefixed color={color} onChange={(value) => {handleChange(value, settingName)}} />
                {window.EyeDropper && 
                <IconButton
                title="Pick color with eyedropper (experimental)" 
                onClick={() => {
                    const eyeDropper = new window.EyeDropper();

                    eyeDropper.open().then(result => {
                        handleChange(result.sRGBHex, settingName)
                    }).catch(e => {
                        console.log(e)
                    });
                }}
                size="small"

                style={{color: "#555"}}> <Colorize/> </IconButton>
                }
                
                <div
                style={{
                    display: 'flex',
                    gap: '8px',
                    flexDirection: 'row'
                }}
                >
                
                    <IconButton 
                    onClick={() => {
                        setPersist(false)
                        handleCancel(settingName)
                    }}
                    size="small"

                    title="Cancel" style={{color: 'grey'}}> <Close/> </IconButton>
                    <IconButton title="Set color change" 
                    onClick={() => {
                        setPersist(false)
                        handleClose()
                    }} 
                    className="confirmColorButton"
                    size="small"
                     > <Done/> </IconButton>
                </div>
                </div>
                </div>
                    
               
                
            </Menu> 
            
            <Snackbar
            open={showSnack}
            autoHideDuration={4000}
            onClose={() => {
                setShowSnack(false)
            }}
            message="Please confirm or cancel color selection."
            />
            


    </div>
    );
  }

export default ColorPicker;