import React, { useCallback, useEffect, useRef, useState } from "react";

import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';


function CursorSelect(props) {
    const {handleCursorChange, currentValue} = props

    const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const [closing, setClosing] = useState(false)

  const handleClose = () => {
    setClosing(true)
    let close = setTimeout(() => {
        setAnchorEl(null);
        clearTimeout(close)

    }, 300)
    let closeFollowup = setTimeout(() => {

        setClosing(false)

    }, 500)
  };

  const handleSelect = (value) => {
    handleClose()
    handleCursorChange(value)
  }

  const cursorOptions = [
    {name: 'Grab', value: 'grab'},
    {name: 'Arrows', value: 'ew-resize'},
    {name: 'Pointer', value: 'pointer'},
    {name: '360 Pointer (black)', value: `url(../../public_assets/cursors/360PointerBlack.svg) 0 0, pointer`},
    {name: '360 Pointer (white)', value: `url(../../public_assets/cursors/360PointerWhite.svg) 0 0, pointer`},
    {name: '360 Pointer (blue)', value: `url(../../public_assets/cursors/360PointerBlue.svg) 0 0, pointer`},
    {name: '360 Pointer (purple)', value: `url(../../public_assets/cursors/360PointerPurple.svg) 0 0, pointer`},
    
]


    return (
        <div>
            <button
            tabIndex={0}
            aria-controls="basic-menu"
            aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            className="cursorSelectButton"
            >
                <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    // padding: '8px 12px 8px 5px'

                }}
                >
                    <div>
                        Cursor Style
                    </div>
                    <div>
                        {cursorOptions.find(c => c.value === currentValue).name}
                    
                    </div>
                </div>
            </button>

            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                'aria-labelledby': 'basic-button',
                }}
                style={{
                    opacity: closing ? 0 : 1,
                    transition: 'opacity 300ms',
                }}
                    
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
            >
                { cursorOptions && 
                    cursorOptions.map(x => 
                    <MenuItem
                    key={x.name}
                    style={{
                        cursor: x.value,
                        background: currentValue === x.value && '#8800ff1f'
                    }}
                    className="cursorOptionsMenuItem"
                    
                    autoFocus={currentValue === x.value ? true : false}
                    value={x.value}
                    onClick={() => handleSelect(x.value)}
                    >{x.name}</MenuItem>
                    )
                }
            </Menu>
        </div>
    );
  }

export default CursorSelect;