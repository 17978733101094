const TranslateProductId = (productId) => {
    console.log('translate', productId)
    switch(productId) {
        case 'prod_KX8Ih7c7XvJ9M2':
            return 'advanced';
        case 'prod_KX8KOsBAYgfk8Z':
            return 'premium';
        case 'prod_KX8Lge265kHvgH':
            return 'ultimate';
        case 'free':
            return 'basic';
        default:
            return 'basic';
    }
}

export default TranslateProductId;