import React, { useContext } from "react";
import { Link } from "react-router-dom";

const TutorialHome = () => {

    return (
        <div>
            This is the home of the tutorials
            <Link to="/howtoarticle/6">How to do this one thing 6</Link>
            <div
            dangerouslySetInnerHTML={{__html: `<h1>Test</h1>`}}
            ></div>
        </div>
    )
};

export default TutorialHome;