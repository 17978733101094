import React, { useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import Interact from '../components/Interact'
import useWindowDimensions from "../custom-hooks/useWindowDimensions"; 
import SpinContext from "../contexts/SpinContext";
import ViewSpin from '../components/spin/ViewSpin'

import { SentimentVeryDissatisfied } from "@mui/icons-material";

import {
  useLocation
} from "react-router-dom";

function useQuery() {
    const { search } = useLocation();
  
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }


const ErrorPage = (props) => {

    let query = useQuery();
    
    const generateFeedbackUrl = `https://docs.google.com/forms/d/e/1FAIpQLSezgwaEmqy9jnhLJ0vczsepTZQq6-eJB43mFfrQwdZGC4GIEA/viewform?usp=pp_url&entry.1608498305=Error Message: ${query.get("message")}`


    return (

        <div className="errorPageOverarch">

        <div className="errorWrapper">
            <div className="errorTitle">
                :( Oh no!

            </div>
            
            <div className="errorInnerWrapper">
                <div style={{
                    marginBottom: '10px'
                }}>
                It looks like we hit an error. The message below may provide context.
                If you need help, please submit <a target="_blank" href={generateFeedbackUrl}>this feedback form</a>.
                    </div>
                    <div>
                    {query.get("message")}

                    </div>
                
            </div>
           
            </div>
            </div>
        
    )
};

export default ErrorPage;