import React, { useEffect, useState } from "react";
import {CircularProgress, Typography, Box} from '@mui/material';

const ProgressLoad = (props) => {
  const {color, width, height} = props;

  const [noSpins, setNoSpins] = useState(false)

  useEffect(() => {
      let delayNoSpins = setTimeout( () => {
        setNoSpins(true)
      }, 4000)

      return () => {
        setNoSpins(false)
        clearTimeout(delayNoSpins)
      }
  }, [])

  return (
      <>

{ noSpins === true ?
    <div>
        No spins found
        </div>
        :
        <CircularProgress
        style={{color: color ? color : '#1976d2', width: width ? width : '24px', height: height ? height : '24px'}}
        />

}
</>


  );
};

export default ProgressLoad;