import React, { useContext, useEffect, useState } from "react";
import { snackbarContentClasses, IconButton } from "@mui/material";
import UpdateImagesContext from "../../contexts/UpdateImagesContext";


import { 

    Close
  } from '@mui/icons-material';



import { SortableImage } from "./SortableImage";

export function WrappedImage(props) {

    const {x, index, currentlyDragging, mode} = props;

    const _UpdateImagesContext = useContext(UpdateImagesContext)

    const [removed, setRemoved] = useState(false)

    const removeImage = (id) => {
        setRemoved(true)
        const delayRemoval = setTimeout(() => {
          // handleCorrectionIndex()
            _UpdateImagesContext.changeUploadedFiles(prev => prev.filter(x => x.id !== id))

            clearTimeout(delayRemoval)
        }, 500)
      }

      const [imageLoaded, setImageLoaded] = useState(false)
      const handleImageLoaded = () => {
        setImageLoaded(true)
      }

  
  
      return (
<div 
className="sortableImageWrapper"
style={{
    opacity: imageLoaded == true && removed == false ? 1 : 0,
    transition: 'opacity 500ms ease 0s'
}}

>

<div 
className="indexSortableImage"
style={{
  visibility: imageLoaded == true && currentlyDragging === false && _UpdateImagesContext.contextFiles.length > 1 ? 'visible' : 'hidden',

}}
>
    {index + 1}

    </div>
<IconButton
className="removeButtonSortableImage"
  disabled={mode === 'edit' && _UpdateImagesContext.contextFiles.length === 1 ? true : false}
  title={mode === 'edit' && _UpdateImagesContext.contextFiles.length === 1 ? '' : 'Click to remove image'}
  onClick={() => {removeImage(x.id)}}
  sx={{
      visibility: imageLoaded !== true || currentlyDragging === true ? 'hidden' : _UpdateImagesContext.contextFiles.length > 1 ? 'visible' :  _UpdateImagesContext.contextFiles.length <= 1 && mode === 'edit' ? 'hidden' : 'visible',
      padding: '8px',
      borderRadius: '0px 10px',
      background: 'rgba(255, 255, 255, .8) !important',
      userSelect: 'none'
      

  }}
  style={{}}
  >
    {/* this is the icon name */}
  <Close
  sx={{
    fontSize: '1rem',
    height: '1em',
    width: '1em',
    color: '#222'
  }}
  />
</IconButton>

  <SortableImage key={x.id} id={x.id} 
  handleImageLoaded={handleImageLoaded}
  // cloudflareId={x.cloudflareId}
  imgUrl={x.url}
  />
  </div>

      );
}
