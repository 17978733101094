import React, { useState, useEffect, useCallback, useContext, useRef } from "react";
import SpinContext from "../contexts/SpinContext";
import AppContext from "../contexts/AppContext";
import SettingsMenu from "./SettingsMenu";
import { Settings, ZoomIn, ZoomOut, ArrowForwardIos, ArrowBackIos } from '@mui/icons-material';
import { IconButton, Button, Hidden } from '@mui/material'

import CorrectNumber from "../functions/CorrectNumber";
import CorrectNumberBounce from "../functions/CorrectNumberBounce";
import ArrayOrderSort from "../functions/ArrayOrderSort";

import LoadingSpinnerCenter from "./LoadingSpinnerCenter";


import { useHistory } from 'react-router-dom';
import {decode} from 'html-entities';
import LogoAnimated from "../assets/LogoAnimated";
import ZoomView from "./spin/ZoomView";
import HandleLocalStorageToken from "../functions/HandleLocalStorageToken";

const Interact = (props) => {
    // Yyou must pass dimensions {height: number, width: number} as prop
    
    const _SpinContext = useContext(SpinContext)
    const _AppContext = useContext(AppContext)
    const history = useHistory();
    const imgRef = useRef(null)
    const dimLayerRef = useRef(null)
    const [loadImagePostionSet, setLoadImagePostionSet] = useState(false)
    const [dragDirection, setDragDirection] = useState(1)
    const dragFactorArray = [ 528, 264, 128, 64, 32, 16, 8, 4, 2, 1 ]
    const [dragFactor, setDragFactor] = useState(32)
    const [imagePosition, setImagePosition] = useState(0)
    const [localSelectedImage, setLocalSelectedImage] = useState()
    const [prevClientX, setPrevClientX] = useState(0)
    const [isListeningForDoubleTap, setIsListeningForDoubleTap] = useState(false)
    const [scale, setScale] = useState(1)
    const [draggableTop, setDraggableTop] = useState(0)
    const [draggableLeft, setDraggableLeft] = useState(0)

    const controlOverflowOn = () => {
        document.querySelector('body').style.overflow = 'hidden'
        document.querySelector('body').style.touchAction = 'none'
        document.querySelector('html').style.overflow = 'hidden'
        document.querySelector('html').style.touchAction = 'none'
        document.querySelector('#viewPortMeta').setAttribute('content', 'width=device-width, initial-scale=1, user-scalable=no')
    }
    
    const controlOverflowOff = () => {
        document.querySelector('body').style.overflow = 'auto'
        document.querySelector('body').style.touchAction = ''
        document.querySelector('html').style.overflow = 'auto'
        document.querySelector('html').style.touchAction = ''
        document.querySelector('#viewPortMeta').setAttribute('content', 'width=device-width, initial-scale=1')
    }
    
    useEffect(() => {
        controlOverflowOn()
        return () => {
            controlOverflowOff()
        }
    }, []
    )

    const getSetting = useCallback(
        (key) => {
            if (_SpinContext.currentSpinData === null) {
                return
            }   
            if (_SpinContext.currentSpinData[key] !== null) {
                return _SpinContext.currentSpinData[key]
            }
        }, [_SpinContext.currentSpinData]
    )
    const pickCorrectNumber = useCallback(
        (num, max) => {

            if (_SpinContext.currentSpinData && _SpinContext.currentSpinData.bounceHalfCircle === true) {
                return CorrectNumberBounce(num, max)

            } else {
                return CorrectNumber(num, max)
            }
        }, [_SpinContext.currentSpinData]
    )

    const initCustomBits = useCallback(
        () => {
            if (_SpinContext.currentSpinData !== null && loadImagePostionSet === false) {
                setLoadImagePostionSet(true)
                let positionToSet = pickCorrectNumber(
                        _SpinContext.currentSpinData.firstImageLoadLindex >= 0 ? _SpinContext.currentSpinData.firstImageLoadLindex + .5 : .5,
                        _SpinContext.currentSpinData.images.length
                    )
                setImagePosition(positionToSet)
                return
            } else {
                return
            }
        }, [
            _SpinContext.currentSpinData, 
            _SpinContext.imageUriArray.length,
            loadImagePostionSet
        ])


       useEffect(() => {
        if (_SpinContext.currentSpinData === null) {
            return
        }
        if (props.mode !== 'edit') {
            document.title = `Octospin | ${_SpinContext.currentSpinData.nickname ? _SpinContext.currentSpinData.nickname : ''}`;

        } else {
            document.title = `Octospin | Edit | ${_SpinContext.currentSpinData.nickname ? _SpinContext.currentSpinData.nickname : ''}`;
        }
        initCustomBits()
        _SpinContext.changeImageUriArray(_SpinContext.currentSpinData.images.map(x =>    {   
            return (
                {
                    
                    imageUrlZoom: `${_SpinContext.currentSpinData.pullZoneBaseUrl}/${x.storagePath}${_SpinContext.currentSpinData.requestTokenQueryZoom}`,
                    imageUrlLow: `${_SpinContext.currentSpinData.pullZoneBaseUrl}/${x.storagePath}${_SpinContext.currentSpinData.requestTokenQueryLow}`,
                    imageUrlMediuum: `${_SpinContext.currentSpinData.pullZoneBaseUrl}/${x.storagePath}${_SpinContext.currentSpinData.requestTokenQueryMedium}`,
                    imageUrlHigh: `${_SpinContext.currentSpinData.pullZoneBaseUrl}/${x.storagePath}${_SpinContext.currentSpinData.requestTokenQueryHigh}`,
                    order: x.order,
                    id: x.id,
                }
            )
            }
        ))
        _SpinContext.changeImageUriArray(initial =>{
            console.log(initial)
            return initial.sort(ArrayOrderSort)
        })
    }, [_SpinContext.currentSpinData, props.mode,  _SpinContext.changeImageUriArray, ArrayOrderSort]
    )


    useEffect(() => {
        console.log('_SpinContext.waitingToLazilyLoad', _SpinContext.waitingToLazilyLoad)
    }, [_SpinContext.waitingToLazilyLoad])




   



    const handleServerSpin = useCallback(
        async (lazilyLoaded) => {
            
            //fetch the spin
                fetch(`${_AppContext.baseBackendUrl}/api/Spin/${props.id}?lazilyLoaded=${lazilyLoaded}`)
                .then(res => 
                    {
                        if (res.status === 200) {
                            return res.json()
                        } else {
                            // _SpinContext.changeShowError({throw: true, message: `spin ${props.id} not found`})
                            throw new Error(`spin ${props.id} not found. Code 400005`);

                        }
                    })
                .then(async data => {

                    let mediumToken = await HandleLocalStorageToken('medium', data.spin.id, data.requestTokenQueryNormal)
                    let zoomToken = await HandleLocalStorageToken('zoom', data.spin.id, data.requestTokenQueryZoom)
                    let lowToken = await HandleLocalStorageToken('low', data.spin.id, data.requestTokenQueryLow)
                    let thumbnailToken = await HandleLocalStorageToken('low', data.spin.id, data.requestTokenQueryThumbnail)
                    let highToken = await HandleLocalStorageToken('high', data.spin.id, data.requestTokenQueryHigh)
                    let webpToken = await HandleLocalStorageToken('webp', data.spin.id, data.requestTokenQueryWebp)
                    // use that token in place of incoming token token
                    let properData = {...data.spin, 
                        requestTokenQueryMedium: mediumToken, 
                        requestTokenQueryZoom: zoomToken,
                        requestTokenQueryLow: lowToken,
                        requestTokenQueryHigh: highToken,
                        requestTokenQueryWebp: webpToken,
                        requestTokenQueryThumbnail: thumbnailToken,
                      }
                    
                      _SpinContext.setSelectedQuality(properData.defaultLoadQuality ? properData.defaultLoadQuality : 'low')
                    
                    _SpinContext.changeCurrentSpinData(properData)

                    if (properData && properData.lazyLoadSpin === true && lazilyLoaded !== true) {
                        _SpinContext.setWaitingToLazilyLoad(true)
                        // countIfBillableLoad(props.id, _AppContext.baseBackendUrl, false)
                    }
                    if (properData && properData.lazyLoadSpin === true && lazilyLoaded === true) {
                        _SpinContext.changeLoading(false)
                        // countIfBillableLoad(props.id, _AppContext.baseBackendUrl, true)
                        setImagePosition(pickCorrectNumber(properData.firstImageLoadLindex >= 0 ? properData.firstImageLoadLindex + .5 : .5, properData.images.length))
                    }
                    if (properData && properData.lazyLoadSpin !== true) {
                        // countIfBillableLoad(props.id, _AppContext.baseBackendUrl, true)

                    }
                    _SpinContext.setInitialSpinData(properData)
                })
                .catch(error => {
                    if (error.message.toLowerCase() === 'failed to fetch') {
                      history.push(`/error?message=There was an issue connecting to Octospin's infrastructure. Code 500000`)
        
                      } else {
                          history.push(`/error?message=${error}`)
                      }
                    }
                    )
            
        },
        [props.id, _AppContext.baseBackendUrl]
    )

    const getSpinAuthed = useCallback(
        async(lazilyLoaded, token) => {

            //fetch the spin
                fetch(`${_AppContext.baseBackendUrl}/api/Spin/${props.id}?lazilyLoaded=${props.mode === 'edit' ? true : lazilyLoaded}`, {
                    method: 'GET',
                    headers: {
                        Accept: 'text/plain',
                        Authorization: `Bearer ${token}`,
                        
                      }
                })
                .then(res => 
                    {
                        
                        if (res.status === 200) {
                            return res.json()
                        } else {
                            // history.push('/notfound/')
                            throw new Error(`spin ${props.id} not found. Code 400004`);

                        }
                    })
                .then(async data => {

                    // get a spin & its token
                    // pass that token to token handler and receive the token to use in return
                    let mediumToken = await HandleLocalStorageToken('medium', data.spin.id, data.requestTokenQueryNormal)
                    let zoomToken = await HandleLocalStorageToken('zoom', data.spin.id, data.requestTokenQueryZoom)
                    let lowToken = await HandleLocalStorageToken('low', data.spin.id, data.requestTokenQueryLow)
                    let thumbnailToken = await HandleLocalStorageToken('low', data.spin.id, data.requestTokenQueryThumbnail)
                    let highToken = await HandleLocalStorageToken('high', data.spin.id, data.requestTokenQueryHigh)
                    let webpToken = await HandleLocalStorageToken('webp', data.spin.id, data.requestTokenQueryWebp)
                    // use that token in place of incoming token token
                    let properData = {...data.spin, 
                        requestTokenQueryMedium: mediumToken, 
                        requestTokenQueryZoom: zoomToken,
                        requestTokenQueryLow: lowToken,
                        requestTokenQueryHigh: highToken,
                        requestTokenQueryWebp: webpToken,
                        requestTokenQueryThumbnail: thumbnailToken,
                      }
                    
                    _SpinContext.setSelectedQuality(properData.defaultLoadQuality ? properData.defaultLoadQuality : 'low')
                   

                    _SpinContext.changeCurrentSpinData(properData)
            
                    if (properData && properData.lazyLoadSpin === true && lazilyLoaded !== true) {
                        _SpinContext.setWaitingToLazilyLoad(true)
                    }
                    if (properData && properData.lazyLoadSpin === true && lazilyLoaded === true) {
                        _SpinContext.changeLoading(false)
                       
                        setImagePosition(pickCorrectNumber(properData.firstImageLoadLindex >= 0 ? properData.firstImageLoadLindex + .5 : .5, properData.images.length))

                    }
                    _SpinContext.setInitialSpinData(properData)
                })
                .catch(error => {
                    if (error.message.toLowerCase() === 'failed to fetch') {
                      history.push(`/error?message=There was an issue connecting to Octospin's infrastructure. Code 500000`)
        
                      } else {
                          history.push(`/error?message=${error}`)
                      }
                    }
                    )
            
        },
        [props.id, _AppContext.baseBackendUrl]
    )

   

const lazilyLoadSpin = async () => {
    _SpinContext.changeLoading(true)
    
    _SpinContext.setWaitingToLazilyLoad(false)

    if (props.mode === 'edit') {
        _SpinContext.changeLoading(false)
        setImagePosition(pickCorrectNumber(_SpinContext.currentSpinData.firstImageLoadLindex >= 0 ? _SpinContext.currentSpinData.firstImageLoadLindex + .5 : .5, _SpinContext.currentSpinData.images.length))
        return
    }
    var accessToken;
            
            try {
                accessToken = await _AppContext.getAccessTokenSilently({
                    audience: `https://dev-backend.prosp.in/`,
                  });
            } catch {
                accessToken = null;
                
            }
            
        
              console.log(accessToken)

              if (accessToken === null) {
                handleServerSpin(true)
              } else if (accessToken.length > 0) {
                getSpinAuthed(true, accessToken)
              }
}

useEffect( async () => {
    var accessToken;
            
            try {
                accessToken = await _AppContext.getAccessTokenSilently({
                    audience: `https://dev-backend.prosp.in/`,
                  });
            } catch {
                accessToken = null;
                
            }
            
        
              console.log(accessToken)

              if (accessToken === null) {
                handleServerSpin(false)
              } else if (accessToken.length > 0) {
                getSpinAuthed(false, accessToken)
              }


}, [])



const checkImagePositionDifference = useCallback(
    () => {
        const truncImagePosition = Math.trunc(imagePosition)

        if (truncImagePosition == localSelectedImage || truncImagePosition == _SpinContext.selectedImage) {
            return {state: false, value: null}
        }
        return {state: true, value: truncImagePosition}

    }, [localSelectedImage, _SpinContext.selectedImage, imagePosition]
)

useEffect(() => {
    const checkedPosition = checkImagePositionDifference()
    if (checkedPosition.state == false) {
        return
    }

    setLocalSelectedImage(checkedPosition.value)
    _SpinContext.changeSelectedImage(checkedPosition.value)


}, [imagePosition])

useEffect(() => {
if (_SpinContext.selectedImage === localSelectedImage) {
    return
}
if (_SpinContext.currentSpinData) {
    setImagePosition(pickCorrectNumber(_SpinContext.selectedImage + .5, _SpinContext.currentSpinData.images.length))
}

}, [_SpinContext.selectedImage, localSelectedImage, _SpinContext.currentSpinData])

    

///// Spin Data Listeners /////

    useEffect(() => {
        if (_SpinContext.currentSpinData !== null && _SpinContext.currentSpinData.dragFactorRangeValue !== null) {
            setDragFactor(dragFactorArray[_SpinContext.currentSpinData.dragFactorRangeValue])
        }
        if (_SpinContext.currentSpinData !== null) {
            setDragDirection(_SpinContext.currentSpinData.dragDirectionNegative == true ? -1 : 1)
        }
    }, [ 
        _SpinContext.currentSpinData, 
        setDragFactor, 
        setDragDirection
    ])

    

    ///// DRAG INTERACTION /////

   
    const listenForDoubleTap = () => {
        setIsListeningForDoubleTap(true)
        return setTimeout( () => {
            setIsListeningForDoubleTap(false)
        }, 150)
    }
    

    const handleMouseDown = useCallback(
    
    (e) => {
        _SpinContext.setPromptIsVisible(false)

        _SpinContext.setMouseIsDown(true)
            setPrevClientX(e.clientX)
    }, [_SpinContext.showZoom, _SpinContext.setPromptIsVisible]
    )

    const handleTouchDown = useCallback(
        (e) => {

           
                    _SpinContext.setMouseIsDown(true)
                    setPrevClientX(e.touches[0].clientX)
                    console.log(e)
                
            
            _SpinContext.setPromptIsVisible(false)

        },[isListeningForDoubleTap, _SpinContext.showZoom, _SpinContext.changeShowZoom]
    )

    
    ///// Pointer Up /////

    const handleMouseUp = (e) => {
            setPrevClientX(e.clientX)
            _SpinContext.setMouseIsDown(false)
    }

    const handleTouchUp = useCallback(
        (e) => {

            // listenForDoubleTap()

                setPrevClientX(e.changedTouches[0].clientX)
                // _SpinContext.setMouseIsDown(false)
        }, [_SpinContext.showZoom, setPrevClientX]
    )




    
    

    

    const processDoubleClick = async (e, type, dimensions) => {  
            console.log(e)          
            var variant = zoomScaleArray.find(z => z.scale === zoomScale).variant;


            var target = type === 'touch' ? e.touches[0].target : e.target;

            var calcCoordinateX = type === 'touch' ? e.touches[0].clientX - target.x : e.clientX - target.x;
            var calcCoordinateY = type === 'touch' ? e.touches[0].clientY - target.y : e.clientY - target.y;

            var distanceFromCenterX = calcCoordinateX - (target.offsetWidth / 2)
            var distanceFromCenterY = calcCoordinateY - (target.offsetHeight / 2)

            var displayedImgHeight;
            var displayedImgWidth;

            var zoomedImgHeight;
            var zoomedImgWidth;

            var fractionOfImgX;
            var fractionOfImgY;

            var wrapperWidthToHeight = dimensions.width / dimensions.height
            var imageWidthToHeight = target.naturalWidth / target.naturalHeight

            if (wrapperWidthToHeight > imageWidthToHeight) {
                // img height is wrapper height 
                displayedImgHeight = dimensions.height
                displayedImgWidth = displayedImgHeight * imageWidthToHeight


            } else if (wrapperWidthToHeight < imageWidthToHeight) {
                // img width is wrapper width 
                displayedImgWidth = dimensions.width
                displayedImgHeight = displayedImgWidth / imageWidthToHeight

            } else {
                // img square
                displayedImgWidth = dimensions.width
                displayedImgHeight = dimensions.height
            }



            

            if (imageWidthToHeight > 1) {
                //landscape
                zoomedImgHeight = variant / imageWidthToHeight
                zoomedImgWidth = variant;

            } else if (imageWidthToHeight < 1) {
                //portrait
                zoomedImgHeight = variant
                zoomedImgWidth = variant * imageWidthToHeight;
            } else {
                //square
                zoomedImgHeight = variant
                zoomedImgWidth = variant
            }

            if (distanceFromCenterX < ((displayedImgWidth / 2) * -1) ) {
                distanceFromCenterX = (displayedImgWidth / 2) * -1
            } else if (distanceFromCenterX > (displayedImgWidth / 2) ) {
                distanceFromCenterX = (displayedImgWidth / 2)
            }

            if (distanceFromCenterY < ((displayedImgHeight / 2) * -1) ) {
                distanceFromCenterY = (displayedImgHeight / 2) * -1
            } else if (distanceFromCenterY > (displayedImgHeight / 2) ) {
                distanceFromCenterY = (displayedImgHeight / 2)
            }

            if (distanceFromCenterX < 0) {
                fractionOfImgX = ((displayedImgWidth / 2) + distanceFromCenterX) / displayedImgWidth
            } else if (distanceFromCenterX > 0) {
                fractionOfImgX = ((displayedImgWidth / 2) + distanceFromCenterX) / displayedImgWidth
            } else {
                // distance from center is 0
                fractionOfImgX = 0.5
            }

            if (distanceFromCenterY < 0) {
                fractionOfImgY = ((displayedImgHeight / 2) + distanceFromCenterY) / displayedImgHeight
            } else if (distanceFromCenterY > 0) {
                fractionOfImgY = ((displayedImgHeight / 2) + distanceFromCenterY) / displayedImgHeight
            } else {
                // distance from center is 0
                fractionOfImgY = 0.5
            }

         

        // setScale(3)

            _SpinContext.changeShowZoom(true)

        }


    const handleDoubleClick = useCallback(
        async (e, type) => {
                processDoubleClick(e, type, props.dimensions)
            

        }, [_SpinContext.showZoom, props.dimensions]
    )
    
    const [zoomPrevClientX, setZoomPrevClientX] = useState(0)
    const [zoomPrevClientY, setZoomPrevClientY] = useState(0)

    // const getRenderedWidth = (naturalHeight, naturalWidth, clientHeight) => {
    //     return (clientHeight * (naturalWidth / naturalHeight))
    // }
    const getRenderedWidth = useCallback(
    () => {
        if (dimLayerRef.current === null || imgRef.current === null) {
            return
        }

        return (dimLayerRef.current.clientHeight * (imgRef.current.naturalWidth / imgRef.current.naturalHeight))
    }, [imgRef.current, imgRef.current, dimLayerRef.current]
    )

    const getBounds = useCallback(
    () => {

        let renderedWidth = getRenderedWidth();
        // dimLayerRef.current.clientWidth;

        let leftBound = (((scale - 1)/2)*dimLayerRef.current.clientWidth) - ((scale/2)*(dimLayerRef.current.clientWidth - renderedWidth))



        let bounds = {
            left: leftBound,
            right: -1 * leftBound,
            top: null,
            bottom: null
        }

        return bounds
    },[dimLayerRef.current, scale]
    )
    


    const dragChangeImagePosition = useCallback(
        (clientX) => {
                if (_SpinContext.mouseIsDown != true) {
                    return
                }
                if (prevClientX == clientX) {
                    return
                }
                
                setImagePosition(prev => {
                    return pickCorrectNumber(prev + ( (dragDirection * (clientX - prevClientX)) / dragFactor ), _SpinContext.imageUriArray.length)
                })
                setPrevClientX(clientX)
                
        }, [scale, imgRef.current, setImagePosition,setDraggableTop, setDraggableLeft,zoomPrevClientX, zoomPrevClientY, _SpinContext.showZoom,  _SpinContext.mouseIsDown, dragDirection, prevClientX, dragFactor, _SpinContext.imageUriArray, scale]
    )
    
    const handleDrag = (e) => {

        dragChangeImagePosition(e.clientX)
    }

    const handleTouchDrag = (e) => {
        console.log('run')

        dragChangeImagePosition(e.touches[0].clientX)
    }

    /////////////// DRAG INTERACTION END ///////////////

    useEffect(() => {
        console.log(dimLayerRef.current.clientHeight)
    }, [dimLayerRef.current])


    const zoomScale = 1;
    const zoomScaleArray = [{scale: 1,variant: 2000},{scale: 2,variant: 2500},{scale: 3,variant: 3000}];
    
    const [spinPadActive, setSpinPadActive] = useState(false)

    return (
        <div 
        ref={dimLayerRef}
        
        style={{
        position: 'relative', 
        backgroundColor: _SpinContext.currentSpinData && _SpinContext.currentSpinData.pageBackgroundColor ?  _SpinContext.currentSpinData.pageBackgroundColor : '#fff',
        // backgroundColor: 'red',
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        }}
        onMouseMove={(e) => { 
            if (spinPadActive === false) {
                return
            }
            handleDrag(e) 
        }}
        onTouchMove={(e) => {
            if (spinPadActive === false) {
                return
            }
            handleTouchDrag(e) 
        }}

            onMouseUp={(e) => { 
                setSpinPadActive(false)
                handleMouseUp(e) 
            }}
            onTouchEnd={(e) => { 
                setSpinPadActive(false)
                handleTouchUp(e) 
            }}

        >
        
        {_SpinContext.currentSpinData && _SpinContext.currentSpinData.lazyLoadSpin === true && _SpinContext.waitingToLazilyLoad === true && 
            <div
            style={{background: 'transparent', position: 'absolute', top: 0, left: 0, height: '100%', width: '100%', zIndex: 200, cursor: 'pointer'}}
            onClick={() => {
                console.log('click')
                lazilyLoadSpin()
            }}
            >
                <div
                className="promptWrapper"
                
                >
                    <div
                    style={{
                        backgroundColor: _SpinContext.currentSpinData.lazyLoadPromptBackgroundColor,
                        boxShadow: _SpinContext.currentSpinData.lazyLoadPromptShowDropshadow ? '0 4px 8px rgba(0, 0, 0, 0.16), 0 4px 8px rgba(0,0,0,0.23)' : 'none',
                        color: _SpinContext.currentSpinData.lazyLoadPromptTextColor,
                        opacity: _SpinContext.currentSpinData.lazyLoadPromptOpacity/100,
                        padding: '10px',
                        borderRadius: '10px'


                    }}
                    >
                    {decode(_SpinContext.currentSpinData.promptToLoad)}
                    </div>
                    </div>
            </div>
        }

            {
                _SpinContext.currentSpinData && _SpinContext.currentSpinData.promptShow && _SpinContext.waitingToLazilyLoad === false  && _SpinContext.promptIsVisible &&
                <div
                className="promptWrapper"
                
                >
                    <div
                    style={{
                        backgroundColor: _SpinContext.currentSpinData.promptBackgroundColor,
                        boxShadow: _SpinContext.currentSpinData.promptShowDropshadow ? '0 4px 8px rgba(0, 0, 0, 0.16), 0 4px 8px rgba(0,0,0,0.23)' : 'none',
                        color: _SpinContext.currentSpinData.promptTextColor,
                        opacity: _SpinContext.currentSpinData.promptOpacity/100,
                        padding: '10px',
                        borderRadius: '10px'


                    }}
                    >
                    {decode(_SpinContext.currentSpinData.promptToSpin)}
                    </div>
                    </div>
            }
           
            { _SpinContext.loading === true && <LoadingSpinnerCenter/>}

         




<ZoomView 
                   
                    draggable={false}

                    handleMouseDown={handleMouseDown}
                    handleDrag={handleDrag}
                    handleMouseUp={handleMouseUp}
                    handleTouchDown={handleTouchDown}
                    handleTouchDrag={handleTouchDrag}
                    handleTouchUp={handleTouchUp}
                    handleDoubleClick={handleDoubleClick}
                    />


{ 1 === 2 &&
            <div
            draggable={false}
            onMouseDown={handleMouseDown}
            onMouseMove={handleDrag}
            onMouseUp={handleMouseUp}
            onTouchStart={handleTouchDown}
            onTouchMove={handleTouchDrag}
            onTouchEnd={handleTouchUp}
            onDoubleClick={handleDoubleClick}
            style={{
                
                cursor: _SpinContext.currentSpinData && _SpinContext.currentSpinData.cursorStyle ? _SpinContext.currentSpinData.cursorStyle : 'ew-resize',
                justifyContent: 'center',
                display: 'flex',
               
                width: '100%',
                        height: `100%`,
     
        outline: '2px solid black'

     
            }}
            >                
                

                { _SpinContext.imageUriArray[localSelectedImage] && _SpinContext.imageUriArray[localSelectedImage].id != 0 &&
                    <div 
                    style={{
                        position: 'relative',
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                        // width: props.dimensions.width,
                        width: '100%',
                        height: '100%',

                        // height: props.dimensions.height
                    }}>
                    {_SpinContext.imageUriArray[localSelectedImage] && _SpinContext.imageUriArray.map((x, index) => 
                        <img
                        draggable={false}
                        key={x.id}
                        onLoad={(e) => { 
                            if (localSelectedImage === index) {
                                _SpinContext.changeLoading(false)
                            }
                        }}
                        ref={imgRef}
                        
                        style={{
                            userSelect: "none",
                            // maxHeight: props.dimensions.height,
                            maxHeight: '100%',
                            // maxWidth: props.dimensions.width,
                            maxWidth: '100%',
                            width: '100%',
                            objectFit: 'contain',
                            position: 'absolute',
                        display: localSelectedImage === index ? 'flex' : 'none',
                        transform: `scale(${scale})`,
        position: scale > 1 ? 'absolute' : 'relative',
        top: scale > 1 ? `${draggableTop}px` : 'unset',
        left: scale > 1 ? `${draggableLeft}px` : 'unset',
                        }}
                        src={x.imageUrlMediuum}
                        />
                    )}
                    </div>
                }
            </div>
}
            

            { _SpinContext.currentSpinData && _SpinContext.currentSpinData.showOctospinBranding === true &&
                <div className="brandingWrapper">
                    <a href="https://octospin.com" target="_blank" title="octospin.com">
                        <LogoAnimated dimensions={{height: '25.6px', width: '32px'}}/>
                    </a>
                </div>
            }
            <div className="settingsWrapper" style={{
                width: _SpinContext.showZoom === true ? '100%' : 'fit-content'
            }}>
            { _SpinContext.showZoom === true &&
                    <div className="spinPad"
                    draggable={false}
            onMouseDown={(e) => { 
                setSpinPadActive(true)
                handleMouseDown(e) 
            }}
            onTouchStart={(e) => { 
                setSpinPadActive(true)
                handleTouchDown(e) 
            }}
            onMouseUp={(e) => { 
                setSpinPadActive(false)
                handleMouseUp(e) 
            }}
            onTouchEnd={(e) => { 
                setSpinPadActive(false)
                handleTouchUp(e) 
            }}
            
            // onDoubleClick={handleDoubleClick}
                    >
                        <div
                        style={{
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis'
                        }}
                        >
                        Press & Drag Here To Spin

                        </div>
                        </div>
                }
                <div className="settingsWrapperActions">
                { _SpinContext.showZoom === true &&
                    <IconButton
                    id="basic-button"     
                    className="settingsActionButton"       
                    style={{ color: getSetting('basicButtonBackgroundColor')}}
                    onClick={()=>{
                        _SpinContext.changeShowZoom(false)
                        _SpinContext.setZoomOutAction(true)

                    }}    
                    >
                        <ZoomOut/>
                    </IconButton>
                }
                { _SpinContext.currentSpinData && _SpinContext.currentSpinData.showZoomButton === true &&
                    <IconButton
                    id="back-button"            
                    style={{ color: getSetting('basicButtonBackgroundColor')}}
                    onClick={()=>{
                        // _SpinContext.changeShowZoom(true)
                        _SpinContext.setZoomInAction(true)
                    }}    
                    >
                        <ZoomIn/>
                    </IconButton>
                }
                { _SpinContext.currentSpinData && _SpinContext.currentSpinData.showPlaybackControls === true &&
                    <>
                    <IconButton
                    id="back-button"            
                    style={{ color: getSetting('basicButtonBackgroundColor')}}
                    onClick={()=>{
                        _SpinContext.setPromptIsVisible(false)

                        const diff = _SpinContext.currentSpinData.dragDirectionNegative === false ? -1 : 1
                        // _SpinContext.changeSelectedImage(prev => pickCorrectNumber(prev + diff, _SpinContext.imageUriArray.length))
                        setImagePosition(prev => pickCorrectNumber(prev + diff, _SpinContext.imageUriArray.length))
                    }}    
                    >
                        <ArrowBackIos/>
                    </IconButton>
                    <IconButton
                    id="forward-button"   
                    style={{ color: getSetting('basicButtonBackgroundColor')}}

                    onClick={() => {
                        _SpinContext.setPromptIsVisible(false)

                        const diff = _SpinContext.currentSpinData.dragDirectionNegative === false ? 1 : -1
                        // _SpinContext.changeSelectedImage(prev => pickCorrectNumber(prev + diff, _SpinContext.imageUriArray.length))
                        setImagePosition(prev => pickCorrectNumber(prev + diff, _SpinContext.imageUriArray.length))
                    }}    
                    >
                        <ArrowForwardIos/>
                    </IconButton>
                    </>
                }
                { _SpinContext.currentSpinData && _SpinContext.currentSpinData.showSettingsMenu === true &&
                    <SettingsMenu mode={props.mode}/>
                }
                </div>

            </div>
        </div>
    )
};

export default Interact;