// import React, { useContext, useState, useEffect, useCallback, useRef } from "react";


const GetMiddleMonths = (startDate, endDate) => {

    return new Promise((resolve) => {

    
        const monthToString = (month) => {
            let monthString = month.toString()
            monthString = monthString.length == 1 ? `0${monthString}` :  monthString
            return monthString
        }
    
    
    const months = []
    
       const monthDiff = (start, end) => {
           var months;
           months = (end.getFullYear() - start.getFullYear()) * 12;
           months -= start.getMonth();
           months += end.getMonth();
            return months <= 0 ? 0 : months;
       }
    
       const numOfMonths = monthDiff(startDate, endDate)
    
       for (let a = 0; a <= numOfMonths; a++) {
            let workingDate = new Date()
            workingDate.setMonth(endDate.getMonth() - a)
    
    
            let yy = `${workingDate.getFullYear()}`
            yy = yy.substring(2,4)
            months.push(
                {
                        yyMm: `${yy}${monthToString(workingDate.getMonth() + 1)}`,
                        mm: monthToString(workingDate.getMonth() + 1),
                        yy: yy
            
                    }
            )
    
         
       }
    
       resolve(months);
    })
   


}

export default GetMiddleMonths;