import React, { useEffect, useState, useCallback, useContext } from "react";
import { useParams } from "react-router";
import Interact from '../components/Interact'
import EditSettings from "../components/EditSettings";
import SpinContext from "../contexts/SpinContext";
import AppContext from "../contexts/AppContext";
import EditContext from "../contexts/EditContext";
import UpdateImagesContext from '../contexts/UpdateImagesContext'
import useWindowDimensions from "../custom-hooks/useWindowDimensions"; 
import LoadingSpinnerPrompt from "../components/LoadingSpinnerPrompt";

import CorrectNumber from "../functions/CorrectNumber";
import CorrectNumberBounce from "../functions/CorrectNumberBounce";
import { Prompt } from 'react-router'


const Edit = () => {
    let { id } = useParams();

    id = id !== 'demo' ? id : 1;
    
    const windowDimensions = useWindowDimensions()

    const _AppContext = useContext(AppContext)
   

    const [currentSpinData, setCurrentSpinData] = useState(null)
    const [initialSpinData, setInitialSpinData] = useState(null)
    const [selectedImage, changeSelectedImage] = useState(0)
    const [imageUriArray, changeImageUriArray] = useState([])
    const [lowImageUriArray, changeLowImageUriArray] = useState(null)
    const [mediumImageUriArray, changeMediumImageUriArray] = useState(null)
    const [editIdArray, changeEditIdArray] = useState(null)
    const [loading, changeLoading] = useState(true)
    const [contextFiles, changeUploadedFiles] = useState([])
    const [imageUploadInProgress, changeImageUploadInProgress] = useState(false)
    const [initialImagesLoaded, setInitialImagesLoaded] = useState(false)
    const [showZoom, changeShowZoom] = useState(false)
    const [pageMightBeDirty, setPageMightBeDirty] = useState(false)
    const [userCustomSettingsFromDB, setUserCustomSettingsFromDB] = useState([])
    const [selectedCustomSetting, setSelectedCustomSetting] = useState([])
    const [initialSavedSettingId, setInitialSavedSettingId] = useState(null)
    const [reloadState, setReloadState] = useState(0)
    const [promptIsVisible, setPromptIsVisible] = useState(true)
    const [mouseIsDown, setMouseIsDown] = useState(false)
    const [zoomOutAction, setZoomOutAction] = useState(false)
    const [zoomInAction, setZoomInAction] = useState(false)
    const [flippingActiveSwitch, setFlippingActiveSwitch] = useState(false)
    const [waitingToLazilyLoad, setWaitingToLazilyLoad] = useState(false)
    const [selectedQuality, setSelectedQuality] = useState('low')

    // ============ CALLBACKS ============

    const changeCurrentSpinData = useCallback(
        (value) => {
        

            setCurrentSpinData(value)

            setInitialSavedSettingId(value.savedSettingId)
        }, [currentSpinData, initialImagesLoaded, userCustomSettingsFromDB]
    )
    const pickCorrectNumber = useCallback(
        (num, max) => {
            if (currentSpinData && currentSpinData.bounceHalfCircle === true) {
                return CorrectNumberBounce(num, max)
  
            } else {
                return CorrectNumber(num, max)
            }
        }, [currentSpinData]
    )
    const handleImageChanges = useCallback(
        () => {
            if (!currentSpinData) {
                return
            }
        
        if (contextFiles.length > 0) {

            editSpinData('images', contextFiles.map((x, index) => {
                return {
                    ...x.fullImageData,
                    order: index
                }
            }))
        }

        if (currentSpinData.firstImageLoadLindex > contextFiles.length - 1) {
            editSpinData('firstImageLoadLindex', pickCorrectNumber(contextFiles.length - 1))
        }
        if (selectedImage > contextFiles.length - 1) {
            changeSelectedImage(pickCorrectNumber(contextFiles.length - 1))
        }

        }, [currentSpinData, contextFiles]
    )
    const changeUserCustomSettingsFromDB = useCallback(
        (value) => {
            setUserCustomSettingsFromDB(value)
        },
        [setUserCustomSettingsFromDB]
    )
    const changeSelectedCustomSetting = useCallback(
        (value) => {
            // let itemToSet = userCustomSettingsFromDB.find(x => x.customSetting.id == value)
            // console.log(itemToSet.customSetting)
            setSelectedCustomSetting(value)
            updateSpinSettings(value)
        },
        [setSelectedCustomSetting]
    )

    const editSpinData = useCallback(
        (key, value) => {
            if (key == null || value == null) {
                return
            }
            if (key == 'images') {
                console.log(333)

                if (key == 'images' && initialImagesLoaded == false) {
                    setInitialImagesLoaded(true)
                }
    
                changeCurrentSpinData( previousState => 
                    ({
                        ...previousState,
                        [key]: value
                    })
                )


                
            } 
            else if (key == 'nickname') {
                console.log(444)
                // setSelectedCustomSetting([])
                changeCurrentSpinData( previousState => 
                    ({
                        ...previousState,
                        // savedSettingId: null,
                        [key]: value
                    })
                )
            }
            else {
                console.log(555)
                setSelectedCustomSetting([])
                changeCurrentSpinData( previousState => 
                    ({
                        ...previousState,
                        savedSettingId: null,
                        [key]: value
                    })
                )
            } 
        },
        [changeCurrentSpinData, initialImagesLoaded]
    )


    const incrementReloadState = useCallback(
        () => {
            setReloadState(prev => prev + 1)
        }, [setReloadState]
    )

    
    // ============ USEEFFECTS ============
 
    useEffect(() => {
        if (!initialSavedSettingId) {
            return
        }
        if (userCustomSettingsFromDB.length === 0) {
            return
        }
        setSelectedCustomSetting(userCustomSettingsFromDB.find(x => x.customSetting.id === initialSavedSettingId))

    } , [initialSavedSettingId, userCustomSettingsFromDB])

    useEffect( () => {
        if (id === 1) {
            return
        }
   
        if (JSON.stringify(currentSpinData) !== JSON.stringify(initialSpinData)) {
            setPageMightBeDirty(true)

        } else {
            setPageMightBeDirty(false)

        }
    }, [currentSpinData, initialSpinData, id])

    useEffect(() => {
        if (pageMightBeDirty === true) {
            window.onbeforeunload = () => true
        } else {
            window.onbeforeunload = undefined
        }
    }, [pageMightBeDirty])

    useEffect(() => {
        handleImageChanges()
    }, [contextFiles, changeImageUriArray])

    const updateSpinSettings = useCallback (
    (setting) => {
        if (setting === null || setting === undefined) {
            return
            
        }
        if (setting.customSetting === null || setting.customSetting === undefined) {
            return
            
        }
        // setPageMightBeDirty(true)
        changeCurrentSpinData( previousState => 
            ({
                ...previousState,
                savedSettingId: setting.id,
                basicButtonBackgroundColor: setting.customSetting.basicButtonBackgroundColor,
                basicButtonTextColor: setting.customSetting.basicButtonTextColor,
                bounceHalfCircle: setting.customSetting.bounceHalfCircle,
                defaultTextColor: setting.customSetting.defaultTextColor,
                dragDirectionNegative: setting.customSetting.dragDirectionNegative,
                dragFactorRangeValue: setting.customSetting.dragFactorRangeValue,
                firstImageLoadLindex: setting.customSetting.firstImageLoadLindex,
                pageBackgroundColor: setting.customSetting.pageBackgroundColor,
                settingsMenuBackgroundColor: setting.customSetting.settingsMenuBackgroundColor,
                settingsMenuOutlineColor: setting.customSetting.settingsMenuOutlineColor,

              cursorStyle: setting.customSetting.cursorStyle,
              lazyLoadPromptBackgroundColor: setting.customSetting.lazyLoadPromptBackgroundColor,
              lazyLoadPromptOpacity: setting.customSetting.lazyLoadPromptOpacity,
              lazyLoadPromptShowDropshadow: setting.customSetting.lazyLoadPromptShowDropshadow,
              lazyLoadPromptTextColor: setting.customSetting.lazyLoadPromptTextColor,
              lazyLoadSpin: setting.customSetting.lazyLoadSpin,
              playSpeedRangeValue: setting.customSetting.playSpeedRangeValue,
              promptBackgroundColor: setting.customSetting.promptBackgroundColor,
              promptOpacity: setting.customSetting.promptOpacity,
              promptShow: setting.customSetting.promptShow,
              promptShowDropshadow: setting.customSetting.promptShowDropshadow,
              promptTextColor: setting.customSetting.promptTextColor,
              promptToLoad: setting.customSetting.promptToLoad,
              promptToSpin: setting.customSetting.promptToSpin,
              showOnInspire: setting.customSetting.showOnInspire,
              showPlaybackControls: setting.customSetting.showPlaybackControls,
              showSettingsMenu: setting.customSetting.showSettingsMenu,
              enableZoom: setting.customSetting.enableZoom,
              showZoomButton: setting.customSetting.showZoomButton

            })
        )
    }, [])

    const setAccessibility = (value) => {
        if (value === true) {
            editSpinData('showPlaybackControls', true)
        } else if (value === false) {
            editSpinData('showPlaybackControls', false)

        }
    }

    


    // =========== STATE OBJECTS ===========

    const uploadReorderContextStateObject = {
        contextFiles: contextFiles,
        changeUploadedFiles,
        imageUploadInProgress: imageUploadInProgress,
        changeImageUploadInProgress,
    }
    const editContextStateObject = {
        selectedCustomSetting: selectedCustomSetting,
        changeSelectedCustomSetting,
        userCustomSettingsFromDB: userCustomSettingsFromDB,
        changeUserCustomSettingsFromDB
    }

    const spinStateObject = {
        mouseIsDown: mouseIsDown,
        setMouseIsDown,
        currentSpinData: currentSpinData,
        changeCurrentSpinData,
        initialSpinData: initialSpinData,
        setInitialSpinData,
        flippingActiveSwitch: flippingActiveSwitch,
        setFlippingActiveSwitch,
        selectedImage: selectedImage,
        changeSelectedImage,
        imageUriArray: imageUriArray,
        changeImageUriArray,
        lowImageUriArray: lowImageUriArray,
        changeLowImageUriArray,
        mediumImageUriArray: mediumImageUriArray,
        changeMediumImageUriArray,
        editIdArray: editIdArray,
        changeEditIdArray,
        showZoom: showZoom,
        changeShowZoom,
        loading: loading,
        changeLoading,
        incrementReloadState,
        setPageMightBeDirty,
        editSpinData,
        promptIsVisible,
        setPromptIsVisible,
        setAccessibility,
        zoomOutAction,
        setZoomOutAction,
        zoomInAction,
        setZoomInAction,
        waitingToLazilyLoad: waitingToLazilyLoad,
        setWaitingToLazilyLoad,
        selectedQuality: selectedQuality,
        setSelectedQuality
    };


    return (
        

            <div className="editPageOvararch">
                
                <Prompt
      when={pageMightBeDirty}
      message='Your spin has not been uploaded, are you sure you want to leave?'
    />
                {  _AppContext.isAuth  &&
                    <SpinContext.Provider value={spinStateObject}>
                    <UpdateImagesContext.Provider value={uploadReorderContextStateObject}>

                    <div className="wrapperPreviewSettings_Edit">
                        <div className="previewSection_Edit">
                            <Interact 
                            dimensions={{
                                width: windowDimensions.width > 750 ? windowDimensions.width / 2 : windowDimensions.width, 
                                height: windowDimensions.width > 750 ? windowDimensions.height - 40 : (windowDimensions.height - 40) / 2
                            }} 
                            type={'server'} 
                            id={id}
                            mode={'edit'}
                            key={reloadState}
                            />
                        </div>
                        <div className="settingsSection_Edit">
                            <EditContext.Provider value={editContextStateObject}>
                            <EditSettings 
                            // key={reloadState}
                            />
                            </EditContext.Provider>
                            
                        </div>
                    </div>

                    </UpdateImagesContext.Provider>
                    </SpinContext.Provider>
                }
                

                { !_AppContext.isAuth && id == 1 &&
                <SpinContext.Provider value={spinStateObject}>
                <UpdateImagesContext.Provider value={uploadReorderContextStateObject}>

                <div className="wrapperPreviewSettings_Edit">
                    <div className="previewSection_Edit">
                        
                        <Interact 
                        dimensions={{
                            width: windowDimensions.width > 750 ? windowDimensions.width / 2 : windowDimensions.width, 
                            height: windowDimensions.width > 750 ? windowDimensions.height - 40 : (windowDimensions.height - 40) / 2
                        }} 
                        type={'server'} 
                        id={id}
                        mode={'edit'}
                        key={reloadState}
                        />
                    </div>
                    <div className="settingsSection_Edit">
                        <EditContext.Provider value={editContextStateObject}>
                        <EditSettings loggedIn={false} key={reloadState}/>
                        </EditContext.Provider>
                        
                    </div>
                </div>

                </UpdateImagesContext.Provider>
                </SpinContext.Provider>
                }

{ !_AppContext.isAuth && id !== 1 &&
                <>
                <LoadingSpinnerPrompt prompt='login'/>
blah
            </>
                }
              
                
            </div>


        
    )
};

export default Edit;