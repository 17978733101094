import React, { useEffect, useState } from "react";
import {CircularProgress} from '@mui/material';
import { PresentToAllSharp } from "@mui/icons-material";
// import { useAuth0 } from "@auth0/auth0-react";

const LoadingSpinner = (props) => {

  return (
    <div
    className="loadingSpinnerWrapper"
    >
        <CircularProgress
        style={{color: '#1976d2'}}
        />
        <div>
        {/* {prompt} */}
        </div>
    </div>
  );
};

export default LoadingSpinner;