import React, { useCallback, useEffect, useRef, useState } from "react";

import { 
    Settings, 
    ZoomIn, 
    ZoomOut, 
    CompareArrows,
    Palette,
    Undo,
    Done,
    Close
} from '@mui/icons-material';
import { 
    FormGroup,
    FormLabel,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Button, 
    Tooltip, 
    Slider, 
    Switch,
    ListItemIcon, 
    Menu, 
    MenuItem, 
    IconButton, 
    Icon 
} from '@mui/material'

import { HexColorPicker } from "react-colorful";
import Information from "../dialogs/Information";
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
    
      main: '#d113a8',
    },
  },
});

function CustomSwitch(props) {
   var {label, tooltip, checked, handleChange, disabled, passedClass, infoValue} = props


    return (
        <div>
            {/* <Tooltip title={toottip} placement="top"> */}
                <FormControlLabel
                className="customSwitchFormControl"
                title={tooltip}
                control={
                    <Switch 
                    className={passedClass ? passedClass : "basicSwitch"}
                    size="medium"
                    disabled={disabled ? disabled : false}
                    checked={checked} onChange={handleChange} name={label ? label : ''}
                    color="primary"
                    />
                }
                label={label ? label : ''}
                />

                {/* <div className="customSwitchWrapper">
                  <div className="customSwitchLabel">
                    {label &&
                      <div>
                      {label}
                      </div>
                    }
                    
                    <div>
                    <Information/>

                    </div>
                  </div>
                  <div className="customSwitchWrapper">
                  <Switch 
                    className={passedClass ? passedClass : "basicSwitch"}
                    size="medium"
                    disabled={disabled ? disabled : false}
                    checked={checked} onChange={handleChange} name={label ? label : ''}
                    color="primary"
                    />
                  </div>
                
                </div> */}
            {/* </Tooltip> */}
         
        </div>
    );
  }

export default CustomSwitch;