import React, { useEffect, useContext } from "react";
import { useParams } from "react-router";
import AppContext from '../contexts/AppContext'

import { useHistory } from "react-router-dom";

const Payment = () => {
    let { session_id } = useParams();
    const _AppContext = useContext(AppContext)
    let history = useHistory();



    useEffect(async () => {
        if (session_id === null || _AppContext.userId === null) {
            return
        }

        if (session_id === 'canceled') {
            history.push('/account')

        }

        const accessToken = await _AppContext.getAccessTokenSilently({
            audience: `https://dev-backend.prosp.in/`,
          });

        fetch(`${_AppContext.baseBackendUrl}/api/Payments/UpdateCheckoutEntryUser?customerId=${session_id}&userId=${_AppContext.userId}`, {
            method: 'PUT',
            headers: {
                Accept: '*/*',
                Authorization: `Bearer ${accessToken}`,
            },
        })
        .then(res => {
            if (res.status == 200) {
                _AppContext.refreshUser_Octospin()
                console.log(res)
                console.log(session_id)
                history.push('/account')
            }
        })

    }, [session_id, _AppContext.userId])


    return (
        <div className="mainContainer">

            { session_id == 'canceled' && 
            <>
                Cancelling
            </>
            }
            { session_id != 'canceled' && 
            <>
                Processing payment, do not refresh...

            </>
            }
            <br/>
            <br/>
            {session_id}
            
        </div>
        
    )
};

export default Payment;