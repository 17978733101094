import React, { useCallback, useContext, useEffect, useState } from "react";

import SpinContext from "../contexts/SpinContext";
import AppContext from "../contexts/AppContext";
import UpdateImagesContext from "../contexts/UpdateImagesContext";
import ArrayOrderSort from "../functions/ArrayOrderSort";
import UseCustomSettings from "./customSettings/UseCustomSettings";
import ReorderImages from './dialogs/ReorderImages'
import ColorPicker from "./settingComponents/ColorPicker";
import CustomSwitch from "./settingComponents/CustomSwitch";
import ProgressLoad from "./loading/ProgressLoad";
import CursorSelect from "./settingComponents/CursorSelect";

import CustomSlider from "./settingComponents/CustomSlider";
import Information from "./dialogs/Information";
import DefaultQualitySelect from "./settingComponents/DefaultQualitySelect";
import TranslateProductId from "../functions/TranslateProductId"
import { 
    Close,
    ExpandMore,
    Edit,
    Check,
    CheckCircleOutline
} from '@mui/icons-material';
import { 
    Button, 
    Tooltip, 
    Slider, 
    TextField,
    Accordion,
    AccordionSummary,
    CircularProgress,
    Menu,
    AccordionDetails,    
    Backdrop
} from '@mui/material'

import { 
    LoadingButton,
    
} from '@mui/lab'
import {decode} from 'html-entities';
import { useHistory } from "react-router-dom";


const EditSettings = (props) => {

    var {windowDimensions, loggedIn} = props

    const history = useHistory()

    const _SpinContext = useContext(SpinContext);


    const _AppContext = useContext(AppContext);
    const _UpdateImagesContext = useContext(UpdateImagesContext);
    
    const [initialColorState, setInitialColorState] = useState('#333')
    const [showUpdateImages, setShowUpdateImages] = useState(false);
    const [showChangeFirstImage, setShowChangeFirstImage] = useState(false);
    const [savingChanges, setSavingChanges] = useState(false)
    const [uploadProgressCount, setUploadProgressCount] = useState(1)
    const [uploadProgressTotal, setUploadProgressTotal] = useState(100)

    const promptOpacityMarks = [ {value: 0, label: '0%'}, {value: 100, label: '100%'} ];
    const dragFactorMarks = [ {value: 0, label: 'Slow' }, {value: 9, label: 'Fast' } ];

    const [deletingSpin, setDeletingSpin] = useState(false)
    const [deleteSuccess, setDeleteSuccess] = useState(false)
    
    const handleChange = useCallback( 
        (key, value) => {
            _SpinContext.editSpinData(key, value)
    },[_SpinContext.editSpinData])

    const [showSavedCheck, setShowSavedCheck] = useState(false)
    
    const delayChangeSavingState = useCallback(
    async (value) => {
        return new Promise(resolve => {
            setTimeout(() => {
                setSavingChanges(value)
                setShowSavedCheck(true)
                resolve()
    
            }, 1000)
        })
        
    }, [setSavingChanges, setShowSavedCheck])

    const delayRemoveSavedCheck = useCallback(
        async (value) => {
            return new Promise(resolve => {
                setTimeout(() => {
                    setShowSavedCheck(false)
                    resolve()
                }, 3000)
            })
            
        }, [setShowSavedCheck])

   
    
    const saveChanges = useCallback( 
        async () => {
            if (loggedIn == false) {
                return
            }
            const accessToken = await _AppContext.getAccessTokenSilently({
                audience: `https://dev-backend.prosp.in/`,
              });
            if (_SpinContext.initialSpinData && _SpinContext.currentSpinData && _SpinContext.initialSpinData.isActive !== _SpinContext.currentSpinData.isActive) {
                _SpinContext.setFlippingActiveSwitch(true)
                setUploadProgressTotal(_SpinContext.currentSpinData.images.length)
            }
            setSavingChanges(true)
            
      
           
            fetch(`${_AppContext.baseBackendUrl}/api/Spin/Update`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,

                },
                body: JSON.stringify(_SpinContext.currentSpinData)
            }).then(async res => {
                //lets just flip this to false no matter what

                _SpinContext.setPageMightBeDirty(false)
                _SpinContext.setFlippingActiveSwitch(false)
                await delayChangeSavingState(false)
                _SpinContext.incrementReloadState()
                await delayRemoveSavedCheck()

            })
            .catch(error => {
                if (error.message.toLowerCase() === 'failed to fetch') {
                  history.push(`/error?message=There was an issue connecting to Octospin's infrastructure. Code 500000`)
    
                  } else {
                      history.push(`/error?message=${error}`)
                  }
                }
                )
        },[_SpinContext.currentSpinData, loggedIn])

    const setLoadImage = useCallback( 
        () => {
            if (_SpinContext.selectedImage === null) {
                return
            }
            handleChange('firstImageLoadLindex', _SpinContext.selectedImage)
        }, [_SpinContext.selectedImage]
    )

    const middlewareHandleChange = useCallback(
    (type, key, value) => {
        if (!_SpinContext.currentSpinData) {
            return
        }
        if (type === 'bool') {
            handleChange(key, !_SpinContext.currentSpinData[key])
        }
        if (type === 'passValue') {
            handleChange(key, value)

        }
    }, [_SpinContext.currentSpinData, handleChange]
    )

    const showPromptPreview = () => {
        _SpinContext.setPromptIsVisible(true)
        _SpinContext.setWaitingToLazilyLoad(false)
    }
    const showLazyLoadPromptPreview = useCallback(
    () => {
        _SpinContext.setWaitingToLazilyLoad(true)
        _SpinContext.setPromptIsVisible(true)
        if (_SpinContext.currentSpinData) {
            _SpinContext.changeSelectedImage(_SpinContext.currentSpinData.firstImageLoadLindex)

        }
    }, [_SpinContext.currentSpinData]
    )

    const [deleteConfirmAnchorEl, setDeleteConfirmAnchorEl] = React.useState(null);
    const deleteConfirmOpen = Boolean(deleteConfirmAnchorEl);
    const handleDeleteConfirmClick = (event) => {
      setDeleteConfirmAnchorEl(event.currentTarget);
    };
    const handleDeleteConfirmClose = () => {
      setDeleteConfirmAnchorEl(null);
    };

    const handleDeleteSpin = useCallback(
        async () => {

            console.log('yers, delete it', _SpinContext.currentSpinData.id)

            setDeletingSpin(true)
            const accessToken = await _AppContext.getAccessTokenSilently({
              audience: `https://dev-backend.prosp.in/`,
            });
            const headers = new Headers();
            headers.append('Content-Type', 'application/json');
            headers.append('Authorization', `Bearer ${accessToken}`);
            try 
            {
                const response = await fetch(`${_AppContext.baseBackendUrl}/api/Spin/DeleteSpin?spinId=${_SpinContext.currentSpinData.id}`, {
                    method: 'DELETE',
                    headers: headers
                    
                })
      
                if (response.status == 200) {
                  console.log('success')
                  setDeleteSuccess(true)

                  const deferPageChange = setTimeout(() => {
                    history.push(`/myspins`)
                    setDeletingSpin(false)
                    clearTimeout(deferPageChange)

                  }, 1500)
      
                } else {
                  console.log('failed to completely delete spin')
                  throw new Error(`spin ${props.id} not found. Code 400005`);
      
                }
            }
            catch (error) {
                
                      history.push(`/error?message=${error}`)
                }
      
            


         
    
        }, [_AppContext, _SpinContext]
      )

    const handleShowOnInspireChange = () => { 
        middlewareHandleChange('bool', 'showOnInspire') 
    }
    const handleLazyLoadSpinChange = (expanded) => { 
   

        if (expanded === true) {
                showLazyLoadPromptPreview()
    
            }

        middlewareHandleChange('bool', 'lazyLoadSpin') 
    }

    const handleDragDirectionChange = () => { 
        middlewareHandleChange('bool', 'dragDirectionNegative') 
    }
    
    const handleDropshadowChange = () => { 
        showPromptPreview()

        middlewareHandleChange('bool', 'promptShowDropshadow') 
    }

    const handleLazyLoadDropshadowChange = () => { 
        showLazyLoadPromptPreview()

        middlewareHandleChange('bool', 'lazyLoadPromptShowDropshadow') 
    }
    

    const handlePromptShowChange = (expanded) => { 
        if (expanded === true) {
            showPromptPreview()

        }
        middlewareHandleChange('bool', 'promptShow') 
    }

    const handleBounceChange = () => { 
        middlewareHandleChange('bool', 'bounceHalfCircle') 
    }

    const handleEnableZoomChange = () => { 
        middlewareHandleChange('bool', 'enableZoom') 
    }

    const handleShowZoomButtonChange = () => { 
        middlewareHandleChange('bool', 'showZoomButton') 
    }

    

    const handleLiveChange = () => { 
        middlewareHandleChange('bool', 'isActive') 
    }

    const handleShowSettingsMenuChange = () => { 
        middlewareHandleChange('bool', 'showSettingsMenu') 
    }


    const handleCursorChange = (value) => {
        middlewareHandleChange('passValue', 'cursorStyle', value) 
    }

    const handleDefaultQualityChange = (value) => {
        middlewareHandleChange('passValue', 'defaultLoadQuality', value) 
    }
    

    const handleShowPlaybackControlsChange = () => { 
        middlewareHandleChange('bool', 'showPlaybackControls') 
    }

    

    const handlePromptTextChange = (e) => {
        showPromptPreview()
        middlewareHandleChange('passValue', 'promptToSpin', e.target.value) 
    }
    const handleLazyLoadPromptTextChange = (e) => {
        showLazyLoadPromptPreview()
        middlewareHandleChange('passValue', 'promptToLoad', e.target.value) 
    }


    

    const handleNicknameTextChange = (e) => {
        middlewareHandleChange('passValue', 'nickname', e.target.value) 
    }

    
    
    const [ anchorElement_basicButtonBackgroundColor, setAnchorElement_basicButtonBackgroundColor] = useState(null)
    const open_basicButtonBackgroundColor = Boolean(anchorElement_basicButtonBackgroundColor)


    const handleColorChange_basicButtonBackgroundColor = (value) => {
        middlewareHandleChange('passValue', 'basicButtonBackgroundColor', value) 
    }

    const handleClose_basicButtonBackgroundColor = () => {
        setAnchorElement_basicButtonBackgroundColor(null);
    };
    const handleMenuCancel_basicButtonBackgroundColor = () => {
        setAnchorElement_basicButtonBackgroundColor(null);
        handleColorChange_basicButtonBackgroundColor(initialColorState)
    };

    const handleClick_basicButtonBackgroundColor = useCallback(
    (event) => {
        if (open_basicButtonBackgroundColor === true) {
            setAnchorElement_basicButtonBackgroundColor(null);
        }
        setAnchorElement_basicButtonBackgroundColor(event.currentTarget);
        setInitialColorState(_SpinContext.currentSpinData.basicButtonBackgroundColor)

    }, [_SpinContext.currentSpinData])

    
    const [ anchorElement_settingsMenuBackgroundColor, setAnchorElement_settingsMenuBackgroundColor] = useState(null)
    const open_settingsMenuBackgroundColor = Boolean(anchorElement_settingsMenuBackgroundColor)

    const handleColorChange_settingsMenuBackgroundColor = (value) => {
        middlewareHandleChange('passValue', 'settingsMenuBackgroundColor', value) 
    }

    const handleClose_settingsMenuBackgroundColor = () => {
        setAnchorElement_settingsMenuBackgroundColor(null);
    };
    const handleMenuCancel_settingsMenuBackgroundColor = () => {
        setAnchorElement_settingsMenuBackgroundColor(null);
        handleColorChange_settingsMenuBackgroundColor(initialColorState)
    };

    const handleClick_settingsMenuBackgroundColor = useCallback(
    (event) => {
        if (open_settingsMenuBackgroundColor === true) {
            setAnchorElement_settingsMenuBackgroundColor(null);
        }
        setAnchorElement_settingsMenuBackgroundColor(event.currentTarget);
        setInitialColorState(_SpinContext.currentSpinData.settingsMenuBackgroundColor)

    }, [_SpinContext.currentSpinData])

    const [ anchorElement_pageBackgroundColor, setAnchorElement_pageBackgroundColor] = useState(null)
    const open_pageBackgroundColor = Boolean(anchorElement_pageBackgroundColor)

    const handleColorChange_pageBackgroundColor = (value) => {
        middlewareHandleChange('passValue', 'pageBackgroundColor', value) 
    }


    const handleClose_pageBackgroundColor = () => {
        setAnchorElement_pageBackgroundColor(null);
    };
    const handleMenuCancel_pageBackgroundColor = () => {
        setAnchorElement_pageBackgroundColor(null);
        handleColorChange_pageBackgroundColor(initialColorState)
    };

    const handleClick_pageBackgroundColor = useCallback(
    (event) => {
        if (open_pageBackgroundColor === true) {
            setAnchorElement_pageBackgroundColor(null);
        }
        setAnchorElement_pageBackgroundColor(event.currentTarget);
        setInitialColorState(_SpinContext.currentSpinData.pageBackgroundColor)

    }, [_SpinContext.currentSpinData])

    
// PROMT BACKGROUND COLOR 
    const [ anchorElement_promptBackgroundColor, setAnchorElement_promptBackgroundColor] = useState(null)
    const open_promptBackgroundColor = Boolean(anchorElement_promptBackgroundColor)
    const handleColorChange_promptBackgroundColor = (value) => {
        middlewareHandleChange('passValue', 'promptBackgroundColor', value) 
    }
    const handleClose_promptBackgroundColor = () => {
        setAnchorElement_promptBackgroundColor(null);
    };
    const handleMenuCancel_promptBackgroundColor = () => {
        setAnchorElement_promptBackgroundColor(null);
        handleColorChange_promptBackgroundColor(initialColorState)
    };
    const handleClick_promptBackgroundColor = useCallback(
    (event) => {
        showPromptPreview()
        if (open_promptBackgroundColor === true) {
            setAnchorElement_promptBackgroundColor(null);
        }
        setAnchorElement_promptBackgroundColor(event.currentTarget);
        setInitialColorState(_SpinContext.currentSpinData.promptBackgroundColor)
    }, [_SpinContext.currentSpinData])


// PROMT TEXT COLOR 
    const [ anchorElement_promptTextColor, setAnchorElement_promptTextColor] = useState(null)
    const open_promptTextColor = Boolean(anchorElement_promptTextColor)
    const handleColorChange_promptTextColor = (value) => {
        middlewareHandleChange('passValue', 'promptTextColor', value) 
    }
    const handleClose_promptTextColor = () => {
        setAnchorElement_promptTextColor(null);
    };
    const handleMenuCancel_promptTextColor = () => {
        setAnchorElement_promptTextColor(null);
        handleColorChange_promptTextColor(initialColorState)
    };
    const handleClick_promptTextColor = useCallback(
    (event) => {
        showPromptPreview()
        if (open_promptTextColor === true) {
            setAnchorElement_promptTextColor(null);
        }
        setAnchorElement_promptTextColor(event.currentTarget);
        setInitialColorState(_SpinContext.currentSpinData.promptTextColor)
    }, [_SpinContext.currentSpinData])


    // LAZY LOAD PROMT BACKGROUND COLOR 
    const [ anchorElement_lazyLoadPromptBackgroundColor, setAnchorElement_lazyLoadPromptBackgroundColor] = useState(null)
    const open_lazyLoadPromptBackgroundColor = Boolean(anchorElement_lazyLoadPromptBackgroundColor)
    const handleColorChange_lazyLoadPromptBackgroundColor = (value) => {
        middlewareHandleChange('passValue', 'lazyLoadPromptBackgroundColor', value) 
    }
    const handleClose_lazyLoadPromptBackgroundColor = () => {
        setAnchorElement_lazyLoadPromptBackgroundColor(null);
    };
    const handleMenuCancel_lazyLoadPromptBackgroundColor = () => {
        setAnchorElement_lazyLoadPromptBackgroundColor(null);
        handleColorChange_lazyLoadPromptBackgroundColor(initialColorState)
    };
    const handleClick_lazyLoadPromptBackgroundColor = useCallback(
    (event) => {
        showLazyLoadPromptPreview()

        if (open_lazyLoadPromptBackgroundColor === true) {
            setAnchorElement_lazyLoadPromptBackgroundColor(null);
        }
        setAnchorElement_lazyLoadPromptBackgroundColor(event.currentTarget);
        setInitialColorState(_SpinContext.currentSpinData.lazyLoadPromptBackgroundColor)
    }, [_SpinContext.currentSpinData])


// LAZY LOAD PROMT TEXT COLOR 
    const [ anchorElement_lazyLoadPromptTextColor, setAnchorElement_lazyLoadPromptTextColor] = useState(null)
    const open_lazyLoadPromptTextColor = Boolean(anchorElement_lazyLoadPromptTextColor)
    const handleColorChange_lazyLoadPromptTextColor = (value) => {
        middlewareHandleChange('passValue', 'lazyLoadPromptTextColor', value) 
    }
    const handleClose_lazyLoadPromptTextColor = () => {
        setAnchorElement_lazyLoadPromptTextColor(null);
    };
    const handleMenuCancel_lazyLoadPromptTextColor = () => {
        setAnchorElement_lazyLoadPromptTextColor(null);
        handleColorChange_lazyLoadPromptTextColor(initialColorState)
    };
    const handleClick_lazyLoadPromptTextColor = useCallback(
    (event) => {
        showLazyLoadPromptPreview()
        if (open_lazyLoadPromptTextColor === true) {
            setAnchorElement_lazyLoadPromptTextColor(null);
        }
        setAnchorElement_lazyLoadPromptTextColor(event.currentTarget);
        setInitialColorState(_SpinContext.currentSpinData.lazyLoadPromptTextColor)
    }, [_SpinContext.currentSpinData])

    


    const [initialFileInitOccured, setInitialFileInitOccured] = useState(false)
    const [initialImageSetOccured, setInitialImageSetOccured] = useState(false)

    //use image uri array in filepond
    useEffect(() => {
        if (initialFileInitOccured === true) {
            return
        }
        if (_SpinContext.imageUriArray.length === 0) {
            return
        }
        setInitialFileInitOccured(true)
        _AppContext.changeFiles([])
        console.log(_SpinContext.imageUriArray)
    }, [_SpinContext.imageUriArray, initialFileInitOccured])


    useEffect(() => {
console.log('useruseruser', _AppContext.userInfo_Octospin)
    }, [_AppContext])

    useEffect(() => {
        if (initialImageSetOccured === true) {
            return
        }
        if (!_SpinContext.currentSpinData) {
            return
        }
        if (!_SpinContext.currentSpinData.images) {
            return
        }
        if (_SpinContext.currentSpinData.images.length === 0) {
            return
        }
        console.log('CHANGING 888')
        setInitialImageSetOccured(true)
        // setInitialArrayForQuery([])
        // setInitialArrayForQuery(_SpinContext.currentSpinData.images)
        
    }, [_SpinContext.currentSpinData, initialImageSetOccured])

    const handleShowReorderImages = useCallback(
    () => {
      
        console.log('starting')
        if (showUpdateImages === false) {
            console.log('blahahahah')
            console.log('4545hy', _SpinContext.currentSpinData)
            setShowUpdateImages(true)

            if (_SpinContext.currentSpinData) {
                console.log('4545hy', _SpinContext.currentSpinData.images)
                var arrayForPopulatongReorder = _SpinContext.currentSpinData.images.sort(ArrayOrderSort)
                // if (_SpinContext.currentSpinData.isActive === true) {
                    //use the normal url
                    // getImagesForReorder(arrayForPopulatongReorder, true)
                    _UpdateImagesContext.changeUploadedFiles(arrayForPopulatongReorder.map(x => {
                        console.log('4545hy x', x)
                        return {
                            id: x.id, 
                            url: `${_SpinContext.currentSpinData.pullZoneBaseUrl}/${x.storagePath}${_SpinContext.currentSpinData.requestTokenQueryThumbnail}`,
                            fullImageData: x
                        }
                    }))
                // } else {
                //     //use the signedUrl
                //     // getImagesForReorder(arrayForPopulatongReorder, false)

                // }
            }
        } else {
            setShowUpdateImages(false)

        }
       
    }, [showUpdateImages, _SpinContext.currentSpinData]
    )

  

    const accordianSummarySx = {
        padding: '0px 6px 0px 12px'
    }


   
  return (
    <div style={{height: '100%', 
    pointerEvents: savingChanges ? 'none' : 'unset',
    cursor: savingChanges ? 'not-allowed' : 'unset'

    }}
    
    >

            { deletingSpin === true &&
           

                <Backdrop
                sx={{ 
                    color: '#fff', 
                    zIndex: 1000,
                    backgroundColor: 'hsl(0deg 0% 16% / 25%)'
                }}
                open={deletingSpin}
                // onClick={handleClose}
                >
                {/* <LoadingSpinnerCenter color="white"/> */}

                <div className="uploadProgressWrapper">


                <div style={{
                        position: 'relative'
                    }}>

                { deleteSuccess == true ? 
                    
                    <Check sx={{ fontSize: 50 }}/>
                    :
                    <CircularProgress variant="indeterminate" size={50} thickness={2.2} />
                }
                    
                    
                </div>


                { deleteSuccess == true ? 
                <>Spin deleted.</>
                
                :
                <>Hold tight. We're deleting this spin. Please do not refresh or close this page.</>
                }
                </div>

                </Backdrop>

                    }


    { _SpinContext.currentSpinData && 

    
        <div className="editSettingsOverarch">
          
            <div 
            style={{
                display: 'flex',
                flexDirection: 'column',
                height: 'auto'
            }}>
            

            <div style={{
            maxHeight: _SpinContext.flippingActiveSwitch ? '300px': '0px', 
            height: _SpinContext.flippingActiveSwitch ? 'auto': '0px', 
            width: '100%',
            visibility: _SpinContext.flippingActiveSwitch ? 'visible': 'hidden',
            opacity: _SpinContext.flippingActiveSwitch ? 1 : 0,
            // display: _SpinContext.flippingActiveSwitch ? 'flex' : 'none', 
            transition: 'all 1s'}}>
                <div 
                style={{
                    height: 'auto',
                    // width: '100%',
                    border: '2px solid #dddddd',
                    borderRadius: '10px',
                    padding: '10px',
                    margin: '0px 10px'
                }}>
                    {`This may take a while. You can leave this page. We're making each image in the spin ${_SpinContext.currentSpinData.isActive ? 'public' : 'private'}.` }
                    <ProgressLoad color="black" 
                    value={100*(uploadProgressCount/uploadProgressTotal)} 
                    />
                </div>
             
                
            </div>

            </div>
            <div className="changeSettingsSectionWrapper">

            <div className="saveSettingsSectionWrapper">

            <div
            style={{
                flex: 1
            }}
            >
    
                <TextField 
                value={decode(_SpinContext.currentSpinData.nickname)} 
                onChange={handleNicknameTextChange} 
                size="small"
                label="Nickname" 
                variant="outlined" 
                sx={{
                    width: '100%'
                }}
                />


                </div>
    
                { loggedIn == false ?
    
                <Tooltip 
                title={loggedIn == false ? 'Save (unavailable during demo)': ''} 
                placement="bottom">
                
                
                <LoadingButton 
                loading={savingChanges ? true : false} 
                onClick={saveChanges}
                style={{
                    background: '#28a745'
                  }}
               
                // disabled={savingChanges ? true : false} 
                variant="contained">
                {showSavedCheck == false ? 'Save' : <Check/>}
                </LoadingButton>
                </Tooltip>
                :
                <LoadingButton 
                loading={savingChanges || showSavedCheck ? true : false} 
                disabled={loggedIn == false || showSavedCheck == true ? true : false}
                onClick={saveChanges}
                style={{
                    background: savingChanges || showSavedCheck ? '#fff' : '#28a745',
                    color: savingChanges || showSavedCheck ? 'transparent' : '#fff',
                    border: '1px solid #28a745',
                    transition: 'background 500ms, color 500ms'
                  }}
                loadingIndicator={ showSavedCheck == false ?
                   
                    <CircularProgress sx={{color: showSavedCheck == false ? '#28a745' : 'transparent', transition: 'color 500ms'}} size={16} />
                    :
                    <Check sx={{color: showSavedCheck == false ? 'transparent' : '#28a745', transition: 'color 500ms'}} size={16}/>
                  
                    
                }               
                variant="contained">
                Save
                </LoadingButton>
            }
                
                
    
                    
                </div>

<div className="changeSettingsSectionWrapperInner">
                {
                    loggedIn !== false &&
            <UseCustomSettings/>

                }
            
            
                 


            <Accordion
                    defaultExpanded={true}
                    className='editAccordion'
                    >
                        <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        sx={accordianSummarySx}
                        >
                       Basic Settings
                        </AccordionSummary>

                        <AccordionDetails
                        sx={{
                            padding: '8px 0px 0px'
                        }}
                        >
                            <div className="inputWrapper">
                    <CustomSwitch
                    label="Live"
                    infoValue="Test"
                    passedClass={_SpinContext.currentSpinData.isActive === true ? "greenSwitch" : "greySwitch"}
                    tooltip={_SpinContext.currentSpinData.isActive === true ? 'Click to make spin private' : 'Click to make spin publicly visible'}
                    checked={_SpinContext.currentSpinData.isActive}
                    handleChange={handleLiveChange}
                    />
                    </div>

                        {
                            loggedIn !== false &&
                
                            <div className="inputWrapper">
                            <CustomSwitch
                            disabled={_SpinContext.currentSpinData.isActive === true ? false : true}
                            label="Show on Inspire"
                            tooltip= {_SpinContext.currentSpinData.isActive === true ? 
                                _SpinContext.currentSpinData.showOnInspire ? 'Click to hide from Inspire' : 'Click to show on Inspire'
                            :'Spin must be live to show on inspire'}
                            checked={_SpinContext.currentSpinData.isActive === true ?_SpinContext.currentSpinData.showOnInspire : false}
                            handleChange={handleShowOnInspireChange}
                            />
                            </div>
                        }
                           
                           
                        <div className="inputWrapper">
                                    <CustomSwitch
                                    label="Reverse Direction"
                                    tooltip="Click to change spin direction"
                                    checked={_SpinContext.currentSpinData.dragDirectionNegative}
                                    handleChange={handleDragDirectionChange}
                                    />
                                </div>
                                <div className="inputWrapper">
                                    <CustomSwitch
                                    label="Bounce"
                                    tooltip={_SpinContext.currentSpinData.bounceHalfCircle === true ? 'Click to disable bounce' : 'Click to enable bounce'}
                                    checked={_SpinContext.currentSpinData.bounceHalfCircle}
                                    handleChange={handleBounceChange}
                                    />
                                </div>
                                <div className="inputWrapper">
                                    <CustomSwitch
                                    label="Enable Zoom"
                                    tooltip={_SpinContext.currentSpinData.enableZoom === true ? 'Click to disable zoom' : 'Click to enable zoom'}
                                    checked={_SpinContext.currentSpinData.enableZoom}
                                    handleChange={handleEnableZoomChange}
                                    />
                                </div>
                                { _SpinContext.currentSpinData.enableZoom === true &&
                                    <div className="inputWrapper">
                                    <CustomSwitch
                                    // disabled={_SpinContext.currentSpinData.enableZoom === true ? false : true}
                                    label="Show Zoom Button"
                                    tooltip={_SpinContext.currentSpinData.showZoomButton === true ? 'Click to hide zoom button' : 'Click to show zoom button'}
                                    checked={_SpinContext.currentSpinData.showZoomButton}
                                    handleChange={handleShowZoomButtonChange}
                                    />
                                </div>
                                }
                                
                                <div className="inputWrapper">
                                    <CustomSwitch
                                    label="Show Arrows"
                                    tooltip={_SpinContext.currentSpinData.showPlaybackControls === true ? 'Click to hide arrows' : 'Click to show arrows'}
                                    checked={_SpinContext.currentSpinData.showPlaybackControls}
                                    handleChange={handleShowPlaybackControlsChange}
                                    />
                                </div>
                                <div className="inputWrapper">
                                    <CustomSwitch
                                    label="Show Controls Menu"
                                    tooltip={_SpinContext.currentSpinData.showSettingsMenu === true ? 'Click to hide arrows' : 'Click to show arrows'}
                                    checked={_SpinContext.currentSpinData.showSettingsMenu}
                                    handleChange={handleShowSettingsMenuChange}
                                    />
                                </div>

                                <div className="inputWrapper">
                                    <CursorSelect 
                                    handleCursorChange={handleCursorChange}
                                    currentValue={_SpinContext.currentSpinData && _SpinContext.currentSpinData.cursorStyle ? _SpinContext.currentSpinData.cursorStyle : '' }
                                    />
                            
                                    </div>
                                    {
                                        _AppContext.userInfo_Octospin && _AppContext.userInfo_Octospin.subscriptionProductId && TranslateProductId(_AppContext.userInfo_Octospin.subscriptionProductId) != "basic" &&
                                        <div className="inputWrapper">
                                        <DefaultQualitySelect 
                                        handleChange={handleDefaultQualityChange}
                                        currentValue={_SpinContext.currentSpinData && _SpinContext.currentSpinData.defaultLoadQuality ? _SpinContext.currentSpinData.defaultLoadQuality : 'low' }
                                        />
                                
                                        </div>
                                    }
                                

                                    <div className="inputWrapper">

                                    

                                    <Accordion
                    defaultExpanded={false}
                    onChange={(e, expanded) => {
                        setShowChangeFirstImage(expanded)
                    }}
                    square={true}
                    sx={{
                        boxShadow: 'none',
                        
                    }}
                    >
                        <AccordionSummary
                        expandIcon={ showChangeFirstImage === false ?
                            <Edit fontSize="small"/>
                            
                            :
                            <Close fontSize="small"/>
                        }
                        sx={{
                            padding: '8px 12px 8px 12px',
                            fontSize: '0.94rem !important',
                        color: '#444 !important'
                        }}
                        
                        aria-controls="panel1a-content"
                        id="firstImageAccordionSummary"
                        >
                            
                          

                          <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            width: '100%'

                                        }}
                                        >
                                            <div>
                                            First Image 
                                            
                                            </div>
                                            <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                justifyContent: 'flex-end',
                                                gap: '4px'
    
                                            }}
                                            >
                                            <img 
                                    draggable={false}
                                    style={{
                                        userSelect: "none",
                                        maxHeight: "80px",
                                        maxWidth: "100px",
                                        width: "100px",
                                        objectFit: "contain",
                                    
                                    }}
                                    
                                    src={_SpinContext.currentSpinData 
                                        && _SpinContext.currentSpinData.images.length > 0 
                                        && _SpinContext.currentSpinData.images.find(a => a.order == _SpinContext.currentSpinData.firstImageLoadLindex) 
                                        ? `${_SpinContext.currentSpinData.pullZoneBaseUrl}/${_SpinContext.currentSpinData.images.find(a => a.order == _SpinContext.currentSpinData.firstImageLoadLindex).storagePath}${_SpinContext.currentSpinData.requestTokenQueryThumbnail}` 
                                        : 
                                        `${_SpinContext.currentSpinData.pullZoneBaseUrl}/${_SpinContext.currentSpinData.images[0].storagePath}${_SpinContext.currentSpinData.requestTokenQueryThumbnail}`}
                                    />
                                            </div>

                                        </div>

                        </AccordionSummary>

                        <AccordionDetails
                        sx={{
                            margin: '8px 0px 10px 0px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                        >


<Button
                                        onClick={setLoadImage}
                                        
                                        variant="contained"
                                        >
                                            Set to current image

                                        </Button>
                        
                        </AccordionDetails>

                    </Accordion>
                                    
                                    
                                    </div>

                                
                                
                                <div className="inputWrapper" style={{minWidth: '200px', padding: '8px 16px'}}>
                                
                        <div 
                        onTouchEnd={(e) => {
                            if (e.target.firstChild && e.target.firstChild.value) {
                                console.log('werwer 44', e.target.firstChild.value)

                            }
                            // handleChange('dragFactorRangeValue', e.target.value)
                            
                        }}
                        style={{margin: '0px 16px'}}>
                            <CustomSlider
                            value={_SpinContext.currentSpinData.dragFactorRangeValue}
                            handleChange={(value) => handleChange('dragFactorRangeValue', value)}
                            step={1}
                            marks={dragFactorMarks}
                            min={0}
                            max={9}
                            />
                  
                           
                            </div>
                            
                       
                                </div>

                        </AccordionDetails>

                    </Accordion>
                

                    <Accordion
                    defaultExpanded={true}
                    className='editAccordion'
                    >
                        <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        sx={accordianSummarySx}
                        
                        >
                        Colors
                        </AccordionSummary>

                        <AccordionDetails
                        sx={{
                            padding: '8px 0px 0px'
                        }}
                        >

<div className="inputWrapper">

<ColorPicker
title="Buttons"
color={_SpinContext.currentSpinData.basicButtonBackgroundColor}
handleClick={handleClick_basicButtonBackgroundColor}
handleChange={handleColorChange_basicButtonBackgroundColor}
handleCancel={handleMenuCancel_basicButtonBackgroundColor}
handleClose={handleClose_basicButtonBackgroundColor}
anchor={anchorElement_basicButtonBackgroundColor}
open={open_basicButtonBackgroundColor}
/>
</div>
<div className="inputWrapper">
<ColorPicker
title="Page Background"
color={_SpinContext.currentSpinData.pageBackgroundColor}
handleClick={handleClick_pageBackgroundColor}
handleChange={handleColorChange_pageBackgroundColor}
handleCancel={handleMenuCancel_pageBackgroundColor}
handleClose={handleClose_pageBackgroundColor}
anchor={anchorElement_pageBackgroundColor}
open={open_pageBackgroundColor}
/>
</div>

<div className="inputWrapper">

<ColorPicker
title="Controls Menu"
color={_SpinContext.currentSpinData.settingsMenuBackgroundColor}
handleClick={handleClick_settingsMenuBackgroundColor}
handleChange={handleColorChange_settingsMenuBackgroundColor}
handleCancel={handleMenuCancel_settingsMenuBackgroundColor}
handleClose={handleClose_settingsMenuBackgroundColor}
anchor={anchorElement_settingsMenuBackgroundColor}
open={open_settingsMenuBackgroundColor}
/>
</div>

                        </AccordionDetails>

                    </Accordion>

               


                <Accordion
                    defaultExpanded={false}
                    className='editAccordion'
                    expanded={_SpinContext.currentSpinData.lazyLoadSpin}
                    onChange={(event, expanded) => {
                        console.log(event)
                        console.log(expanded)
                        handleLazyLoadSpinChange(expanded)
                    }}
                    >
                        <AccordionSummary
                        // expandIcon={<ExpandMore />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        sx={accordianSummarySx}
                        // onClick={handleLazyLoadSpinChange}
                        
                        >
                            <div
                            style={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                // height: _SpinContext.currentSpinData.lazyLoadSpin === true ? '1.5rem' : '1.5rem'
                                height: '1.5rem'


                            }}
                            >
                                <div>
                                Load Spin When Clicked
                                </div>
                               

                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: '4px',
                                    alignItems: 'center',
                                    pointerEvents: 'none',
                                    transform: 'translateX(6px)'

                                }}>
                                

<CustomSwitch
                                label={_SpinContext.currentSpinData.lazyLoadSpin === true ? 'On' : 'Off'}
                                tooltip= {
                                    _SpinContext.currentSpinData.lazyLoadSpin ? 'Load spin on page load' : 'Wait for click on spin to load'}
                                checked={_SpinContext.currentSpinData.lazyLoadSpin}
                                // handleChange={handleLazyLoadSpinChange}
                                />
                                </div>
                                </div>
                        </AccordionSummary>

                        <AccordionDetails
                        sx={{
                            padding: '8px 0px 0px'
                        }}
                        >
<div className="inputWrapper">
                                
                                </div>
                                {_SpinContext.currentSpinData  &&
                        <>
                                <div className="inputWrapper">

                          

                                <TextField 
                                value={decode(_SpinContext.currentSpinData.promptToLoad)} 
                                onChange={handleLazyLoadPromptTextChange} 
                                onClick = {showLazyLoadPromptPreview}

                                label="Load Prompt Text" 
                                variant="outlined" 
                                size="small"
                                sx={{
                                    width: 'calc(100% - 32px)',
                                    margin: '8px 16px 8px 16px'
                                }}
                                />


                                </div>



                            
                            <div className="inputWrapper">

                            <ColorPicker
                            title="Prompt Background"
                            color={_SpinContext.currentSpinData.lazyLoadPromptBackgroundColor}
                            handleClick={handleClick_lazyLoadPromptBackgroundColor}
                            handleChange={handleColorChange_lazyLoadPromptBackgroundColor}
                            handleCancel={handleMenuCancel_lazyLoadPromptBackgroundColor}
                            handleClose={handleClose_lazyLoadPromptBackgroundColor}
                            anchor={anchorElement_lazyLoadPromptBackgroundColor}
                            open={open_lazyLoadPromptBackgroundColor}
                            />
                            </div>
                            <div className="inputWrapper">

                            <ColorPicker
                            title="Prompt Text"
                            color={_SpinContext.currentSpinData.lazyLoadPromptTextColor}
                            handleClick={handleClick_lazyLoadPromptTextColor}
                            handleChange={handleColorChange_lazyLoadPromptTextColor}
                            handleCancel={handleMenuCancel_lazyLoadPromptTextColor}
                            handleClose={handleClose_lazyLoadPromptTextColor}
                            anchor={anchorElement_lazyLoadPromptTextColor}
                            open={open_lazyLoadPromptTextColor}
                            />
                            </div>
                            <div className="inputWrapper">
                            <CustomSwitch
                            label="Dropshadow"
                            tooltip={_SpinContext.currentSpinData.lazyLoadPromptShowDropshadow === true ? 'Click to hide dropshadow' : 'Click to show dropshadow'}
                            checked={_SpinContext.currentSpinData.lazyLoadPromptShowDropshadow}
                            handleChange={handleLazyLoadDropshadowChange}
                            />
                            </div>
                            <div className="inputWrapper opacitySlider" style={{minWidth: '200px', padding: '8px 16px'}}>
                                
                            <div >
                        

                            <CustomSlider
                            value={_SpinContext.currentSpinData.lazyLoadPromptOpacity}
                            handleChange={(value) => {
                                showLazyLoadPromptPreview()
                                handleChange('lazyLoadPromptOpacity', value)
                            }}
                            step={10}
                            marks={promptOpacityMarks}
                            min={0}
                            max={100}
                            />
                       
                                </div>
                                </div>
                        </>
                        }
                        
                        </AccordionDetails>

                    </Accordion>

             








                        <Accordion
                    defaultExpanded={false}
                    className='editAccordion'
                    expanded={_SpinContext.currentSpinData.promptShow}
                    onChange={(event, expanded) => {
                        console.log(event)
                        console.log(expanded)
                        handlePromptShowChange(expanded)
                    }}
                    >
                        <AccordionSummary
                        // expandIcon={<ExpandMore />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        sx={accordianSummarySx}
                        // onClick={handleLazyLoadSpinChange}
                        
                        >
                            <div
                            style={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                // height: _SpinContext.currentSpinData.lazyLoadSpin === true ? '1.5rem' : '1.5rem'
                                height: '1.5rem'


                            }}
                            >
                                <div>
                                Prompt to Spin
                                </div>
                               

                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: '4px',
                                    alignItems: 'center',
                                    pointerEvents: 'none',
                                    transform: 'translateX(6px)'
                                }}>
                                

<CustomSwitch
                                label={_SpinContext.currentSpinData.promptShow === true ? 'On' : 'Off'}
                                tooltip= {
                                    _SpinContext.currentSpinData.promptShow ? 'Click to hide overlay prompt' : 'Click to show overlay prompt'}
                                checked={_SpinContext.currentSpinData.promptShow}
                                // handleChange={handleLazyLoadSpinChange}
                                />
                                </div>
                                </div>
                        </AccordionSummary>










                        <AccordionDetails
                        sx={{
                            padding: '8px 0px 0px'
                        }}
                        >


                        {_SpinContext.currentSpinData 
                        // && _SpinContext.currentSpinData.promptShow 
                        &&
                        <>
                                <div className="inputWrapper">

                          

                            <TextField 
                            value={decode(_SpinContext.currentSpinData.promptToSpin)} 
                            onChange={handlePromptTextChange} 
                            onClick={showPromptPreview}
                            label="Overlay Prompt Text" 
                            variant="outlined" 
                                size="small"
                                sx={{
                                    width: 'calc(100% - 32px)',
                                    margin: '8px 16px 8px 16px'
                                }}
                            />

                           
                            </div>



                            <div className="inputWrapper">
                            <CustomSwitch
                            label="Dropshadow"
                            tooltip={_SpinContext.currentSpinData.promptShowDropshadow === true ? 'Click to hide dropshadow' : 'Click to show dropshadow'}
                            checked={_SpinContext.currentSpinData.promptShowDropshadow}
                            handleChange={handleDropshadowChange}
                            />
                            </div>
                            <div className="inputWrapper">

                            <ColorPicker
                            title="Prompt Background"
                            color={_SpinContext.currentSpinData.promptBackgroundColor}
                            handleClick={handleClick_promptBackgroundColor}
                            handleChange={handleColorChange_promptBackgroundColor}
                            handleCancel={handleMenuCancel_promptBackgroundColor}
                            handleClose={handleClose_promptBackgroundColor}
                            anchor={anchorElement_promptBackgroundColor}
                            open={open_promptBackgroundColor}
                            />
                            </div>
                            <div className="inputWrapper">

                            <ColorPicker
                            title="Prompt Text"
                            color={_SpinContext.currentSpinData.promptTextColor}
                            handleClick={handleClick_promptTextColor}
                            handleChange={handleColorChange_promptTextColor}
                            handleCancel={handleMenuCancel_promptTextColor}
                            handleClose={handleClose_promptTextColor}
                            anchor={anchorElement_promptTextColor}
                            open={open_promptTextColor}
                            />
                            </div>
                            <div className="inputWrapper" style={{minWidth: '200px', padding: '8px 16px'}}>
                                
                            <div style={{margin: '0px'}}>
                        

                            <CustomSlider
                            value={_SpinContext.currentSpinData.promptOpacity}
                            handleChange={(value) => {
                                showPromptPreview()
                                handleChange('promptOpacity', value)
                            }}
                            step={10}
                            marks={promptOpacityMarks}
                            min={0}
                            max={100}
                            />
                       
                                </div>
                                </div>
                        </>
                        }
                        
                        </AccordionDetails>

                    </Accordion>


          

                    <ReorderImages loggedIn={loggedIn} changeIndex={(value) => handleChange('firstImageLoadLindex', value)} prepareImages={handleShowReorderImages} />
                    

                    <Accordion
                    defaultExpanded={false}
                    className='editAccordion'
                    sx={{
                        border: '1px solid #ffbcbc !important'
                    }}
                    >
                        <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        sx={accordianSummarySx}
                        >
                            <div 
                            style={{
                                color: 'red'
                            }}>
                            Danger Zone
                            </div>
                        </AccordionSummary>










                        <AccordionDetails
                        sx={{
                            padding: '8px'
                        }}
                        >

                        <div
                        style={{
                            padding: '8px 16px'
                        }}
                        >
                            <Button 
                            aria-controls="basic-menu"
                            aria-haspopup="true"
                            aria-expanded={deleteConfirmOpen ? 'true' : undefined}
                            onClick={handleDeleteConfirmClick}
                            sx={{
                                background: 'red !important'
                            }} variant="contained">Delete Spin</Button>

                                   
<Menu
        id="basic-menu"
        anchorEl={deleteConfirmAnchorEl}
        open={deleteConfirmOpen}
        onClose={handleDeleteConfirmClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        
      >
        <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '10px',
          gap: '10px',
          justifyContent: 'center',
          alignItems: 'center',
            maxWidth: '400px'
        }}
        >
        <div
        style={{
          textAlign: 'center'

        }}
        >

        Are you sure you want to delete this spin? You will not be able to undo this action. This spin and its images will be permanently deleted.
        </div>
       
        <div>
        <Button 
        onClick={handleDeleteConfirmClose}
        >Cancel</Button>
        <Button variant="contained"
        onClick={() => {
          handleDeleteConfirmClose()
          handleDeleteSpin()
        }}
        sx={{
            background: 'red !important'
        }}
        >Yes, Delete Spin</Button>
        </div>
        </div>
      </Menu>

                        </div>
                        
                        </AccordionDetails>

                    </Accordion>


                    
                
            </div>
        </div>
        </div>
        }
    </div>

  );
};

export default EditSettings;