const TranslateBytes = (bytes) => {
    if (bytes > 1099511627776) {
        return `${Math.round(((bytes/1099511627776) + Number.EPSILON) * 100) / 100} TB`
    }
    if (bytes > 1073741824) {
        return `${Math.round(((bytes/1073741824) + Number.EPSILON) * 100) / 100} GB`
    } 
    if (bytes > 1048576) {
        return `${Math.round(((bytes/1048576) + Number.EPSILON) * 100) / 100} MB`
    } 
    if (bytes > 1024) {
        return `${Math.round(((bytes/1024) + Number.EPSILON) * 100) / 100} KB`
    } 
    return `${bytes} B`
}

export default TranslateBytes;