const CorrectNumberBounce = (num, max) => {
    var input = num;
    var absInput = Math.abs(num);
    if (input < 0) {
        return 0;
      
    } else if (input >= max ) {
        return max - 1;
    } else {
        return input
    }
}

export default CorrectNumberBounce;