import React, { useContext, useState, useCallback, useEffect } from "react";
import { Link } from "react-router-dom";

import {Backdrop, Button} from '@mui/material';

import ViewSpin from "../spin/ViewSpin";
import AppContext from "../../contexts/AppContext"
import { internal_resolveProps } from "@mui/utils";

const Welcome = (props) => {
    const _AppContext = useContext(AppContext)

    const [spinExists, setSpinExists] = useState(false)
    const spinForWelcome = 2;

    const checkForSpin = (spinId) => {
        return new Promise(async (resolve, reject) => {
            await fetch(`${window.location.origin}/v/${spinId}`)
            .then(res => {
                if (res.status == 200) {
                    resolve(true)
                } else {
                    resolve(false)
                }
            })
        })
        
     
    }

    useEffect(async () => {
        if (spinForWelcome) {
            setSpinExists(await checkForSpin(spinForWelcome))
            console.log(await checkForSpin(spinForWelcome))
        }
    }, [spinForWelcome])

  

    return (
        <div style={{
            maxWidth: '1000px',
            // margin: '20vh 20px 20vh 20px', //maybe 
            display: 'flex',
            // flexDirection: 'row',
            flexWrap: 'wrap',
            alignItems: 'center'
        }}
    className="welcomeWrapper"
    >

<div
    className="welcomeItemWrapper"
        style={{
            justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column'
        }}
>
    <div>
<div style={{
                fontSize: '48px',
                fontWeight: '400',
                // flex: 2,
                // width: '100%',
                marginBottom: '20px',
                maxWidth: '400px',
                

            }}>
            Show your product from all sides.
            </div>

            <Button component={Link} size="large" to="/demo" variant="contained" >Take us for a spin</Button>
</div>
</div>
            

            <div
          
            className="welcomeItemWrapper welcomeSpin"
            
            >
                { spinExists === true ?
                <ViewSpin spinId={spinForWelcome}/>
                :
                <>booppo</>
                }

            </div>

        </div>

        
    
    
    );
};

export default Welcome;