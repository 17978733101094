import React from "react";
import {LinearProgress, Typography, Box} from '@mui/material';

const ProgressLoad = (props) => {
  const {color, value, showLabel} = props
  return (


<Box sx={{ width: '50%', minWidth: '200px', maxWidth: '100%', display: 'flex', alignItems: 'center' }}>
<Box sx={{ width: '100%',mr: 1 }}>
  <LinearProgress className="uploadProgressBar" variant="determinate" value={value} />
</Box>
{
  showLabel == true && 
<Box sx={{ minWidth: 35 }}>
  <Typography color={color ? color : '#1976d2'}>
    {`${Math.round(
    value,
  )}%`}
  </Typography>
</Box>

}

</Box>


  );
};

export default ProgressLoad;