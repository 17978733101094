import React, { useContext, useEffect, useState, useCallback } from "react";
import AppContext from '../contexts/AppContext'
import { useAuth0 } from "@auth0/auth0-react";
import {Switch, Button, TextField, Modal, Box} from '@mui/material';

import TranslateProductId from "../functions/TranslateProductId";

const Home = () => {
    const _AppContext = useContext(AppContext)
    const { loginWithRedirect } = useAuth0();


    const loginReturnUri = `${window.location.origin}/pricing`

    const [currentPlan, setCurrentPlan] = useState(null)
    const [open, setOpen] = useState(false);
    const [modalContent, setModalContent] = useState(null)
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
      setOpen(false)
      setModalContent(null)
    };

    const handleManageBillingClick = useCallback(
        async () => {
            const accessToken = await _AppContext.getAccessTokenSilently({
                audience: `https://dev-backend.prosp.in/`,
              });
            fetch(`${_AppContext.baseBackendUrl}/api/Payments/create-portal-session?returnUrl=${window.location.href}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,

                }
            }).then(res => res.text())
            .then(data => {
                window.location.href = data
            })
        }, [_AppContext]
        )

        const handleCheckoutClick = useCallback(
            async (price) => {
                const accessToken = await _AppContext.getAccessTokenSilently({
                    audience: `https://dev-backend.prosp.in/`,
                  });
                fetch(`${_AppContext.baseBackendUrl}/api/Payments/create-checkout-session?priceId=${price}&originUrl=${window.location.origin}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${accessToken}`,
    
                    }
                }).then(res => res.text())
                .then(data => {
                    window.location.href = data
                })
            }, [_AppContext]
            )


    const handleModelClick = useCallback(
        (type) => {
            setModalContent( () => {
                if (!_AppContext.userInfo_Octospin) {
                    return
                }
                var actionWord = ''
                switch (type) {
                    case 'basic':
                        actionWord = 'cancel'
                    break;
                    default:
                        actionWord = 'update'
                    break ;
                }
                return (
                    <div>
                            
                                <div>
                                    You'll need to {actionWord} your{type === 'basic' ? ' paid ': ' '}plan in the billing portal to switch to {type}.
                                </div>
                            
                           
                        


                    <Button variant="contained" onClick={handleManageBillingClick} type="submit">Manage Billing V2</Button>


                    </div>
                    
                    )
            }
                
                
            )
            handleOpen()
        }, [_AppContext.userInfo_Octospin]
    )

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      };

    useEffect( () => {
        if (_AppContext.userInfo_Octospin !== null) {
            console.log(_AppContext.userInfo_Octospin.subscriptionProductId)
            setCurrentPlan(TranslateProductId(_AppContext.userInfo_Octospin.subscriptionProductId))

            
        }

    }, [_AppContext.userInfo_Octospin, _AppContext.isAuth])

    return (
        <div>

            
            
            <div className="priceWrapper">
                
                <div className="priceGroup">
                    <div className="priceTitleSection">
                        Basic
                    </div>
                    <div className="priceAmountSection">
                        Free
                    </div>
                    <div className="priceDescriptionSection">
                        description
                    </div>
                    <div className="priceActionSection">
                        
                        { !_AppContext.isAuth &&
                            <>
                                <Button variant="contained"
                                onClick={() => loginWithRedirect({ redirectUri: loginReturnUri })}
                                >Log in to Start for Free</Button>
                            </>
                        
                        }
                        { _AppContext.isAuth && _AppContext.userInfo_Octospin &&
                        

                            <>


                            { currentPlan === 'basic' &&
                                <>
                                    

                        <Button variant="contained" disabled={currentPlan === 'basic' ? true : false} onClick={handleCheckoutClick}>
                            {currentPlan === 'basic' ? "Current Plan" : "Get Basic"}
                        </Button>

                                </>
                            }

                            { currentPlan !== 'basic' &&
                                <>
                                    <Button variant="contained"
                                    onClick={() => {handleModelClick('basic')}}
                                    >Switch to Basic</Button>

                                </>
                            }
                            </>
                        
                        }

                    </div>
                    
                    
                    
                </div>

                <div  className="priceGroup">
                    <div className="priceTitleSection">
                        Advanced
                    </div>
                    <div className="priceAmountSection">
                        $9
                    </div>
                    <div className="priceDescriptionSection">
                        description
                    </div>
                    <div className="priceActionSection">
                    
                        { !_AppContext.isAuth &&
                            <>
                                <Button variant="contained"
                                onClick={() => loginWithRedirect({ redirectUri: loginReturnUri })}
                                >Log in to Get Advanced</Button>
                            </>
                        
                        }
                        { _AppContext.isAuth &&  _AppContext.userInfo_Octospin &&
                            

                            <>


                            { currentPlan === 'basic' &&
                                <>
                                    

                                    <Button variant="contained" disabled={currentPlan === 'advanced' ? true : false} onClick={() => {handleCheckoutClick('price_1Js42IA0EWeE6cfl3T0vh8sC') }}>
                                        {currentPlan === 'advanced' ? "Current Plan" : "Get Advanced"}
                                    </Button>

                                </>
                            }

                            { currentPlan !== 'basic' &&
                                <>
                                    <Button variant="contained"
                                        disabled={currentPlan === 'advanced' ? true : false}
                                        onClick={() => {handleModelClick('advanced')}}
                                        >{currentPlan === 'advanced' ? "Current Plan" : "Switch to Advanced"}
                                        </Button>
                                </>
                            }
                            </>
                        
                        }
                    </div>

                    

                </div>
                <div className="priceGroup">
                <div className="priceTitleSection">
                        Premium
                    </div>
                    <div className="priceAmountSection">
                        $19
                    </div>
                    <div className="priceDescriptionSection">
                        description
                    </div>
                    <div className="priceActionSection">
                    
                    
                        { !_AppContext.isAuth &&
                            <>
                                <Button variant="contained"
                                onClick={() => loginWithRedirect({ redirectUri: loginReturnUri })}
                                >Log in to Get Premium</Button>
                            </>
                        
                        }
                        { _AppContext.isAuth && _AppContext.userInfo_Octospin &&
                            <>


                                { currentPlan === 'basic' &&
                                    <>

                                        <Button variant="contained" disabled={currentPlan === 'premium' ? true : false} onClick={() => {handleCheckoutClick('price_1Js447A0EWeE6cflI1961rq4') }}>
                                        {currentPlan === 'premium' ? "Current Plan" : "Get Premium"}
                                    </Button>
                                    </>
                                }

                                { currentPlan !== 'basic' &&
                                    <>

                                        
                                        <Button variant="contained"
                                        disabled={currentPlan === 'premium' ? true : false}
                                        onClick={() => {handleModelClick('premium')}}
                                        >{currentPlan === 'premium' ? "Current Plan" : "Switch to Premium"}
                                        </Button>
                                    </>
                                }
                            </>
                        }

                    
                    </div>

                </div>
                <div className="priceGroup">
                    <div className="priceTitleSection">
                        Ultimate
                    </div>
                    <div className="priceAmountSection">
                        $49
                    </div>
                    <div className="priceDescriptionSection">
                        description
                    </div>
                    <div className="priceActionSection">
                    
                    
                        { !_AppContext.isAuth &&
                            <>
                                <Button variant="contained"
                                onClick={() => loginWithRedirect({ redirectUri: loginReturnUri })}
                                >Log in to Get Ultimate</Button>
                            </>
                        
                        }
                        { _AppContext.isAuth && _AppContext.userInfo_Octospin &&
                            <>
                                { currentPlan === 'basic' &&
                                    <>
                                    

                                        <Button variant="contained" disabled={currentPlan === 'ultimate' ? true : false} onClick={() => {handleCheckoutClick('price_1Js44mA0EWeE6cflDny5ELUl') }}>
                                            {currentPlan === 'ultimate' ? "Current Plan" : "Get Ultimate"}
                                        </Button>
                                    </>
                                }

                                { currentPlan !== 'basic'  &&
                                    <>
                                        <Button variant="contained"
                                        disabled={currentPlan === 'ultimate' ? true : false}
                                        onClick={() => {handleModelClick('ultimate')}}
                                        >{currentPlan === 'ultimate' ? "Current Plan" : "Switch to Ultimate"}
                                        </Button>
                                    </>
                                }


                            </> 
                        

                        
                            
                            
                        }
                    </div>

                    
                </div>
            {modalContent !== null &&
            <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style}>
                            {modalContent}
                            
                        </Box>
                    </Modal>
            }
        </div>
        </div>
    )
};

export default Home;