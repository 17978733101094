import React, { useCallback, useEffect, useRef, useState } from "react";

import { Dialog, DialogContent, DialogContentText, DialogActions, Button, Box, DialogTitle } from '@mui/material';

import ViewSpin from "../spin/ViewSpin";

import useWindowDimensions from "../../custom-hooks/useWindowDimensions";

function PreviewDialog(props) {
    const { onClose, open, id } = props;
    const windowDimensions = useWindowDimensions();
  
    const handleClosePreviewDialog = () => {
      //cleanup stuff on close
      onClose();
    };

    // const dialog = useRef(null)
 
    const [spinDimensions, setSpinDimensions] = useState({ width: 300, height: 300})

    const handleRef = useCallback(
    (el) => {
        if (el) {
            console.log('a4234', el.offsetWidth)
            console.log('a4234', el.offsetHeight)
            setSpinDimensions({ width: el.offsetWidth, height: el.offsetHeight})
        }
        
    }, [windowDimensions]
    )
  
    return (
      <Dialog fullWidth={true}
        maxWidth={false} onClose={handleClosePreviewDialog} open={open}
        
        PaperProps={{ sx: { width: "100%", height: "80%", margin: '0px' } }}
        >
          
       {/* <DialogTitle>Optional sizes</DialogTitle> */}
       <DialogActions>
          <Button size="small" onClick={handleClosePreviewDialog}>Close</Button>
        </DialogActions>
        <DialogContent
        sx={{
          padding: 0
        }}
        >
          
          <Box
            ref={handleRef}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              m: 'auto',
              width: '100%',
              height: '100%'
            }}
          >
      

<ViewSpin spinId={id} dimensions={spinDimensions}/>
          </Box>
        </DialogContent>
        
      </Dialog>
    );
  }

export default PreviewDialog;