import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'


import AppContext from '../../contexts/AppContext'
import UpdateImagesContext from '../../contexts/UpdateImagesContext';
import SpinContext from '../../contexts/SpinContext';


import {
  DndContext, 
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  TouchSensor,
  MouseSensor,
  useSensor,
  useSensors,
  DragOverlay
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  rectSortingStrategy,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';

import { SortableImage } from '../dragndrop/SortableImage';

import { 
  AddCircle,
  Cancel,
  Add,
  Check,
  SettingsBackupRestore,
  Close, FormatColorReset
} from '@mui/icons-material';
import { 

  Button, 
  Tooltip, 
  Slider, 
  Switch,
  Menu, 
  MenuItem, 
  IconButton, 
  Icon,
  FormControl,
  InputLabel,
  Select,
  Backdrop,
  CircularProgress
} from '@mui/material'
import { WrappedImage } from '../dragndrop/WrappedImage';
import ProgressLoad from '../loading/ProgressLoad';

const FileUpload = (props) => {

  var {mode, useCase, imageUploadState, filesLength, uploadSpin, changeIndex, loggedIn} = props

  const inputEl = useRef(null)

    const _AppContext = useContext(AppContext)
    const _UpdateImagesContext = useContext(UpdateImagesContext)
    const _SpinContext = useContext(SpinContext)

    const [imagesToBeUploaded, setImagesToBeUploaded] = useState([])
    const [currentlyDragging, setCurrentlyDragging] = useState(false)

    const [isLive, setIsLive] = useState(true)

    const [restartAnchorEl, setRestartAnchorEl] = React.useState(null);
    const restartOpen = Boolean(restartAnchorEl);
    const handleRestartClick = (event) => {
      setRestartAnchorEl(event.currentTarget);
    };
    const handleRestartClose = () => {
      setRestartAnchorEl(null);
    };

    const [activeId, setActiveId] = useState(null);
    const sensors = useSensors(
      useSensor(TouchSensor, {
        // Press delay of 250ms, with tolerance of 5px of movement
        activationConstraint: {
          delay: 250,
          tolerance: 5,
        },
      }),
     
      useSensor(MouseSensor),
      useSensor(KeyboardSensor, {
        coordinateGetter: sortableKeyboardCoordinates,
      })
    );

    const handleDragEnd = useCallback(
     (event) => {
      console.log(event)
      setCurrentlyDragging(false)
      const {active, over} = event;
      if (!active || !over) {
        return
      }
      if (active.id !== over.id) {
        _UpdateImagesContext.changeUploadedFiles((items) => {
          console.log(items.findIndex(img => img.id == active.id))
          const oldIndex = items.findIndex(img => img.id == active.id);
          const newIndex = items.findIndex(img => img.id == over.id);
          
          return arrayMove(items, oldIndex, newIndex);
        });
      }
      setActiveId(null);
    }, [_UpdateImagesContext.changeUploadedFiles]
    )




    function handleDragStart(event) {
      setActiveId(event.active.id);
      setCurrentlyDragging(true)
    }
    function handleDragCancel() {
      setActiveId(null);
      setCurrentlyDragging(false)
    }


    const handleRemoveAll = useCallback(
    () => {
      if (mode === 'edit') {
        _UpdateImagesContext.changeUploadedFiles(prev => [prev[0]])

      } else {
        _UpdateImagesContext.changeUploadedFiles([])

      }
    },[_UpdateImagesContext.changeUploadedFiles, mode])

    const [selectedSort, setSelectedSort] = useState('createdDateOldToNew')


    const handleImageSort = useCallback((value) => {
      if (!_UpdateImagesContext || !_UpdateImagesContext.contextFiles) {
        return
      }
      setSelectedSort(value)
      console.log(value)
      switch (value) {
        case 'imageNameAToZ': 
        _UpdateImagesContext.changeUploadedFiles(prev => {          
          return prev.sort((a,b) => {
            var nameA = a.file.name.toUpperCase(); // ignore upper and lowercase
            var nameB = b.file.name.toUpperCase(); // ignore upper and lowercase
            if (!nameA || !nameB || nameA.length == 0 || nameB.length == 0) {
              return 0
            }
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          })
        })
      return;
        case 'imageNameZToA': 
        _UpdateImagesContext.changeUploadedFiles(prev => {          
          return prev.sort((a,b) => {
            var nameA = a.file.name.toUpperCase(); // ignore upper and lowercase
            var nameB = b.file.name.toUpperCase(); // ignore upper and lowercase
            if (!nameA || !nameB || nameA.length == 0 || nameB.length == 0) {
              return 0
            }
            if (nameA > nameB) {
              return -1;
            }
            if (nameA < nameB) {
              return 1;
            }
            return 0;
          })
        })        
      return;
        case 'createdDateNewToOld': 
        _UpdateImagesContext.changeUploadedFiles(prev => prev.sort((a,b) => (b.file.lastModified - a.file.lastModified)))
      return;
        case 'createdDateNewToOld': 
        _UpdateImagesContext.changeUploadedFiles(prev => prev.sort((a,b) => (a.file.lastModified - b.file.lastModified)))
      return;
        default: 
        _UpdateImagesContext.changeUploadedFiles(prev => prev.sort((a,b) => (a.file.lastModified - b.file.lastModified)))
      return;
      }
      
    }, [_UpdateImagesContext.changeUploadedFiles, _UpdateImagesContext.contextFiles])


    const handleFileInput = useCallback(
      async (e) => {
        e.preventDefault()
        _UpdateImagesContext.changeImageUploadInProgress(true)
        //filter out files without image as mimetype
        const filteredUploadedArray = Array.from(e.target.files).filter(x => x.type.split('/')[0].toLowerCase() === 'image')
        //sort images by data created old to new
        const sortedUploadedArray = filteredUploadedArray.sort((a,b) => (a.lastModified - b.lastModified))
        const newArrayToAdd = await Promise.all(sortedUploadedArray.map(x => {
          
          const url = window.URL || window.webkitURL    
          const imgUrl = url.createObjectURL(x)
          return { url: imgUrl, id: imgUrl, file: x}
        })
        )

        console.log(newArrayToAdd)
        _UpdateImagesContext.changeUploadedFiles(prev => prev.concat(newArrayToAdd))
        _UpdateImagesContext.changeImageUploadInProgress(false)
        if (inputEl.current) {
          inputEl.current.value = null

        }
      }, [_UpdateImagesContext.changeUploadedFiles, _UpdateImagesContext.changeImageUploadInProgress, inputEl]
    )


  const [uploadTotal, setUploadTotal] = useState(1)
  const [uploadProgress, setUploadProgress] = useState(0)
    
    const handleFileInputEditPage = useCallback(
      async (e, numOfExistingImages) => {        
        e.preventDefault()
        _UpdateImagesContext.changeImageUploadInProgress(true)
        setUploadTotal(e.target.files.length)
        //filter out files without image as mimetype
        const filteredUploadedArray = Array.from(e.target.files).filter(x => x.type.split('/')[0].toLowerCase() === 'image')
        //sort images by data created old to new
        const sortedUploadedArray = filteredUploadedArray.sort((a,b) => (a.lastModified - b.lastModified))

        const accessToken = await _AppContext.getAccessTokenSilently({
          audience: `https://dev-backend.prosp.in/`,
        });

        const newArrayToAdd = await Promise.all(sortedUploadedArray.map(async (file, index) => {

    





          var formData = new FormData();
            formData.append("passedFile", file);
            // console.log(fileList[a])
            var response;
            var newIndex = numOfExistingImages + index

            await fetch(`${_AppContext.baseBackendUrl}/api/Image/UploadWithBunny?spinId=${_SpinContext.currentSpinData.id}&order=${newIndex}`, {
                method: 'POST',
                headers: {
                'Accept': '*/*',
                // 'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${accessToken}`,
                },
                body: formData
            })
            .then(res => res.json())
            .then(data => {
                response = {
                    id: data.id,
                    order: data.order,
                    storagePath: data.storagePath,
                    mimeType: data.mimeType,
                    live: data.live,
                    // cloudflareId: data.cloudflareId,
                    // signedUrl: data.signedUrl
                }
                console.log(data)
                setUploadProgress(prev => prev + 1)
            })
            return { 
              url: `${_SpinContext.currentSpinData.pullZoneBaseUrl}/${response.storagePath}${_SpinContext.currentSpinData.requestTokenQueryThumbnail}`,  
              id: response.id, 
              fullImageData: response
            }






        })
        )
        _UpdateImagesContext.changeUploadedFiles(prev => prev.concat(newArrayToAdd))
        _UpdateImagesContext.changeImageUploadInProgress(false)
        inputEl.current.value = null
        setUploadTotal(0)
        setUploadProgress(0)

      }, [_UpdateImagesContext.changeUploadedFiles, _UpdateImagesContext.changeImageUploadInProgress, inputEl, setUploadProgress]
    )



    return (
        <div


      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
      }}
        >
          {
                 mode === 'edit' && _UpdateImagesContext.imageUploadInProgress === true &&
           

                <Backdrop
                sx={{ 
                    color: '#fff', 
                    zIndex: 1000,
                    backgroundColor: 'hsl(0deg 0% 16% / 25%)'
                }}
                open={_UpdateImagesContext.imageUploadInProgress}
                // onClick={handleClose}
                >
                {/* <LoadingSpinnerCenter color="white"/> */}

                <div className="uploadProgressWrapper">


                <div style={{
                        position: 'relative'
                    }}>

                    <CircularProgress size={50} thickness={2.2} />
                    {/* {!isProcessingSpin && */}
                    <div style={{
                        position: 'absolute',
                        top: '50%',
                        fontSize: '1em',
                        fontWeight: '600',
                        transform: 'translateY(-65%)',
                        textAlign: 'center',
                        width: '100%',
                    }}>
                { `${uploadProgress/uploadTotal == 0 ? 1 :  uploadProgress/uploadTotal > 99 ? 99 : Math.round(100*(uploadProgress/uploadTotal))}%`}
                {/* {`00%`} */}
                        </div>
                    {/* } */}
                    
                </div>


                <ProgressLoad color="black" 
                value={uploadProgress/uploadTotal == 0 ? 1 : uploadProgress/uploadTotal > 99 ? 99 : 100*(uploadProgress/uploadTotal)} 
                />
                <div>
                { `Hold tight. We're uploading your images. Please do not refresh or close this page.`}

                </div>
                </div>

                </Backdrop>

                    }

          <div
          className={_UpdateImagesContext.contextFiles.length === 0 ? "actionWrapperNoFiles" : "actionWrapperWithFiles"}
          >
            <Tooltip 
            disableFocusListener={loggedIn == false ? false : true} 
            disableHoverListener={loggedIn == false ? false : true} 
            disableTouchListener={loggedIn == false ? false : true} 
            title='Add images (disabled during demo)' placement='bottom'>
            <label 
        
            // className="addImagesButton"
            className={_UpdateImagesContext.contextFiles && _UpdateImagesContext.contextFiles.length === 0 ? 'addImagesButton addImagesButtonLarge' : 'addImagesButton'}
            >
            { 
            mode === 'edit' ?
            
            <>
            { loggedIn == false ?
            <input ref={inputEl} type="submit" />
            
            

            :
              <input 
          
              ref={inputEl} onChange={(e) => handleFileInputEditPage(e, _UpdateImagesContext.contextFiles.length)} type="file" multiple/>
            }
              
            </>
              : 
              
              
            
            <input ref={inputEl} onChange={handleFileInput} type="file"  multiple/>
            }
            <Add />
            <span className="addImagesWords">Add<br/> Images</span>
            </label>
            </Tooltip>
          

                <label 
            style={{
              display: _UpdateImagesContext.contextFiles.length > 0 ? 'flex' : 'none'
            }}
            className="addImagesButton basicActionButton"
            id="basic-button"
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={restartOpen ? 'true' : undefined}
        onClick={handleRestartClick}

            >
            
      <SettingsBackupRestore />

      <span className="basicActionWords">Restart</span>
            

                </label>

                
      <Menu
        id="basic-menu"
        anchorEl={restartAnchorEl}
        open={restartOpen}
        onClose={handleRestartClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '10px',
          gap: '10px',
          justifyContent: 'center',
          alignItems: 'center',
          maxWidth: '400px',
          width: '100%'

        }}
        >
        <div
        style={{
          textAlign: 'center'

        }}
        >

        {`Are you sure you want to restart and remove ${ mode != 'edit' ? 'all images?' : 'all images except for one? (spins are required to have one image)'}`}
        </div>
       
        <div>
        <Button 
        onClick={handleRestartClose}
        >Cancel</Button>
        <Button variant="contained"
        onClick={() => {
          handleRestartClose()
          handleRemoveAll()
        }}
        >Yes</Button>
        </div>
        </div>
      </Menu>



            { mode === 'create' && filesLength > 0 &&
            <>

              <FormControl 
                sx={{
                width: '100px',
                maxWidth: '100px'
                }}>
                        <InputLabel 
                        className="customSettingsSelect"
                        >Sort</InputLabel>
                        <Select
                        value={selectedSort}
                        size="small"
                        label="Sort"
                        sx={{
                            maxWidth: '100px',
                            width: '100px',
                            textOverflow: 'ellipsis'
                        }}
                        onChange={(e) => {
                          handleImageSort(e.target.value)
                        }}
                        >
                                    <MenuItem value="createdDateNewToOld">New to Old</MenuItem>
                                    <MenuItem value="createdDateOldToNew">Old to New</MenuItem>
                                    <MenuItem value="imageNameAToZ">A to Z</MenuItem>
                                    <MenuItem value="imageNameZToA">Z to A</MenuItem>
                    
                        </Select>
                    </FormControl>
       


            <label 
            style={{
              display: _UpdateImagesContext.contextFiles.length > 0 ? 'flex' : 'none'
            }}
            className="addImagesButton">
           
            

<Switch 
                    // className={passedClass ? passedClass : "basicSwitch"}
                    size="small"
                    checked={isLive} 
                    onChange={() => {setIsLive(prev => !prev)}} 
                    color="primary"
                    />
            <span className="basicActionWords">Public</span>

                </label>


<label 
            style={{
              display: _UpdateImagesContext.contextFiles.length > 0 ? 'flex' : 'none'
            }}
            className="addImagesButton createContinueButton">
            <input variant="contained"
                disabled={imageUploadState || filesLength === 0 ? true : false}
                
                onClick={() => {uploadSpin(isLive)}}
                
                type="button" />
            <Check />
            <span className="continueActionWords">Continue</span>

                </label>


                </>
            }
          </div>      

          


      


    
      <DndContext 
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
      onDragCancel={handleDragCancel}
      autoScroll={{
        threshold: {
          x: 0,
          y: 0.2,
        },
        acceleration: 5,
        
      }}
    >
      <SortableContext 
        items={_UpdateImagesContext.contextFiles ? _UpdateImagesContext.contextFiles : []}
        strategy={rectSortingStrategy}
      >
        <div
        className="sortableGrid"
        >
        {_UpdateImagesContext.contextFiles && _UpdateImagesContext.contextFiles.map((x, index) => 
          <WrappedImage 
          // handleCorrectionIndex={() => {correctIndex(index)}} 
          mode={mode}
          index={index} key={x.id} currentlyDragging={currentlyDragging} x={x}/>
        )}
        { mode === 'edit' && _UpdateImagesContext.imageUploadInProgress === true &&
          <div
          style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
          >
            <CircularProgress
          style={{color: '#1976d2'}}
          size={32}
          />
          </div>
          }
        </div>
      </SortableContext>
      
      <DragOverlay
      
      adjustScale={true}>
        <div
        
        >
        {activeId && _UpdateImagesContext.contextFiles.findIndex(x => x.id == activeId) >= 0 ? (
          <div 
          className="currentlyDraggingOverlay"
          style={{
            transform: 'scale(1.06)',
          }}
          >
            <SortableImage
            
            grabbedElement={true}
            index={_UpdateImagesContext.contextFiles.findIndex(img => img.id == activeId)} 
            // cloudflareId={imagesToBeUploaded.find(x => x.id == activeId).cloudflareId} 
            imgUrl={_UpdateImagesContext.contextFiles.find(x => x.id == activeId).url}
            />
          </div>
          
        ) : null
      }
      
          </div>
       
      </DragOverlay>

    </DndContext>

    {/* {JSON.stringify(_UpdateImagesContext.contextFiles)} */}
      </div>
    )
};

export default FileUpload;