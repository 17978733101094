import React, { useCallback, useEffect, useRef, useState } from "react";

import { 
    Accordion, 
    AccordionSummary,
    AccordionDetails,
    Dialog, 
    DialogContent, 
    DialogContentText, 
    DialogActions, 
    Button, 
    Box, 
    DialogTitle, 
    TextField,
    IconButton
} from '@mui/material';

import { ExpandMore, ContentCopy, Check } from '@mui/icons-material';

import EmbedShare from "./sharePanels/EmbedShare";


function ShareDialog(props) {
    const { spinId, onClose, open } = props;
  
    const handleCloseShareDialog = () => {
      //cleanup stuff on close
      onClose();
    };

    const [copied, setCopied] = useState(false)

    const [spinUrl, setSpinUrl] = useState(`https://octospin.com/v/`)

    useEffect(() => {
      setSpinUrl(`https://octospin.com/v/${spinId}`)
    }, [spinId])

    useEffect(() => {
      if (copied == true) {
        const delayCopyReset = setTimeout(() => {
          setCopied(false)
          clearTimeout(delayCopyReset)
        }, 3000)
      }
      return
    }, [copied])

    const handleUrlCopy = useCallback(
    () => {
      try {
        console.log('trying', spinUrl)
        navigator.clipboard.writeText(spinUrl)
        setCopied(true)
        return
      }
      catch {
        console.log('fail')
        return
      }
    }, [spinUrl])
    
  
    return (
      <Dialog 
        fullWidth={true}
        maxWidth={'sm'}
        PaperProps={{
          sx: {
            margin: '0px'
          }
        }}
        onClose={handleCloseShareDialog} 
        open={open}
        PaperProps={{ sx: { 
          width: "100%", 
          height: "80%", 
          margin: '0px' 
        } }}
        >
     
          
       <DialogTitle>
         <div
         style={{
           display: 'flex',
           flexDirection: 'row',
           justifyContent: 'space-between',
           alignItems: 'center'
         }}
         >
           <div>
           Share
           </div>
           <Button onClick={handleCloseShareDialog}>Close</Button>

         </div>
           


           
       </DialogTitle>
        <DialogContent
        sx={{
          padding: '8px'
        }}
        >
          
         
      <div>

      <div
      style={{
        width: 'fit-content',
        display: 'flex',
        flexDirection: 'row',
        gap: '8px',
        alignItems: 'center',
        justifyContent: 'center'
      }}
      >
                
        <TextField  
        multiline
        size="small"
        style={{width: '260px', margin: '8px'}} 
        className="denseTextFieldWithoutInteraction" 
        type="url" 
        id="outlined-basic" 
        label="Spin Url" 
        variant="outlined" 
        value={spinUrl}
        />

<Button
        onClick={handleUrlCopy}
        size="small"
        title="Click to copy URL"
        size="small"
        sx={{
          lineHeight: '2em !important',
          height: '2em !important',
          pointerEvents: copied ? "none" : "unset"
        }}
        variant={copied ? "outlined" : "contained"}
        >
          <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: copied ? '3px': '2px',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '8ch',
          }}
          >
        {     copied ? 
          <Check
          fontSize="12px"
          />
        :
        <ContentCopy
        fontSize="12px"
        />
        }
        <div
        style={{
          // textAlign: 'center',
          // width: '6ch'
        }}
        >
        {copied ? 
          'Copied'
        :
        'Copy'
        }
        </div>
          </div>
          
        
        
          
      </Button>
       
      </div>

      

            {/* <EmbedShare spinId={spinId}/> */}

    </div>

        </DialogContent>
        {/* <DialogActions>
        </DialogActions> */}
      </Dialog>
    );
  }

export default ShareDialog;