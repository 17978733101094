import React, { useEffect } from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FileUpload from "../upload/FileUpload";


export default function ReorderImages(props) {
    const {prepareImages ,changeIndex, loggedIn} = props
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

useEffect(
    () => {
      console.log('mmm')
        prepareImages()
    }, []
)
 
  return (
    <React.Fragment>
      <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
      }}
      >
      <Button variant="contained" size="medium" onClick={handleClickOpen}>
        Update Images
      </Button>
      </div>
      
      <Dialog
        // fullWidth={true}
        maxWidth='750px'
        sx={{
          minHeight: '200px'
        }}
        open={open}
        onClose={handleClose}
      >
        
        <DialogActions
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap'
        }}
        
        >
          <DialogTitle
        sx={{
          padding: '0px 12px'
        }}
        >Reorder, Add or Remove Images</DialogTitle>
          <Button size="small" onClick={handleClose}>Close</Button>
        </DialogActions>
        <DialogContent
        sx={{
          minHeight: '200px'
        }}
        >

          {open && 
                    <div
                    className="fileUploadWrapper"
                    >

                    <FileUpload loggedIn={loggedIn} changeIndex={changeIndex} mode="edit" />

                    </div>
                    }
        </DialogContent>
        
      </Dialog>
    </React.Fragment>
  );
}
