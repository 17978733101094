import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from "@auth0/auth0-react";

import {
  BrowserRouter as Router
  
} from "react-router-dom";

ReactDOM.render(
  <Auth0Provider
    domain="spinning.auth0.com"
    clientId="vIWoH7xQKUeJauqcfj6igEth8TQJMcnB"
    useRefreshTokens={true}
    cacheLocation="localstorage"
    // redirectUri={window.location.origin}
    // audience="https://spinning.auth0.com/api/v2/"
    audience="https://dev-backend.prosp.in/"
    redirectUri={window.location.origin}
    // screen_hint="signup"
  >
    <React.StrictMode>
      <Router>
        <App />

        
      </Router>
    </React.StrictMode>
  </Auth0Provider>,

  document.getElementById('root')
);

reportWebVitals();
