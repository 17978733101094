import React from "react";

import {FormControlLabel,  IconButton} from '@mui/material';
import { Link } from "react-router-dom";

import { Visibility, Edit, Share } from '@mui/icons-material'
import TranslateCreatedDate from "../../functions/TranslateCreatedDate";

function MySpinsItem(props) {
    const { passed, handlePreview, handleShare } = props

  const createdDateTitle = (millseconds) => {
    let created = new Date(millseconds)
    return `Created: ${created.toLocaleDateString()} at ${created.toLocaleTimeString('en-US')}`;
  }
  return (
   <div className="mySpinsListItem">
           <div className="mySpinsImageWrapper"
           style={{
               cursor: 'pointer'
           }}
            onClick={() => {handlePreview(passed)}}
           
           >
                { passed.spin && passed.spin.images.length > 0 &&
                <img 
                
                src={`${passed.spin.pullZoneBaseUrl}/${passed.spin.images[passed.spin.firstImageLoadLindex].storagePath}${passed.spin.requestTokenQueryThumbnail}`}
              
                className="mySpinsImage"
                draggable={false}

                />
                }
            </div>
        <div className="mySpinsNickname" 
        style={{
            fontStyle: passed.spin.nickname.length > 0 ? 'normal' : 'italic',
            // opacity: passed.spin.nickname.length > 0 ? 1 : .8,
            color: passed.spin.nickname.length > 0 ? 'unset' : '#777'
        }}
        title={passed.spin.nickname.length > 0 ? passed.spin.nickname : '(untitled)'}>

                {passed.spin.nickname.length > 0 ? passed.spin.nickname : '(untitled)'}

            </div>     

            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            
<FormControlLabel
                    className={`publicIconLabeled ${passed.spin.isActive === true ? 'liveIcon' : 'hiddenIcon'}`}


                    control={
                        // <div style={{paddingBottom: '8px'}}>
                        <svg width="12px" height="12px" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="6" cy="6" r="6" fill={passed.spin.isActive === true ? '#00c700' : '#c5c5c5'}/>
                        </svg>
                        // </div>
                    } label={passed.spin.isActive === true ? 'Public' : 'Private'} />


            </div>  

        <div className="mySpinsCreated" title={createdDateTitle(passed.spin.dateCreatedInMilliseconds)}>
        {TranslateCreatedDate(passed.spin.dateCreatedInMilliseconds)}

        </div>
       
                    
        


                <div className="mySpinsActionItems">
                    
                   



                    <FormControlLabel
                    className="iconButtonLabeled"

                    control={
                        <IconButton
                        className="mySpinsActionItemButton"
                        size="small"
                        onClick={() => {handlePreview(passed)}}

                        >
                            <Visibility />
                        </IconButton>
                    } label="Preview" />

                    <FormControlLabel
                    className="iconButtonLabeled"
                    control={

                        <Link
                        to={`/edit/${passed.spin.id}`}
                        >
                        <IconButton
                        className="mySpinsActionItemButton"
                        size="small"

                        >
                            <Edit />
                        </IconButton>
                        </Link>
                        // 
                    } label="Edit" />

                    <FormControlLabel
                    className="iconButtonLabeled"
                    control={
                        <IconButton
                        size="small"
                        className="mySpinsActionItemButton"
                        onClick={() => {handleShare(passed)}}
                        >
                            <Share />
                        </IconButton>
                    } label="Share" />


                </div>

    </div>


  );
}
export default MySpinsItem;