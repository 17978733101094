const HandleLocalStorageToken = (type, spinId, token) => {
    return new Promise ((resolve, reject) => {
            
        // grab the existing token for the spin
        var existing = localStorage.getItem(`${spinId}/${type}`);
        //if there isn't an existing token
        //stick the new token in and return new token
        if (!existing) {
            localStorage.setItem(`${spinId}/${type}`, token);
            resolve(token)
        }
        //if there is an existing token convert it to query params
        let existingTokenParams = new URLSearchParams(existing)
        //grab the time right now
        let rightNow = Math.round(Date.now() / 1000);
        //grab the expiry time
        let existingExpiry = Number(existingTokenParams.get('expires'))    
        //is exp is less than a day away, return the new token
        if (existingExpiry - rightNow <  86400) {
            localStorage.setItem(`${spinId}/${type}`, token);
            resolve(token)
        }
        //otherwise, return the existing token
        resolve(existing)
    })
}

export default HandleLocalStorageToken;