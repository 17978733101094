import React, { useContext, useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import FileUpload from "../components/upload/FileUpload";
import AppContext from "../contexts/AppContext";
import {CircularProgress} from '@mui/material';

import {Backdrop, Button} from '@mui/material'
import ProgressLoad from "../components/loading/ProgressLoad";
import UpdateImagesContext from "../contexts/UpdateImagesContext";

import { Prompt } from 'react-router'

import imageCompression from 'browser-image-compression';
import TranslateProductId from "../functions/TranslateProductId";

const Create = () => {

    const _AppContext = useContext(AppContext)
    const history = useHistory();

    const [nickname, setNickname] = useState('')
    const [contextFiles, setContextFiles] = useState([])
    const [imageUploadInProgress, setImageUploadInProgress] = useState(false)

    const [isUploadingSpin, setIsUploadingSpin] = useState(false)

const [uploadProgressCount, setUploadProgressCount] = useState(0)
const [uploadProgressTotal, setUploadProgressTotal] = useState(100)







const incrementProgressCount = useCallback(
    () => {
        setUploadProgressCount(prev => prev + 1)
    }, [setUploadProgressCount]
    
)



const [pageIsDirty, setPageIsDirty] = useState(false)
useEffect(() => {
    if (pageIsDirty === true) {
        window.onbeforeunload = () => true
    } else {
        window.onbeforeunload = undefined
    }
}, [pageIsDirty])

useEffect(() => {
    if (contextFiles.length > 0) {
        setPageIsDirty(true)
    } else {
        setPageIsDirty(false)
    }
}, [contextFiles])


const postImages = useCallback(
     async (spinId, subscriptionLevel) => {
        setUploadProgressTotal(contextFiles.length)

        const accessToken = await _AppContext.getAccessTokenSilently({
            audience: `https://dev-backend.prosp.in/`,
          });



        return Promise.all(contextFiles.map(async (x, index) => {


            var imageFile = x.file
            var initialFileSize = imageFile.size / 1024 / 1024
            //max size for everyone
            var maxFileSize = 20;
          
            // handle image compression on client side to save server compute
            if (initialFileSize > maxFileSize) {
                const options = {
                    maxSizeMB: maxFileSize,
                    // maxWidthOrHeight: 1920,
                    useWebWorker: true
                }
                try {
                    const compressedFile = await imageCompression(imageFile, options);
                    imageFile = compressedFile;
                } catch (error) {
                    // let it upload full size
                }
            }
          

            var formData = new FormData();
            formData.append("passedFile", imageFile);
            // console.log(fileList[a])
            var response;
            await fetch(`${_AppContext.baseBackendUrl}/api/Image/UploadWithBunny?spinId=${spinId}&order=${index}`, {
                method: 'POST',
                headers: {
                'Accept': '*/*',
                // 'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${accessToken}`,
                },
                body: formData
            })
            .then(res => res.json())
            .then(data => {
                response = {
                    id: data.id,
                    order: data.order,
                    storagePath: data.storagePath,
                    mimeType: data.mimeType,
                    live: data.live,
                }
                console.log(data)
                incrementProgressCount()
            })
            return response
        }))

    }, [contextFiles]
)

    const uploadSpin = useCallback(
        async (isLive) => {
            setPageIsDirty(false)
            
            setIsUploadingSpin(true)

            const initialData = {
                //send empty images array first
                images: [],
                userId: "",
                dateCreatedInMilliseconds: Date.now(),
                isActive: isLive
            }

            const postThatSpin = () => {
                return new Promise (async (resolve, reject) => {
                        const accessToken = await _AppContext.getAccessTokenSilently({
                            audience: `https://dev-backend.prosp.in/`,
                        });
        
                        fetch(`${_AppContext.baseBackendUrl}/api/Spin`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: `Bearer ${accessToken}`,
                            },
                            body: JSON.stringify(initialData)
                        })
                        .then(res => res.json())
                        .then(data => {
                            resolve(data);
                        })
                        .catch(error => history.push(`/error?message=${error}`))
                })
            }

           

            const updateThatSpin = (newData) => {
            
                return new Promise (async (resolve, reject) => {
                   
                        const accessToken = await _AppContext.getAccessTokenSilently({
                            audience: `https://dev-backend.prosp.in/`,
                        });
        
                        fetch(`${_AppContext.baseBackendUrl}/api/Spin/Update`, {
                            method: 'PUT',
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: `Bearer ${accessToken}`,
                            },
                        
                            body: JSON.stringify(newData)
                        })
                        .then(res => { 
                            console.log(res.status)
                            
                            resolve()
                        })
                        
                        .catch(error => history.push(`/error?message=${error}`))

                
            })
                        

                    
             
            }
            
                
            
            const initialPostedSpin = await postThatSpin()
            console.log('initialPostedSpin', initialPostedSpin)
            let spinId = initialPostedSpin.id;

            var subscriptionLevel = _AppContext.userInfo_Octospin && _AppContext.userInfo_Octospin.subscriptionProductId ? _AppContext.userInfo_Octospin.subscriptionProductId : '' 
            var translatedSubscriptionLevel = TranslateProductId(subscriptionLevel)
            const uploadedImagesArray = await postImages(spinId, translatedSubscriptionLevel)
            console.log(uploadedImagesArray)

            const updatedData = {...initialPostedSpin, images: uploadedImagesArray}
            await updateThatSpin(updatedData)
         

            setIsUploadingSpin(false)
            history.push(`/edit/${spinId}`)


            return

               

            
            

            
        }
    ,[contextFiles, _AppContext, nickname])


useEffect(() => {
    console.log(imageUploadInProgress)
}, [imageUploadInProgress])


const changeUploadedFiles = useCallback(
    (value) => {
        setContextFiles(value)
    },
    [setContextFiles]
)

const changeImageUploadInProgress = useCallback(
    (value) => {
        setImageUploadInProgress(value)
    },
    [setImageUploadInProgress]
)



const createSpinStateObject = {
    contextFiles: contextFiles,
    changeUploadedFiles,
    imageUploadInProgress: imageUploadInProgress,
    changeImageUploadInProgress,
}


    return (
        <>
        {
            _AppContext.isAuth ?

            <UpdateImagesContext.Provider value={createSpinStateObject}>
            {
                 isUploadingSpin &&
           

                <Backdrop
                sx={{ 
                    color: '#fff', 
                    zIndex: 1000,
                    backgroundColor: 'hsl(0deg 0% 16% / 25%)'
                }}
                open={isUploadingSpin}
                // onClick={handleClose}
                >
                {/* <LoadingSpinnerCenter color="white"/> */}

                <div className="uploadProgressWrapper">


                <div style={{
                        position: 'relative'
                    }}>

                    <CircularProgress size={50} thickness={2.2} />
                    {/* {!isProcessingSpin && */}
                    <div style={{
                        position: 'absolute',
                        top: '50%',
                        fontSize: '1em',
                        fontWeight: '600',
                        transform: 'translateY(-65%)',
                        textAlign: 'center',
                        width: '100%',
                    }}>
                { `${uploadProgressCount/uploadProgressTotal == 0 ? 1 : Math.round(100*(uploadProgressCount/uploadProgressTotal))}%`}
                {/* {`00%`} */}
                        </div>
                    {/* } */}
                    
                </div>


                <ProgressLoad color="black" value={uploadProgressCount/uploadProgressTotal == 0 ? 1 : 100*(uploadProgressCount/uploadProgressTotal)} />
                <div>
                { `Hold tight. We're uploading your images. Please do not refresh or close this page.`}

                </div>
                </div>

                </Backdrop>

                    }

                    {! isUploadingSpin &&
                <div
                style={{
                    padding: '20px'
                }}
                >
                    <FileUpload
                    imageUploadState={imageUploadInProgress}
                    filesLength={contextFiles.length}
                    uploadSpin={uploadSpin}
                    mode="create"/>
                    {/* contextFiles: {JSON.stringify(contextFiles)} */}

                </div>
        }
        <Prompt
            when={pageIsDirty}
            message='Your spin has not been uploaded, are you sure you want to leave?'
            />
                </UpdateImagesContext.Provider>

        :

        <>
        ahh
        </>
        }
        </>
        
    )
};

export default Create;