import React, { useContext, useState, useCallback, useRef } from "react";
import SpinContext from "../contexts/SpinContext";
import { Settings, ZoomIn, ZoomOut, CompareArrows, Check, TryRounded } from '@mui/icons-material';
import { FormControl, InputLabel, Select, Box, Button, Tooltip, NativeSelect, Slider, ListItemIcon, Menu, MenuItem, IconButton, Icon, FormControlLabel, Switch, MenuList, Paper } from '@mui/material'
import CustomSlider from "./settingComponents/CustomSlider";

const SettingsMenu = (props) => {

  const _SpinContext = useContext(SpinContext)
  
  const wrapperMenuRef = useRef(null)
  const reverseDirectionItem = useRef(null)
  const speedEl = useRef(null)
  const qualitySelectEl = useRef(null)
  const qualityMenuItem = useRef(null)
  const lowEl = useRef(null)
  const mediumEl = useRef(null)
  const highEl = useRef(null)
  
 const [accessible, setAccessible] = useState(false)
  const [qualitySelectFocused, setQualitySelectFocused] = useState(false)

  const handleQualitySelectFocus = useCallback(
    () => {
    console.log('focusing', qualitySelectEl.current.focus)
    setAccessible(true)
    qualitySelectEl.current.click()
    // if (_SpinContext.currentSpinData.imageQualityRangeValue > 1) {
    //   highEl.current.focus()
    // } else if (_SpinContext.currentSpinData.imageQualityRangeValue > 0) {
    //   mediumEl.current.focus()

    // } else {
    //   lowEl.current.focus()

    // }
// qualitySelectEl.current.focus()    
}, [_SpinContext.currentSpinData, qualitySelectEl, lowEl, mediumEl, highEl])

const handleQualitySelectKeyDown = useCallback(
  () => {
  console.log('focusing', qualitySelectEl.current)
  qualitySelectEl.current.click()
  if (_SpinContext.currentSpinData.imageQualityRangeValue > 1) {
    highEl.current.focus()
  } else if (_SpinContext.currentSpinData.imageQualityRangeValue > 0) {
    mediumEl.current.focus()

  } else {
    lowEl.current.focus()

  }
// qualitySelectEl.current.focus()    
}, [_SpinContext.currentSpinData, qualitySelectEl, lowEl, mediumEl, highEl])


    const [ anchorElement, setAnchorElement] = useState(null)
    
    const open = Boolean(anchorElement)

    

    const handleClick = (event) => {
        if (open) {
            setAnchorElement(null);
        } 
        setAnchorElement(event.currentTarget);

        
    };
    const handleClose = () => {
        setAnchorElement(null);
      };

    const handleChange = useCallback( 
        (key, value) => {
            _SpinContext.editSpinData(key, value)
    },[_SpinContext.editSpinData])

    const getSetting = useCallback(
        (key) => {
            if (_SpinContext.currentSpinData === null) {
                return
            }   
            if (_SpinContext.currentSpinData[key] !== null) {
                return _SpinContext.currentSpinData[key]
            }
        }, [_SpinContext.currentSpinData]
    )

    const dragFactorMarks = [
        {
          value: 0,
          label: 'Slow',
        },
        {
          value: 9,
          label: 'Fast',
        },
      ];

      const [accessibilityOn, setAccessibilityOn] = useState(false)

      const handleAccessibilityChange = useCallback(
      () => {
        setAccessibilityOn(prev => {
          console.log('go')
          _SpinContext.setAccessibility(!prev)
          return !prev;
        })
      }, [ setAccessibilityOn]
      )

      const [basePrompt, setBasePrompt] = useState('')


      const handleShowArrowsChange = useCallback(
        () => {
         
          if (_SpinContext.currentSpinData.showPlaybackControls === false) {
            handleChange('promptToSpin', 'Use Arrows to Spin')
            setBasePrompt(_SpinContext.currentSpinData.promptToSpin)
          } else if (basePrompt) {
            console.log(basePrompt)
            handleChange('promptToSpin', basePrompt)

          }
          handleChange('showPlaybackControls', !_SpinContext.currentSpinData.showPlaybackControls)

          
        }, [_SpinContext.currentSpinData, setAccessibilityOn, basePrompt]
        )

        const handleShowZoomButtomChange = useCallback(
          () => {
           
            handleChange('showZoomButton', !_SpinContext.currentSpinData.showZoomButton)
  
            
          }, [_SpinContext.currentSpinData, setAccessibilityOn, basePrompt]
          )

        

        const [showReverseCheck, setShowReverseCheck] = useState(false)

    return (
        <div>
        <Tooltip title="Change Settings" placement="top">
      <IconButton

        id="basic-button"
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        style={{ color: getSetting('basicButtonBackgroundColor')}}
      >
          {/* this is the icon name */}
        <Settings/>
      </IconButton>
      </Tooltip>
      { _SpinContext.currentSpinData &&

      <Menu
        tabIndex="-1"
      
        id="basic-menu"
        anchorEl={anchorElement}
        open={open}
        onClose={handleClose}
        // MenuListProps={{
        //   'aria-labelledby': 'basic-button',
        // }}
        PaperProps={{sx: {
          backgroundColor: getSetting('settingsMenuBackgroundColor'),
          padding: '8px',
        }
      }}
        // style={{ transform: "translateY(-30px)"}}
        anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}

          
    
      >
       

        
          { props.mode === 'edit' &&
            <Tooltip 
            
            disableInteractive={true} title="Controls unavailable during edit" placement="top">
              <div
              style={{
                position: "absolute",
                top: 0,
                height: "100%",
                width: '100%'
              }}
              >

              </div>
          </Tooltip>
          }
          
          <div 
          ref={wrapperMenuRef}
          tabIndex="-1"
          style={{ backgroundColor: getSetting('settingsMenuBackgroundColor')}}     
          onFocus={(e) => {
            if (e.target == wrapperMenuRef.current) {
            reverseDirectionItem.current.focus()
              console.log('match')
            }
            console.log('focusss')
          }}     
          >
          {/* <> */}
    
        <MenuItem 
        ref={reverseDirectionItem}
        tabIndex={0}
        onClick={() => {
            handleChange('dragDirectionNegative', !_SpinContext.currentSpinData.dragDirectionNegative)
            setShowReverseCheck(true)
            setTimeout( () => {
              setShowReverseCheck(false)

            }, 2000)
            // handleClose()
          }}
          className={props.mode === 'edit' ? 'disabledOnEdit customSettingsFocus' : 'customSettingsFocus'}
          style={{padding: '12px 8px', color: showReverseCheck === true ? '#009f00' : 'inherit'}}
          
        >
            <ListItemIcon>
                {showReverseCheck === false ? 
                <CompareArrows  />
                :
                <Check style={{color: '#009f00'}} />
                }

            </ListItemIcon>
            Reverse Direction
        </MenuItem>
        { _SpinContext.currentSpinData.dragFactorRangeValue !== null &&

        <div
        tabIndex={1}
        onKeyDown={
          (e) => {
            console.log(e)
            if (e.key === "ArrowLeft") {

              handleChange('dragFactorRangeValue', _SpinContext.currentSpinData.dragFactorRangeValue >= 1 ?  _SpinContext.currentSpinData.dragFactorRangeValue - 1 : 0)
            } 
            if (e.key === "ArrowRight") {
              handleChange('dragFactorRangeValue', _SpinContext.currentSpinData.dragFactorRangeValue <= 8 ?  _SpinContext.currentSpinData.dragFactorRangeValue + 1 : 9)

            }
          }
        }
        style={{
          padding: '0px 8px'
        }}
        className="sliderSettingsWrapper"
        >

        <CustomSlider
              aria-label="Spin Speed Input Range"
              value={_SpinContext.currentSpinData.dragFactorRangeValue}
              handleChange={(value) => handleChange('dragFactorRangeValue', value)}
              tabIndex={1}

              step={1}
              marks={dragFactorMarks}
              min={0}
              max={9}
              // customWidth='calc(100% - 16px)'
              // customPadding='0px 8px'
              inputClassName="settingsSlider"
            className={props.mode === 'edit' ? 'disabledOnEdit' : ''}
            />
        </div>
        

        
        }
      {_SpinContext.selectedQuality && _SpinContext.currentSpinData &&

//         <FormControl fullWidth variant="outlined" 
          
// >

        
        <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
        className="settingsMenuItem"
        className={props.mode === 'edit' ? 'disabledOnEdit' : ''}
ref={qualityMenuItem}
tabIndex={1}
onClick={() => {
  handleQualitySelectFocus('keyDownEvent')
  qualitySelectEl.current.click()


}}
onKeyDown={(e) => {

  if (e.code.toLowerCase() == 'space' || e.code.toLowerCase() == 'enter') {
    handleQualitySelectFocus('keyDownEvent')

  } 
  else {
    qualityMenuItem.current.focus()
  }

}}
        >
         

        <NativeSelect
    value={_SpinContext.selectedQuality ? _SpinContext.selectedQuality : null}
    ref={qualitySelectEl}
        style={{
          width: '100%',
        }}
    onChange={(e) => {
     
      _SpinContext.changeLoading(true);

      _SpinContext.setSelectedQuality(e.target.value)
    qualityMenuItem.current.focus()

    }}
    // inputProps={{
    //   name: 'age',
    //   id: 'uncontrolled-native',
    // }}
    // size="small"
    variant="outlined"
    inputProps={{
      name: 'quality',
      id: 'uncontrolled-native',
    }}
    className="customNativeSelect"

  >
    { _SpinContext.currentSpinData.imageQualityRangeValue > 1 && <option  value='high'>High Quality</option>}
          {_SpinContext.currentSpinData.imageQualityRangeValue > 0 && <option  value='medium'>Medium Quality</option>} 
         
          <option  value='low'>Low Quality</option>
  </NativeSelect>
  </div>
    
     




      }

      <MenuItem 
        tabIndex={0}
        onClick={handleShowArrowsChange}
          className={props.mode === 'edit' ? 'disabledOnEdit customSettingsFocus' : 'customSettingsFocus'}
          style={{
            padding: '4px 0px'
          }}
          
          
        >
          <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            gap: '12px',
            justifyContent: 'flex-start',
            alignItems: 'center'
          }}>
            <div>
            <Switch 
                    checked={_SpinContext.currentSpinData ? _SpinContext.currentSpinData.showPlaybackControls : false} 
                    name="Show Arrows"
                    />
            </div>
            <div>
            Show Arrows

            </div>
          </div>
            
        </MenuItem>
        <MenuItem 
        tabIndex={0}
        onClick={handleShowZoomButtomChange}
          className={props.mode === 'edit' ? 'disabledOnEdit customSettingsFocus' : 'customSettingsFocus'}
          style={{
            padding: '4px 0px'
          }}
          
          
        >
          <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            gap: '12px',
            justifyContent: 'flex-start',
            alignItems: 'center'
          }}>
            <div>
            <Switch 
                    checked={_SpinContext.currentSpinData ? _SpinContext.currentSpinData.showZoomButton : false} 
                    name="Show Arrows"
                    />
            </div>
            <div>
            Show Zoom Button

            </div>
          </div>
            
        </MenuItem>

            </div>
            {/* </> */}

     

      </Menu>
      }
      </div>

        
    
    
    );
};

export default SettingsMenu;