import React, { useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import Interact from '../../components/Interact'
import useWindowDimensions from "../../custom-hooks/useWindowDimensions"; 
import SpinContext from "../../contexts/SpinContext";

const ViewSpin = (props) => {
    
    const [currentSpinData, setCurrentSpinData] = useState(null)
    const [initialSpinData, setInitialSpinData] = useState(null)
    const [selectedImage, setSelectedImage] = useState(null)

    const[loading, setLoading] = useState(true)

    const [imageUriArray, setImageUriArray] = useState([])
    const [lowImageUriArray, setLowImageUriArray] = useState(null)
    const [mediumImageUriArray, setMediumImageUriArray] = useState(null)
    const [showZoom, setShowZoom] = useState(false)

    const changeCurrentSpinData = useCallback(
        (value) => {
            setCurrentSpinData(value)
        },
        [setCurrentSpinData]
    )

    const changeSelectedImage = useCallback(
        (value) => {
            setSelectedImage(value)
        },
        [setSelectedImage]
    )

    const changeImageUriArray = useCallback(
        (value) => {
            setImageUriArray(value)
        },
        [setImageUriArray]
    )
    const changeLowImageUriArray = useCallback(
        (value) => {
            setLowImageUriArray(value)
        },
        [setLowImageUriArray]
    )
    const changeMediumImageUriArray = useCallback(
        (value) => {
            setMediumImageUriArray(value)
        },
        [setMediumImageUriArray]
    )

    const changeShowZoom = useCallback(
        (value) => {
            setShowZoom(value)
        },
        [setShowZoom]
    )

    const changeLoading = useCallback(
        (value) => {
            setLoading(value)
        },
        [setLoading]
    )



    

    const editSpinData = useCallback(
        (key, value) => {
            if (key == null || value == null) {
                return
            }
            setCurrentSpinData( previousState => 
                ({
                    ...previousState,
                    [key]: value
                })
            )
        },
        [setCurrentSpinData]
    )

    const [promptIsVisible, setPromptIsVisible] = useState(true)
    const setAccessibility = (value) => {
        if (value === true) {
            editSpinData('showPlaybackControls', true)
        } else if (value === false) {
            editSpinData('showPlaybackControls', false)

        }
    }

    const [mouseIsDown, setMouseIsDown] = useState(false)
    const [zoomOutAction, setZoomOutAction] = useState(false)
    const [zoomInAction, setZoomInAction] = useState(false)
    const [waitingToLazilyLoad, setWaitingToLazilyLoad] = useState(false)

    const [selectedQuality, setSelectedQuality] = useState('low')
    
    const spinStateObject = {
        mouseIsDown: mouseIsDown,
        setMouseIsDown,
        currentSpinData: currentSpinData,
        changeCurrentSpinData,
        initialSpinData: initialSpinData,
        setInitialSpinData,
        selectedImage: selectedImage,
        changeSelectedImage,
        imageUriArray: imageUriArray,
        changeImageUriArray,
        lowImageUriArray: lowImageUriArray,
        changeLowImageUriArray,
        mediumImageUriArray: mediumImageUriArray,
        changeMediumImageUriArray,
        showZoom: showZoom,
        changeShowZoom,
        loading: loading,
        changeLoading,
        editSpinData,
        promptIsVisible,
        setPromptIsVisible,
        setAccessibility,
        zoomOutAction,
        setZoomOutAction,
        zoomInAction,
        setZoomInAction,
        waitingToLazilyLoad: waitingToLazilyLoad,
        setWaitingToLazilyLoad,
        selectedQuality: selectedQuality,
        setSelectedQuality
    };


    return (
        <SpinContext.Provider value={spinStateObject}>                        
            <Interact dimensions={props.dimensions}  id={props.spinId} mode={'embed'}/>
        </SpinContext.Provider>
        
    )
};

export default ViewSpin;