import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import { Button } from "@mui/material";

import Welcome from "../components/home/Welcome"
import Introduction from "../components/home/Introduction"

const Demo = () => {
    const { isAuthenticated, isLoading } = useAuth0();

    return (
        <div>
        We've photographed a product in 360 for you
        <Button component={Link} size="small" to="/edit/demo" variant="contained" >Edit</Button>

        </div>
       
        
    )
};

export default Demo;