import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import Welcome from "../components/home/Welcome"
import Introduction from "../components/home/Introduction"

const Home = () => {
    const { isAuthenticated, isLoading } = useAuth0();

    return (
        <div>
        

        { isLoading === true &&
            <div>
                Loading...

            </div>
        }

        <div>
            <div 
            style={{
                padding:'10vh 20px 10vh 20px',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                background: 'white'
            }}
            >
                <Welcome />
                

            </div>
        
            <div 
            style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
            padding: '48px 20px 40px 20px',
            // background: 'white'
            }}
            >
                <Introduction />
                

            </div>
        </div>


        {/* { isAuthenticated === false && isLoading === false &&
        <div>
            <div 
            style={{
                padding:'10vh 20px 10vh 20px',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                background: 'white'
            }}
            >
                <Welcome />
                

            </div>
        
            <div 
            style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
            padding: '48px 20px 40px 20px',
            // background: 'white'
            }}
            >
                <Introduction />
                

            </div>
        </div>
        }
        { isAuthenticated === true && isLoading === false &&
            <div>
                Welcome home authed user.

            </div>
        } */}

        </div>
       
        
    )
};

export default Home;