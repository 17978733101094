import React from "react";

const LogoAnimated = (props) => {

    const {dimensions} = props
    return (
        <svg class="logoWrapper" 
        style={{
            height: dimensions.height,
            width: dimensions.width,
            fontSize: '100px'
        }}
        viewBox="0 0 100 80" xmlns="http://www.w3.org/2000/svg">
            <circle class="body" cx="50" cy="25" r="24" fill="9d4edd"/>
            <circle class="armOne" cx="50" cy="67" r="12" fill="#bb73f5"/>
            <circle class="armTwo" cx="23" cy="62" r="11" fill="#ab5fe8"/>
            <circle class="armThree" cx="10" cy="48" r="8" fill="#9d4edd"/>
            <circle class="armFour" cx="25" cy="33" r="6" fill="#8a39cc"/>
            <circle class="armFive" cx="50" cy="28" r="4" fill="#7929ba"/>
            <circle class="armSix" cx="75" cy="33" r="6" fill="#8a39cc"/>
            <circle class="armSeven" cx="90" cy="48" r="8" fill="#9d4edd"/>
            <circle class="armEight" cx="77" cy="62" r="11" fill="#ab5fe8"/>

            <circle class="body" cx="50" cy="25" r="24" fill="#9d4edd"/>


             {/* <ellipse cx="50" cy="55.4" rx="41" ry="23.4" fill="transparent" stroke="blue" stroke-width=".5"/>  */}
             {/* <ellipse cx="50" cy="48" rx="48" ry="20" fill="transparent" stroke="black" stroke-width=".5"/>  */}
        </svg>
    )
}

export default LogoAnimated;