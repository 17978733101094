import React, { useContext, useEffect, useRef, useState } from "react";
import { useSortable } from "@dnd-kit/sortable";
import {CSS} from '@dnd-kit/utilities';
import UpdateImagesContext from "../../contexts/UpdateImagesContext";


export function SortableImage(props) {

    const {imgUrl, id, grabbedElement, handleImageLoaded} = props;

    const _UpdateImagesContext = useContext(UpdateImagesContext)

    const {
        attributes,
        listeners,
        setNodeRef,
        isDragging,
        transform,
        transition,
      } = useSortable({id: props.id});
      
      const style = {
        transform: CSS.Transform.toString(transform),
        transition,
      };

      const handleTouchStart = (e) => {
        e.preventDefault()
      }
    
      const [disableScroll, setDisableScroll] = useState(false)
      


      return (

       


        <div ref={setNodeRef} 
        style={{
            transform: CSS.Transform.toString(transform),
            transition,
            // visibility: isDragging === true ? 'hidden' : 'visible',
            opacity: isDragging === true ? 0 : 1,
            // outline: isDragging === true ? 'lightblue solid 2px' : '',
            // position: 'absolute',
            // top: 0
            width: '100%',
            // height: '100%',
            display: 'flex',
            userSelect: 'none',
            touchAction: disableScroll === true ? 'none' : 'unset'

        }} 
        className="imgWrapperDrag"
        onTouchStart={(e) => {
          console.log(e)
          e.preventDefault()}
        }
        {...attributes} {...listeners}>


        
            <img
            // src={`https://imagedelivery.net/JAnurhEfUBJwmcOJol_vIw/${cloudflareId}/normal`}
            src={imgUrl && imgUrl.length > 0 ? imgUrl : ''}
            // src={imgUrl}
            // src=""
            draggable={false}
            onLoad={handleImageLoaded}
            style={{
              touchAction: disableScroll === true ? 'none' : 'unset'
            }}
            className="draggableImage"
            onTouchStart={(e) => {
              setDisableScroll(false)
              const removeScrolling = setTimeout(
                () => {
                  setDisableScroll(true)
                  const cleanUp = setTimeout(() => {
                    setDisableScroll(false)
                    
                    clearTimeout(removeScrolling)
                    clearTimeout(cleanUp)

                  }, 100)
                }, 200
              )
              console.log(e)
              e.preventDefault()
            }
            }
            onTouchEnd={() => {
              setDisableScroll(false)
            }}
            

            />
          


          
        </div>

      );
}
