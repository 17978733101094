import React, { useState } from "react";


import { 
  
    Button, 
    IconButton,
    
    Menu, 
    
} from '@mui/material';

import { 
  
  ContentCopy,
  InfoOutlined
} from '@mui/icons-material';





const Information = (props) => {
    const {value} = props;
    const [infoAnchorEl, setInfoAnchorEl] = useState(null);
    const infoOpen = Boolean(infoAnchorEl);
    const handleInfoClick = (event) => {
        setInfoAnchorEl(event.currentTarget);
    };
    const handleInfoClose = () => {
        setInfoAnchorEl(null);
    };

return (
    <>
    

<IconButton
                    aria-controls="basic-menu"
                    aria-haspopup="true"
                    aria-expanded={infoOpen ? 'true' : undefined}
                    onClick={handleInfoClick} 
                    style={{
                        width: 'fit-content',
                        height: 'fit-content',
                        color: 'rgba(100, 100, 100, .6)'
                    }}
                    size="small"
                    >
                        <InfoOutlined/>
                    </IconButton>

    <Menu
        id="basic-menu"
        anchorEl={infoAnchorEl}
        open={infoOpen}
        onClose={handleInfoClose}
        
        
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        
      >
        <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '8px 8px 0px 8px',
          gap: '10px',
          justifyContent: 'center',
          alignItems: 'center',
            maxWidth: '400px'
        }}
        >
        <div
        style={{
          textAlign: 'center'

        }}
        >

        {value}
        </div>
       
        <div>
        <Button 
        onClick={handleInfoClose}
        >Close</Button>
        
        </div>
        </div>
      </Menu>
    </>
    
)
    }

export default Information;
