import React, { useCallback, useContext, useEffect, useRef, useState } from "react";


import AppContext from "../../contexts/AppContext";
import EditContext from "../../contexts/EditContext";
import SpinContext from "../../contexts/SpinContext";

import { 
  Dialog, 
  DialogContent, 
  DialogActions, 
  Box, 
  DialogTitle,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  FormGroup,
    FormControlLabel,
    FormHelperText,
    Button, 
    Tooltip, 
    Slider,
    Menu, 
    IconButton, 
    Icon,
    TextField,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    CircularProgress
  
} from '@mui/material';


import { 
  LoadingButton,
  
} from '@mui/lab'

import { 
  ExpandMore,
  Edit,
  AddCircleOutline,
  ContentCopy,
  Close,
  Check
} from '@mui/icons-material';
import { useHistory } from "react-router-dom";
import {decode} from 'html-entities';

import CustomSwitch from "../settingComponents/CustomSwitch";
import ColorPicker from "../settingComponents/ColorPicker"
import CursorSelect from "../settingComponents/CursorSelect";
import DefaultQualitySelect from "../settingComponents/DefaultQualitySelect";
import TranslateProductId from "../../functions/TranslateProductId";
import CustomSlider from "../settingComponents/CustomSlider";

function UpdateCustomSettings(props) {
    const { onClose, open } = props;
    const history = useHistory()
    const _AppContext = useContext(AppContext)
    const _EditContext = useContext(EditContext)
    const _SpinContext = useContext(SpinContext)
    const [currentSettingInEditor, setCurrentSettingInEditor] = useState({})
    const handleCloseCustomSettingsDialog = () => {
      //cleanup stuff on close
      onClose();

    };

    const [savingChanges, setSavingChanges] = useState(false)

    const [showSavedCheck, setShowSavedCheck] = useState(false)
    
    const delayChangeSavingState = useCallback(
    async (value) => {
        return new Promise(resolve => {
            setTimeout(() => {
                setSavingChanges(value)
                setShowSavedCheck(true)
                getTheCustomSettings()
                resolve()
    
            }, 1000)
        })
        
    }, [setSavingChanges, setShowSavedCheck])

    const delayRemoveSavedCheck = useCallback(
        async (value) => {
            return new Promise(resolve => {
                setTimeout(() => {
                    setShowSavedCheck(false)
          setCurrentSettingInEditor({})

                    resolve()
                }, 1500)
            })
            
        }, [setShowSavedCheck])



    const boilerPlate = (authId) => {
      return ({
        id: 0,
        customSetting: {
              name: "",
              userId: authId, //unchangeable
              dragDirectionNegative: false,
              bounceHalfCircle: false,
              enableZoom: true,
              showZoomButton: false,
              dragFactorRangeValue: 5,
              firstImageLoadLindex: 0,
              settingsMenuBackgroundColor: "#eeeeee",
              settingsMenuOutlineColor: "#eeeeee",
              basicButtonBackgroundColor: "#212121",
              basicButtonTextColor: "#ffffff",
              pageBackgroundColor: "#ffffff",
              defaultTextColor: "#212121",
              cursorStyle: "grab",
              lazyLoadPromptBackgroundColor: "#ffffff",
              lazyLoadPromptOpacity: 100,
              lazyLoadPromptShowDropshadow: true,
              lazyLoadPromptTextColor: "#222222",
              lazyLoadSpin: false,
              playSpeedRangeValue: 5,
              promptBackgroundColor: "#ffffff",
              promptOpacity: 100,
              promptShow: true,
              promptShowDropshadow: true,
              promptTextColor: "#222222",
              promptToLoad: "Click to Load Spin",
              promptToSpin: "Click & Drag to spin",
              showOnInspire: false,
              showPlaybackControls: false,
              showSettingsMenu: true
      }
    })
      
      
      
    }

    const promptOpacityMarks = [
      {
        value: 0,
        label: '0%',
      },
      {
        value: 100,
        label: '100%',
      },
    ];

  

  

  const dragFactorMarks = [
      {
        value: 0,
        label: 'Slow',
      },
      {
        value: 9,
        label: 'Fast',
      },
    ];

    const [showSettingSaved, setShowSettingSaved] = useState({
      show: false,
      message: ''
    })


    

    const handleChange = useCallback(
    (key, value) => {
      setCurrentSettingInEditor( previousState => 
        ({
            ...previousState,
            customSetting: {
              ...previousState.customSetting,
              [key]: value
            }
            
           
            
        })
    )
    }, [setCurrentSettingInEditor]
    )


    const [deleteConfirmAnchorEl, setDeleteConfirmAnchorEl] = React.useState(null);
    const deleteConfirmOpen = Boolean(deleteConfirmAnchorEl);
    const handleDeleteConfirmClick = (event) => {
      setDeleteConfirmAnchorEl(event.currentTarget);
    };
    const handleDeleteConfirmClose = () => {
      setDeleteConfirmAnchorEl(null);
    };

    const middlewareHandleChange = useCallback(
      (type, key, value) => {
          if (Object.keys(currentSettingInEditor).length == 0) {
              return
          }

          if (!currentSettingInEditor.customSetting) {
            return
        }

          if (type === 'bool') {
        console.log('blah', key, currentSettingInEditor.customSetting[key])
        handleChange(key, !currentSettingInEditor.customSetting[key])
          }
          if (type === 'passValue') {
              handleChange(key, value)
  
          }
      }, [currentSettingInEditor, handleChange]
      )

      const handleShowOnInspireChange = () => { 
        middlewareHandleChange('bool', 'showOnInspire') 
    }
    const handleDragDirectionChange = () => { 
      middlewareHandleChange('bool', 'dragDirectionNegative') 
  }
  
  const handleDropshadowChange = () => { 
      middlewareHandleChange('bool', 'promptShowDropshadow') 
  }

  const handleLazyLoadDropshadowChange = () => { 
      middlewareHandleChange('bool', 'lazyLoadPromptShowDropshadow') 
  }

  const handlePromptShowChange = (expanded) => { 
  
    middlewareHandleChange('bool', 'promptShow') 
}

const handleBounceChange = () => { 
    middlewareHandleChange('bool', 'bounceHalfCircle') 
}

const handleEnableZoomChange = () => { 
  middlewareHandleChange('bool', 'enableZoom') 
}

const handleShowZoomButtonChange = () => { 
  middlewareHandleChange('bool', 'showZoomButton') 
}
const handleDefaultQualityChange = (value) => { 
  middlewareHandleChange('passValue', 'defaultLoadQuality', value) 
}

const handleShowSettingsMenuChange = () => { 
    middlewareHandleChange('bool', 'showSettingsMenu') 
}


const handleCursorChange = (value) => {
    middlewareHandleChange('passValue', 'cursorStyle', value) 
}

const handleShowPlaybackControlsChange = () => { 
    middlewareHandleChange('bool', 'showPlaybackControls') 
}

const handlePromptTextChange = (e) => {
    middlewareHandleChange('passValue', 'promptToSpin', e.target.value) 
}
const handleLazyLoadPromptTextChange = (e) => {
    middlewareHandleChange('passValue', 'promptToLoad', e.target.value) 
}

const handleLazyLoadSpinChange = (expanded) => { 

  middlewareHandleChange('bool', 'lazyLoadSpin') 
}





const [initialColorState, setInitialColorState] = useState('#333')


const [ anchorEl_settingsMenuBackgroundColor, setAnchorEl_settingsMenuBackgroundColor] = useState(null)
const open_settingsMenuBackgroundColor = Boolean(anchorEl_settingsMenuBackgroundColor)
const handleColorChange_settingsMenuBackgroundColor = useCallback(
  (value, settingName) => {
      handleChange(settingName, value)
  }, [currentSettingInEditor, handleChange]
)
const handleClose_settingsMenuBackgroundColor = () => {
  setAnchorEl_settingsMenuBackgroundColor(null);
};
const handleCancel_settingsMenuBackgroundColor = useCallback((settingName) => {
  setAnchorEl_settingsMenuBackgroundColor(null);
  handleColorChange_settingsMenuBackgroundColor(initialColorState, settingName)
}, [initialColorState])
const handleClick_settingsMenuBackgroundColor = useCallback((event, color) => {
  if (open_settingsMenuBackgroundColor === true) {
    setAnchorEl_settingsMenuBackgroundColor(null);
  }
  setAnchorEl_settingsMenuBackgroundColor(event.currentTarget);
  if (Object.keys(currentSettingInEditor).length > 0) {
    setInitialColorState(color)
  }
}, [setAnchorEl_settingsMenuBackgroundColor, currentSettingInEditor])




const [ anchorEl_basicButtonBackgroundColor, setAnchorEl_basicButtonBackgroundColor] = useState(null)
const open_basicButtonBackgroundColor = Boolean(anchorEl_basicButtonBackgroundColor)
const handleColorChange_basicButtonBackgroundColor = useCallback(
  (value, settingName) => {
      handleChange(settingName, value)
  }, [currentSettingInEditor, handleChange]
)
const handleClose_basicButtonBackgroundColor = () => {
  setAnchorEl_basicButtonBackgroundColor(null);
};
const handleCancel_basicButtonBackgroundColor = useCallback((settingName) => {
  setAnchorEl_basicButtonBackgroundColor(null);
  handleColorChange_basicButtonBackgroundColor(initialColorState, settingName)
}, [initialColorState])
const handleClick_basicButtonBackgroundColor = useCallback((event, color) => {
  if (open_basicButtonBackgroundColor === true) {
    setAnchorEl_basicButtonBackgroundColor(null);
  }
  setAnchorEl_basicButtonBackgroundColor(event.currentTarget);
  if (Object.keys(currentSettingInEditor).length > 0) {
    setInitialColorState(color)
  }
}, [setAnchorEl_basicButtonBackgroundColor, currentSettingInEditor])



const [ anchorEl_pageBackgroundColor, setAnchorEl_pageBackgroundColor] = useState(null)
const open_pageBackgroundColor = Boolean(anchorEl_pageBackgroundColor)
const handleColorChange_pageBackgroundColor = useCallback(
  (value, settingName) => {
      handleChange(settingName, value)
  }, [currentSettingInEditor, handleChange]
)
const handleClose_pageBackgroundColor = () => {
  setAnchorEl_pageBackgroundColor(null);
};
const handleCancel_pageBackgroundColor = useCallback((settingName) => {
  setAnchorEl_pageBackgroundColor(null);
  handleColorChange_pageBackgroundColor(initialColorState, settingName)
}, [initialColorState])
const handleClick_pageBackgroundColor = useCallback((event, color) => {
  if (open_pageBackgroundColor === true) {
    setAnchorEl_pageBackgroundColor(null);
  }
  setAnchorEl_pageBackgroundColor(event.currentTarget);
  if (Object.keys(currentSettingInEditor).length > 0) {
    setInitialColorState(color)
  }
}, [setAnchorEl_pageBackgroundColor, currentSettingInEditor])



const [ anchorEl_lazyLoadPromptBackgroundColor, setAnchorEl_lazyLoadPromptBackgroundColor] = useState(null)
const open_lazyLoadPromptBackgroundColor = Boolean(anchorEl_lazyLoadPromptBackgroundColor)
const handleColorChange_lazyLoadPromptBackgroundColor = useCallback(
  (value, settingName) => {
      handleChange(settingName, value)
  }, [currentSettingInEditor, handleChange]
)
const handleClose_lazyLoadPromptBackgroundColor = () => {
  setAnchorEl_lazyLoadPromptBackgroundColor(null);
};
const handleCancel_lazyLoadPromptBackgroundColor = useCallback((settingName) => {
  setAnchorEl_lazyLoadPromptBackgroundColor(null);
  handleColorChange_lazyLoadPromptBackgroundColor(initialColorState, settingName)
}, [initialColorState])
const handleClick_lazyLoadPromptBackgroundColor = useCallback((event, color) => {
  if (open_lazyLoadPromptBackgroundColor === true) {
    setAnchorEl_lazyLoadPromptBackgroundColor(null);
  }
  setAnchorEl_lazyLoadPromptBackgroundColor(event.currentTarget);
  if (Object.keys(currentSettingInEditor).length > 0) {
    setInitialColorState(color)
  }
}, [setAnchorEl_lazyLoadPromptBackgroundColor, currentSettingInEditor])


const [ anchorEl_lazyLoadPromptTextColor, setAnchorEl_lazyLoadPromptTextColor] = useState(null)
const open_lazyLoadPromptTextColor = Boolean(anchorEl_lazyLoadPromptTextColor)
const handleColorChange_lazyLoadPromptTextColor = useCallback(
  (value, settingName) => {
      handleChange(settingName, value)
  }, [currentSettingInEditor, handleChange]
)
const handleClose_lazyLoadPromptTextColor = () => {
  setAnchorEl_lazyLoadPromptTextColor(null);
};
const handleCancel_lazyLoadPromptTextColor = useCallback((settingName) => {
  setAnchorEl_lazyLoadPromptTextColor(null);
  handleColorChange_lazyLoadPromptTextColor(initialColorState, settingName)
}, [initialColorState])
const handleClick_lazyLoadPromptTextColor = useCallback((event, color) => {
  if (open_lazyLoadPromptTextColor === true) {
    setAnchorEl_lazyLoadPromptTextColor(null);
  }
  setAnchorEl_lazyLoadPromptTextColor(event.currentTarget);
  if (Object.keys(currentSettingInEditor).length > 0) {
    setInitialColorState(color)
  }
}, [setAnchorEl_lazyLoadPromptTextColor, currentSettingInEditor])




const [ anchorEl_promptBackgroundColor, setAnchorEl_promptBackgroundColor] = useState(null)
const open_promptBackgroundColor = Boolean(anchorEl_promptBackgroundColor)
const handleColorChange_promptBackgroundColor = useCallback(
  (value, settingName) => {
      handleChange(settingName, value)
  }, [currentSettingInEditor, handleChange]
)
const handleClose_promptBackgroundColor = () => {
  setAnchorEl_promptBackgroundColor(null);
};
const handleCancel_promptBackgroundColor = useCallback((settingName) => {
  setAnchorEl_promptBackgroundColor(null);
  handleColorChange_promptBackgroundColor(initialColorState, settingName)
}, [initialColorState])
const handleClick_promptBackgroundColor = useCallback((event, color) => {
  if (open_promptBackgroundColor === true) {
    setAnchorEl_promptBackgroundColor(null);
  }
  setAnchorEl_promptBackgroundColor(event.currentTarget);
  if (Object.keys(currentSettingInEditor).length > 0) {
    setInitialColorState(color)
  }
}, [setAnchorEl_promptBackgroundColor, currentSettingInEditor])



const [ anchorEl_promptTextColor, setAnchorEl_promptTextColor] = useState(null)
const open_promptTextColor = Boolean(anchorEl_promptTextColor)
const handleColorChange_promptTextColor = useCallback(
  (value, settingName) => {
      handleChange(settingName, value)
  }, [currentSettingInEditor, handleChange]
)
const handleClose_promptTextColor = () => {
  setAnchorEl_promptTextColor(null);
};
const handleCancel_promptTextColor = useCallback((settingName) => {
  setAnchorEl_promptTextColor(null);
  handleColorChange_promptTextColor(initialColorState, settingName)
}, [initialColorState])
const handleClick_promptTextColor = useCallback((event, color) => {
  if (open_promptTextColor === true) {
    setAnchorEl_promptTextColor(null);
  }
  setAnchorEl_promptTextColor(event.currentTarget);
  if (Object.keys(currentSettingInEditor).length > 0) {
    setInitialColorState(color)
  }
}, [setAnchorEl_promptTextColor, currentSettingInEditor])

const handleSelectChange = (event) => {
  console.log(event.target.value);
  // setCurrentSettingInEditor({})
  setCurrentSettingInEditor(event.target.value)
};




    const getTheCustomSettings = useCallback(
      async () => {
        console.log('go')
  // const dialog = useRef(null)
  const accessToken = await _AppContext.getAccessTokenSilently({
    audience: `https://dev-backend.prosp.in/`,
  });


  fetch(`${_AppContext.baseBackendUrl}/api/User/AllSavedCustomSettings`, {
    headers: {
      Accept: 'text/plain',
      Authorization: `Bearer ${accessToken}`,
      
    }
  })
  .then(res => {
    if (res.status === 200) {
      return res.json()
    }
  })
  .then(data => {
      // setUserInfo_Octospin(data)
      console.log(data)
      console.log(accessToken)
      console.log(_AppContext.userInfo_Auth.sub)
      _EditContext.changeUserCustomSettingsFromDB(data)
  })
  .catch(error => {
    if (error.message.toLowerCase() === 'failed to fetch') {
      history.push(`/error?message=There was an issue connecting to Octospin's infrastructure. Code 500000`)

      } else {
          history.push(`/error?message=${error}`)
      }
    }
    )
      }, [_AppContext]
    )

  useEffect(
    () => {
      console.log('gooo')
      getTheCustomSettings()
    }, [getTheCustomSettings]
  )


  const handleCreateNewSettingFromSpin = useCallback(
    () => {
      console.log(_SpinContext.currentSpinData)
      const currentSpin = _SpinContext.currentSpinData;
      setCurrentSettingInEditor(
        {
          id: 0,
          customSetting: {
            name: "",
            userId: _AppContext.userInfo_Auth.sub, //unchangeable
            dragDirectionNegative: currentSpin.dragDirectionNegative,
            bounceHalfCircle: currentSpin.bounceHalfCircle,
            enableZoom: currentSpin.enableZoom,
            showZoomButton: currentSpin.showZoomButton,
            dragFactorRangeValue: currentSpin.dragFactorRangeValue,
            firstImageLoadLindex: currentSpin.firstImageLoadLindex,
            settingsMenuBackgroundColor: currentSpin.settingsMenuBackgroundColor,
            settingsMenuOutlineColor: currentSpin.settingsMenuOutlineColor,
            basicButtonBackgroundColor: currentSpin.basicButtonBackgroundColor,
            basicButtonTextColor: currentSpin.basicButtonTextColor,
            pageBackgroundColor: currentSpin.pageBackgroundColor,
            defaultTextColor: currentSpin.defaultTextColor,
            cursorStyle: currentSpin.cursorStyle,
            lazyLoadPromptBackgroundColor: currentSpin.lazyLoadPromptBackgroundColor,
            lazyLoadPromptOpacity: currentSpin.lazyLoadPromptOpacity,
            lazyLoadPromptShowDropshadow: currentSpin.lazyLoadPromptShowDropshadow,
            lazyLoadPromptTextColor: currentSpin.lazyLoadPromptTextColor,
            lazyLoadSpin: currentSpin.lazyLoadSpin,
            playSpeedRangeValue: currentSpin.playSpeedRangeValue,
            promptBackgroundColor: currentSpin.promptBackgroundColor,
            promptOpacity: currentSpin.promptOpacity,
            promptShow: currentSpin.promptShow,
            promptShowDropshadow: currentSpin.promptShowDropshadow,
            promptTextColor: currentSpin.promptTextColor,
            promptToLoad: currentSpin.promptToLoad,
            promptToSpin: currentSpin.promptToSpin,
            showOnInspire: currentSpin.showOnInspire,
            showPlaybackControls: currentSpin.showPlaybackControls,
            showSettingsMenu: currentSpin.showSettingsMenu
          }
      }
      )
    }, [_SpinContext.currentSpinData]
  )

  const handleCreateNewSetting = useCallback(
    () => {
      console.log(boilerPlate(_AppContext.userInfo_Auth.sub))
      setCurrentSettingInEditor(boilerPlate(_AppContext.userInfo_Auth.sub))
    }, [setCurrentSettingInEditor, boilerPlate, _AppContext.userInfo_Auth]
  )

  const [deletingSetting, setDeletingSetting] = useState(false)

  const delaySettingDelete = async () => {
    return new Promise(resolve => {
      const delayTimeout = setTimeout(() => {
        resolve()
        clearTimeout(delayTimeout)
      }, 2000)
    })
  }

  const handleDeleteSetting = useCallback(
    async () => {
      setDeletingSetting(true)

      if (currentSettingInEditor.id && currentSettingInEditor.id !== 0) {

        console.log('deleting this one')
        const accessToken = await _AppContext.getAccessTokenSilently({
          audience: `https://dev-backend.prosp.in/`,
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Authorization', `Bearer ${accessToken}`);
  
        await fetch(`${_AppContext.baseBackendUrl}/api/User/RemoveSavedSetting?id=${currentSettingInEditor.id}`, {
          method: 'DELETE',
          headers: headers
          
      }).then(async res => {
        getTheCustomSettings()


      }
      )
      }

      

      if (_SpinContext.currentSpinData.savedSettingId == currentSettingInEditor.id) {

        _SpinContext.editSpinData('savedSettingId', 0)
        _EditContext.changeSelectedCustomSetting([])
      }

      await delaySettingDelete()
        setDeletingSetting(false)
        handleDeleteConfirmClose()

      console.log(currentSettingInEditor)
      setCurrentSettingInEditor({})

    }, [currentSettingInEditor, _AppContext, _SpinContext]
  )

  const handleCustomSettingNameChange = (e) => {
    handleChange('name', e.target.value)
    // console.log(e.target.value)
  }

  const shortenTitle = (title) => {
    if (title.length > 15) {
      return `${title.substring(0,12)}...`
    } else {
      return title

    }
  }
  
  const postNewCustomSetting = useCallback(
    async () => {
      return new Promise(async resolve => {
        const accessToken = await _AppContext.getAccessTokenSilently({
          audience: `https://dev-backend.prosp.in/`,
        });
        const nameForLater = currentSettingInEditor.customSetting.name;
        const headers = new Headers();
        const content = JSON.stringify(currentSettingInEditor.customSetting)
        headers.append('Content-Type', 'application/json');
        headers.append('Content-Length', content.length.toString());
        headers.append('Authorization', `Bearer ${accessToken}`);
        headers.append('Accept', '*/*');
  
        fetch(`${_AppContext.baseBackendUrl}/api/User/NewCustomSetting`, {
          method: 'POST',
          headers: headers,
          body: content
          
      }).then(res => res.json())
      .then(data => 
        {
          resolve()
          // console.log(data)
          // getTheCustomSettings()
          // setCurrentSettingInEditor({})
          // setShowSettingSaved({
          //     show: true,
          //     message: `Setting named ${shortenTitle(nameForLater)} saved.`
          //   })
          // setTimeout(() => {
          //   setShowSettingSaved({
          //     show: false,
          //     message: ''
          //   })
          // }, 4000)
        }
        
      )
      })

      
    }, [_AppContext, currentSettingInEditor, getTheCustomSettings, setCurrentSettingInEditor, setShowSettingSaved]
  )

  const putExistingCustomSetting = useCallback(
    async () => {
      return new Promise(async resolve => {
        const accessToken = await _AppContext.getAccessTokenSilently({
          audience: `https://dev-backend.prosp.in/`,
        });
        const nameForLater = currentSettingInEditor.customSetting.name;
  
        const headers = new Headers();
        const content = JSON.stringify(currentSettingInEditor.customSetting)
        headers.append('Content-Type', 'application/json');
        headers.append('Content-Length', content.length.toString());
        headers.append('Authorization', `Bearer ${accessToken}`);
        headers.append('Accept', '*/*');
  
        fetch(`${_AppContext.baseBackendUrl}/api/User/ChangeCustomSetting`, {
          method: 'PUT',
          headers: headers,
          body: content
          
      }).then(res => {
        if (res.status == 200) {
          resolve()
          
        
        }
      })
      })
      
  
    }, [_AppContext, currentSettingInEditor, getTheCustomSettings]
  )

  const handleSaveChanges = useCallback(
    async () => {


      if (Object.keys(currentSettingInEditor).length == 0) {
        return
      }
      setSavingChanges(true)

      console.log(currentSettingInEditor)
      console.log(`${JSON.stringify(currentSettingInEditor.customSetting)}`)
      if (currentSettingInEditor.id === 0) {
        //this is a new custom setting, post it
        await postNewCustomSetting()
        
        
      } else {
        //this is an existing custom setting, put it
        await putExistingCustomSetting()
        console.log(_SpinContext.currentSpinData.savedSettingId, currentSettingInEditor.id)
        if (_SpinContext.currentSpinData.savedSettingId == currentSettingInEditor.id) {
          _SpinContext.editSpinData('savedSettingId', 0)
          _EditContext.changeSelectedCustomSetting([])
        }

      }

      await delayChangeSavingState(false)
      await delayRemoveSavedCheck()
    }, [_EditContext, _SpinContext, currentSettingInEditor, postNewCustomSetting, putExistingCustomSetting]
  )

  const feedbackOnUpdateSpinSettings = `The note this feedback is regarding: Note: Editing an existing saved setting does not affect spins and removes its a association to any spin. 
  This ensures that existing spins do not experience unexpected changes.
  To update spins with these new changes, please reassociate this setting with intended spins.`

  const generateFeedbackUrl = `https://docs.google.com/forms/d/e/1FAIpQLSezgwaEmqy9jnhLJ0vczsepTZQq6-eJB43mFfrQwdZGC4GIEA/viewform?usp=pp_url&entry.1608498305=`


  const accordianSummarySx = {
    padding: '0px 6px 0px 12px'
}

    return (
      <Dialog fullWidth={false}
      
        onClose={handleCloseCustomSettingsDialog} 
        open={open}
        maxWidth="small"
        PaperProps={{ 
          sx: { 
            width: '100%', 
          maxWidth: '600px',
          // pointerEvents: 'none' ,
          // cursor: 'not-allowed'
        } 
        }}
        >
          
       <DialogTitle
       sx={{
         padding: '0px',
         pointerEvents: savingChanges == true || showSavedCheck == true ? 'none' : 'unset',
          userSelect: savingChanges == true || showSavedCheck == true ? 'none' : 'unset',
          opacity: savingChanges == true || showSavedCheck == true ? '.5' : 'unset'
       }}
       
       >
         <div
         style={{
           display: 'flex',
           flexDirection: 'row-reverse',
           flexWrap: 'wrap',
           columnGap: '10px',           
           padding: '4px 10px 0px 10px',

           alignItems: 'center',
           justifyContent: 'space-between'
         }}
         >

<div
      style={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        gap: '10px',
        alignItems: 'center',
        justifyContent: 'flex-end',
        flex: 1
      }}
      >
      { showSettingSaved.show &&
<div>
{showSettingSaved.message}
</div>

}
<Button 
          onClick={() => {
            if (Object.keys(currentSettingInEditor).length > 0)
            {
              setCurrentSettingInEditor({})

            } 
            else {
              handleCloseCustomSettingsDialog()

            }
            
          
          }}
          size="small"
          disableRipple={true}
          className="basicFocus"
          >
            {Object.keys(currentSettingInEditor).length > 0 ? 
            'Cancel'
            :'Close'}
            </Button>
{Object.keys(currentSettingInEditor).length > 0 && currentSettingInEditor.customSetting &&

<Tooltip 
          title={ currentSettingInEditor.customSetting.name != undefined && currentSettingInEditor.customSetting.name.length <= 0 ? "Give it a name before saving" : savingChanges == true ? "Saving" : showSavedCheck == true ? "Saved" : "Click to save custom setting"}
          placement="top"
          ><div className="buttonWrapperEnableTooltipOnDisable">
<LoadingButton 
                loading={savingChanges || showSavedCheck ? true : false} 
                disabled={showSavedCheck == true 
                  || (
                    currentSettingInEditor.customSetting.name != undefined 
                    && currentSettingInEditor.customSetting.name.length <= 0
                  ) ? true : false}
                onClick={handleSaveChanges}
          size="small"

                style={{
                    background: savingChanges || showSavedCheck ? '#fff' : '#28a745',
                    color: savingChanges || showSavedCheck ? 'transparent' : '#fff',
                    border: '1px solid #28a745',
                    transition: 'background 500ms, color 500ms'
                  }}
                  className={
                    currentSettingInEditor.customSetting.name != undefined 
                    && currentSettingInEditor.customSetting.name.length <= 0 ? "customDisabled basicFocus": "basicFocus"}
                loadingIndicator={ showSavedCheck == false ?
                   
                    <CircularProgress sx={{color: showSavedCheck == false ? '#28a745' : 'transparent', transition: 'color 500ms'}} size={16} />
                    :
                    <Check sx={{color: showSavedCheck == false ? 'transparent' : '#28a745', transition: 'color 500ms'}} size={16}/>
                  
                    
                }               
                variant="contained">
                Save
                </LoadingButton>


          
          {/* <Button 
          variant="contained"
          
          // disabled={currentSettingInEditor.customSetting.name != undefined && currentSettingInEditor.customSetting.name.length <= 0} 
          onClick={handleSaveChanges}
          size="small"
          >Save</Button>    */}
          </div> 
          </Tooltip>
}
          

          
      </div>

         <div
         style={{
           display: 'flex',
           flexDirection: 'row',
           flexWrap: 'wrap',
           gap: '10px',
           alignItems: 'center',
           justifyContent: 'left',
           flex: 1,
         }}
         >
           
         <div
         style={{
          width: '100%',
          maxWidth: '200px'
         }}
         >
         <FormControl
         sx={{
           width: '100%',
          //  maxWidth: '200px'
         }}

         >
        {/* <InputLabel
        className="savedSettingsDropdownLabel"
        >Edit existing</InputLabel> */}
        <Select
          // value={currentSettingInEditor}
          value="edit existing"
          size="small"
          // label="Edit existing"
          onChange={handleSelectChange}
          sx={{
            width: '100%',
            // minWidth: '100px',
            maxWidth: '200px',
            textOverflow: 'ellipsis'
          }}
          // placeholder="Edit existing"
        >
         <MenuItem 
            onClick={handleCreateNewSetting}
            sx={{
              display: 'none',
             
            }}
            disabled={true}
            value="edit existing"
            >
              
              Edit Saved Setting
              
              </MenuItem>

          { _EditContext.userCustomSettingsFromDB && _EditContext.userCustomSettingsFromDB.length > 0 ?
            _EditContext.userCustomSettingsFromDB.map(x => 
              <MenuItem value={x}>{x.customSetting.name}</MenuItem>
              )
          
            
            :
            <MenuItem 
            onClick={handleCreateNewSetting}
            
            disabled={Object.keys(currentSettingInEditor).length > 0 ? true : false}
            value={boilerPlate(_AppContext.userInfo_Auth.sub)}
            >
              
              {Object.keys(currentSettingInEditor).length > 0 ? 'None found.' : 'None found. Create new?' }
              
              </MenuItem>

          }
        </Select>
      </FormControl>
      </div>
      
      <div
      style={{
        transform: 'scale(.8)'
      }}
      >

      <FormControlLabel
      className="iconButtonLabeled"
      title="Create new saved settings"
      
      control={
        <IconButton
        onClick={handleCreateNewSetting}
        className="mySpinsActionItemButton basicFocus"
        size="small"
        disableRipple={true}
      >
          {/* this is the icon name */}
        <AddCircleOutline/>
      </IconButton>
      } label="New" />
      </div>
      </div>
      
      </div>

       </DialogTitle>
        <DialogContent
        sx={{
          pointerEvents: savingChanges == true || showSavedCheck == true ? 'none' : 'unset',
          userSelect: savingChanges == true || showSavedCheck == true ? 'none' : 'unset',
          opacity: savingChanges == true || showSavedCheck == true ? '.5' : 'unset'
        }}
        >
          
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              m: 'auto',
              width: '100%',
              height: '100%'
            }}
          >


{Object.keys(currentSettingInEditor).length > 0 ?
<div>



<div className="changeSettingsSectionWrapperInner">
            
          
            {
             currentSettingInEditor.id !== 0 &&
              <div 
              style={{
                padding: '8px',
                border: '1px solid orange',
                borderLeft: '6px solid orange',
                color: '#686868',
                borderRadius: '6px',
                fontSize: '0.9em'
                // borderTopLeftRadius: '2px',
                // borderBottomLeftRadius: '2px',

              }}
              >
              Note: Editing an existing saved setting does not affect spins and removes its a association from any spin. 
              Spins that had this setting will be unchanged.
              This ensures that existing spins do not experience unexpected changes.
              To update spins with these new changes, please reassociate this setting with intended spins. <a target="_blank" href={generateFeedbackUrl + feedbackOnUpdateSpinSettings}>Provide feedback</a>.
              
              </div>
            }
            { currentSettingInEditor.id === 0 &&
            <div>
              <Button size="small" variant="contained" onClick={handleCreateNewSettingFromSpin}>
            <span 
            style={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
        whiteSpace: 'nowrap'
            }}
            >
            Use current spin settings
              </span>
              </Button>  
              </div>
   

}
{currentSettingInEditor.customSetting &&
        
         
        <TextField 
        size="small" 
        required={true} 
        sx={{
          minWidth: '80px',
          maxWidth: '300px',
          width: '100%'
        }}
        value={currentSettingInEditor.customSetting.name} 
        onChange={handleCustomSettingNameChange} 
        id="outlined-basic" 
        label="Name (required)" 
        variant="outlined" />
  

      
      }
                 


            <Accordion defaultExpanded={true} className='editAccordion' >
                        <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header" sx={accordianSummarySx} >
                       Basic Settings
                        </AccordionSummary>

                        <AccordionDetails
                        sx={{
                            padding: '8px 0px 0px'
                        }}
                        >
                 
                
                            <div className="inputWrapper">
                            <CustomSwitch
                            // disabled={currentSettingInEditor.customSetting.isActive === true ? false : true}
                            label="Show on Inspire"
                            tooltip= {currentSettingInEditor.customSetting.showOnInspire ? 'Click to hide from Inspire' : 'Click to show on Inspire'}
                            checked={currentSettingInEditor.customSetting.showOnInspire}
                            handleChange={handleShowOnInspireChange}
                            />
                            </div>
                        
                           

                        <div className="inputWrapper">
                                    <CustomSwitch
                                    label="Reverse Direction"
                                    tooltip="Click to change spin direction"
                                    checked={currentSettingInEditor.customSetting.dragDirectionNegative}
                                    handleChange={handleDragDirectionChange}
                                    />
                                </div>
                                <div className="inputWrapper">
                                    <CustomSwitch
                                    label="Bounce"
                                    tooltip={currentSettingInEditor.customSetting.bounceHalfCircle === true ? 'Click to disable bounce' : 'Click to enable bounce'}
                                    checked={currentSettingInEditor.customSetting.bounceHalfCircle}
                                    handleChange={handleBounceChange}
                                    />
                                </div>
                                <div className="inputWrapper">
                                    <CustomSwitch
                                    label="Enable Zoom"
                                    tooltip={currentSettingInEditor.customSetting.enableZoom === true ? 'Click to disable zoom' : 'Click to enable zoom'}
                                    checked={currentSettingInEditor.customSetting.enableZoom}
                                    handleChange={handleEnableZoomChange}
                                    />
                                </div>
                                { currentSettingInEditor.customSetting.enableZoom === true &&
                                    <div className="inputWrapper">
                                    <CustomSwitch
                                    label="Show Zoom Button"
                                    tooltip={currentSettingInEditor.customSetting.showZoomButton === true ? 'Click to hide zoom button' : 'Click to show zoom button'}
                                    checked={currentSettingInEditor.customSetting.showZoomButton}
                                    handleChange={handleShowZoomButtonChange}
                                    />
                                </div>
                                }
                                <div className="inputWrapper">
                                    <CustomSwitch
                                    label="Show Arrows"
                                    tooltip={currentSettingInEditor.customSetting.showPlaybackControls === true ? 'Click to hide arrows' : 'Click to show arrows'}
                                    checked={currentSettingInEditor.customSetting.showPlaybackControls}
                                    handleChange={handleShowPlaybackControlsChange}
                                    />
                                </div>
                                <div className="inputWrapper">
                                    <CustomSwitch
                                    label="Show Controls Menu"
                                    tooltip={currentSettingInEditor.customSetting.showSettingsMenu === true ? 'Click to hide arrows' : 'Click to show arrows'}
                                    checked={currentSettingInEditor.customSetting.showSettingsMenu}
                                    handleChange={handleShowSettingsMenuChange}
                                    />
                                </div>

                                <div className="inputWrapper">
                                    <CursorSelect 
                                    handleCursorChange={handleCursorChange}
                                    currentValue={currentSettingInEditor.customSetting && currentSettingInEditor.customSetting.cursorStyle ? currentSettingInEditor.customSetting.cursorStyle : '' }
                                    />
                            
                                    </div>

                                    {
                                        _AppContext.userInfo_Octospin && _AppContext.userInfo_Octospin.subscriptionProductId && TranslateProductId(_AppContext.userInfo_Octospin.subscriptionProductId) != "basic" &&
                                        <div className="inputWrapper">
                                        <DefaultQualitySelect 
                                        handleChange={handleDefaultQualityChange}
                                        currentValue={currentSettingInEditor.customSetting 
                                          && currentSettingInEditor.customSetting.defaultLoadQuality 
                                          ? currentSettingInEditor.customSetting.defaultLoadQuality : 'low' }
                                        />
                                
                                        </div>
                                    }

                                
                                
                                <div className="inputWrapper" style={{minWidth: '200px', padding: '8px 16px'}}>
                                
                        <div >
                        <CustomSlider
                            
                         
                            value={currentSettingInEditor.customSetting.dragFactorRangeValue}
                            handleChange={(value) => {
                                if (currentSettingInEditor.customSetting.dragFactorRangeValue !== value) {

                                    handleChange('dragFactorRangeValue', value)
                                }
                            }}
                            step={1}
                            marks={dragFactorMarks}
                            min={0}
                            max={9}
                            
                            />
                            </div>
                            
                       
                                </div>

                        </AccordionDetails>

                    </Accordion>
                

                    <Accordion
                    defaultExpanded={true}
                    className='editAccordion'
                    >
                        <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        sx={accordianSummarySx}
                        
                        >
                        Colors
                        </AccordionSummary>

                        <AccordionDetails
                        sx={{
                            padding: '8px 0px 0px'
                        }}
                        >

<div className="inputWrapper">

<ColorPicker
    title="Buttons"
    handleCancel={handleCancel_basicButtonBackgroundColor}
    settingName="basicButtonBackgroundColor"
    handleClick={handleClick_basicButtonBackgroundColor}
    color={currentSettingInEditor.customSetting.basicButtonBackgroundColor}
    open={open_basicButtonBackgroundColor}
    handleChange={handleColorChange_basicButtonBackgroundColor}
    handleClose={handleClose_basicButtonBackgroundColor}
    anchor={anchorEl_basicButtonBackgroundColor}
    />
</div>
<div className="inputWrapper">
<ColorPicker
    title="Buttons"
    handleCancel={handleCancel_pageBackgroundColor}
    settingName="pageBackgroundColor"
    handleClick={handleClick_pageBackgroundColor}
    color={currentSettingInEditor.customSetting.pageBackgroundColor}
    open={open_pageBackgroundColor}
    handleChange={handleColorChange_pageBackgroundColor}
    handleClose={handleClose_pageBackgroundColor}
    anchor={anchorEl_pageBackgroundColor}
    />
</div>

<div className="inputWrapper">

<ColorPicker
    title="Controls Menu"
    handleCancel={handleCancel_settingsMenuBackgroundColor}
    settingName="settingsMenuBackgroundColor"
    handleClick={handleClick_settingsMenuBackgroundColor}
    color={currentSettingInEditor.customSetting.settingsMenuBackgroundColor}
    open={open_settingsMenuBackgroundColor}
    handleChange={handleColorChange_settingsMenuBackgroundColor}
    handleClose={handleClose_settingsMenuBackgroundColor}
    anchor={anchorEl_settingsMenuBackgroundColor}
    />

</div>

                        </AccordionDetails>

                    </Accordion>

               


                <Accordion
                    defaultExpanded={false}
                    className='editAccordion'
                    expanded={currentSettingInEditor.customSetting.lazyLoadSpin}
                    onChange={(event, expanded) => {
                        console.log(event)
                        console.log(expanded)
                        handleLazyLoadSpinChange(expanded)
                    }}
                    >
                        <AccordionSummary
                        // expandIcon={<ExpandMore />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        sx={accordianSummarySx}
                        // onClick={handleLazyLoadSpinChange}
                        
                        >
                            <div
                            style={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                // height: currentSettingInEditor.customSetting.lazyLoadSpin === true ? '1.5rem' : '1.5rem'
                                height: '1.5rem'


                            }}
                            >
                                <div>
                                Load Spin When Clicked
                                </div>
                               

                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: '4px',
                                    alignItems: 'center',
                                    pointerEvents: 'none',
                                    transform: 'translateX(6px)'

                                }}>
                                {/* {currentSettingInEditor.customSetting.lazyLoadSpin === true ? 'On' : 'Off'}
                                <div
                                    style={{
                                        // border: '2px solid rgba(100, 100, 100, .3)',
                                        // boxShadow: '0px 0px 4px -1px rgba(50, 50, 50, .8)',
                                        height: '.8em',
                                        width: '.8em',
                                        borderRadius: '50%',
                                        background: currentSettingInEditor.customSetting.lazyLoadSpin === true ? '#00c700' : '#c5c5c5'
                                    }}
                                ></div> */}

<CustomSwitch
                                label={currentSettingInEditor.customSetting.lazyLoadSpin === true ? 'On' : 'Off'}
                                tooltip= {
                                    currentSettingInEditor.customSetting.lazyLoadSpin ? 'Load spin on page load' : 'Wait for click on spin to load'}
                                checked={currentSettingInEditor.customSetting.lazyLoadSpin}
                                // handleChange={handleLazyLoadSpinChange}
                                />
                                </div>
                                </div>
                        </AccordionSummary>

                        <AccordionDetails
                        sx={{
                            padding: '8px 0px 0px'
                        }}
                        >
<div className="inputWrapper">
                                
                                </div>
                                {currentSettingInEditor.customSetting  &&
                        <>
                                <div className="inputWrapper">

                          

                                <TextField 
                                value={decode(currentSettingInEditor.customSetting.promptToLoad)} 
                                onChange={handleLazyLoadPromptTextChange} 
                                // onClick = {showLazyLoadPromptPreview}

                                label="Load Prompt Text" 
                                variant="outlined" 
                                size="small"
                                sx={{
                                    width: 'calc(100% - 32px)',
                                    margin: '8px 16px 8px 16px'
                                }}
                                />


                                </div>



                            
                            <div className="inputWrapper">


                            <ColorPicker
                            title="Prompt Background"
                            handleCancel={handleCancel_lazyLoadPromptBackgroundColor}
                            settingName="lazyLoadPromptBackgroundColor"
                            handleClick={handleClick_lazyLoadPromptBackgroundColor}
                            color={currentSettingInEditor.customSetting.lazyLoadPromptBackgroundColor}
                            open={open_lazyLoadPromptBackgroundColor}
                            handleChange={handleColorChange_lazyLoadPromptBackgroundColor}
                            handleClose={handleClose_lazyLoadPromptBackgroundColor}
                            anchor={anchorEl_lazyLoadPromptBackgroundColor}
                            />



                            </div>
                            <div className="inputWrapper">

                         

                            <ColorPicker
                            title="Prompt Text"
                            handleCancel={handleCancel_lazyLoadPromptTextColor}
                            settingName="lazyLoadPromptTextColor"
                            handleClick={handleClick_lazyLoadPromptTextColor}
                            color={currentSettingInEditor.customSetting.lazyLoadPromptTextColor}
                            open={open_lazyLoadPromptTextColor}
                            handleChange={handleColorChange_lazyLoadPromptTextColor}
                            handleClose={handleClose_lazyLoadPromptTextColor}
                            anchor={anchorEl_lazyLoadPromptTextColor}
                            />

                            </div>
                            <div className="inputWrapper">
                            <CustomSwitch
                            label="Dropshadow"
                            tooltip={currentSettingInEditor.customSetting.lazyLoadPromptShowDropshadow === true ? 'Click to hide dropshadow' : 'Click to show dropshadow'}
                            checked={currentSettingInEditor.customSetting.lazyLoadPromptShowDropshadow}
                            handleChange={handleLazyLoadDropshadowChange}
                            />
                            </div>
                            <div className="inputWrapper opacitySlider" style={{minWidth: '200px', padding: '8px 16px'}}>
                                
                            <div>
                        
                            <CustomSlider
                            
                                value={currentSettingInEditor.customSetting.lazyLoadPromptOpacity}
                                handleChange={(value) => {

                                    if (currentSettingInEditor.customSetting.lazyLoadPromptOpacity !== value) {
                                        handleChange('lazyLoadPromptOpacity', value)

                                    }
                                }}
                                step={10}
                                marks={promptOpacityMarks}
                                min={0}
                                max={100}
                                
                                />
                       
                                </div>
                                </div>
                        </>
                        }
                        
                        </AccordionDetails>

                    </Accordion>

                








                        <Accordion
                    defaultExpanded={false}
                    className='editAccordion'
                    expanded={currentSettingInEditor.customSetting.promptShow}
                    onChange={(event, expanded) => {
                        console.log(event)
                        console.log(expanded)
                        handlePromptShowChange(expanded)
                    }}
                    >
                        <AccordionSummary
                        // expandIcon={<ExpandMore />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        sx={accordianSummarySx}
                        // onClick={handleLazyLoadSpinChange}
                        
                        >
                            <div
                            style={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                // height: currentSettingInEditor.customSetting.lazyLoadSpin === true ? '1.5rem' : '1.5rem'
                                height: '1.5rem'


                            }}
                            >
                                <div>
                                Prompt to Spin
                                </div>
                               

                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: '4px',
                                    alignItems: 'center',
                                    pointerEvents: 'none',
                                    transform: 'translateX(6px)'
                                }}>
                              

<CustomSwitch
                                label={currentSettingInEditor.customSetting.promptShow === true ? 'On' : 'Off'}
                                tooltip= {
                                    currentSettingInEditor.customSetting.promptShow ? 'Click to hide overlay prompt' : 'Click to show overlay prompt'}
                                checked={currentSettingInEditor.customSetting.promptShow}
                                // handleChange={handleLazyLoadSpinChange}
                                />
                                </div>
                                </div>
                        </AccordionSummary>










                        <AccordionDetails
                        sx={{
                            padding: '8px 0px 0px'
                        }}
                        >


                        {currentSettingInEditor.customSetting 
                        // && currentSettingInEditor.customSetting.promptShow 
                        &&
                        <>
                                <div className="inputWrapper">

                          

                            <TextField 
                            value={decode(currentSettingInEditor.customSetting.promptToSpin)} 
                            onChange={handlePromptTextChange} 
                            // onClick={showPromptPreview}
                            label="Overlay Prompt Text" 
                            variant="outlined" 
                                size="small"
                                sx={{
                                    width: 'calc(100% - 32px)',
                                    margin: '8px 16px 8px 16px'
                                }}
                            />

                           
                            </div>


                         

                            <div className="inputWrapper">
                            <CustomSwitch
                            label="Dropshadow"
                            tooltip={currentSettingInEditor.customSetting.promptShowDropshadow === true ? 'Click to hide dropshadow' : 'Click to show dropshadow'}
                            checked={currentSettingInEditor.customSetting.promptShowDropshadow}
                            handleChange={handleDropshadowChange}
                            />
                            </div>
                            <div className="inputWrapper">

                            

                            <ColorPicker
                            title="Prompt Background"
                            handleCancel={handleCancel_promptBackgroundColor}
                            settingName="promptBackgroundColor"
                            handleClick={handleClick_promptBackgroundColor}
                            color={currentSettingInEditor.customSetting.promptBackgroundColor}
                            open={open_promptBackgroundColor}
                            handleChange={handleColorChange_promptBackgroundColor}
                            handleClose={handleClose_promptBackgroundColor}
                            anchor={anchorEl_promptBackgroundColor}
                            />
                            </div>
                            <div className="inputWrapper">


                            <ColorPicker
                            title="Prompt Background"
                            handleCancel={handleCancel_promptTextColor}
                            settingName="promptTextColor"
                            handleClick={handleClick_promptTextColor}
                            color={currentSettingInEditor.customSetting.promptTextColor}
                            open={open_promptTextColor}
                            handleChange={handleColorChange_promptTextColor}
                            handleClose={handleClose_promptTextColor}
                            anchor={anchorEl_promptTextColor}
                            />

                            </div>
                            <div className="inputWrapper" style={{minWidth: '200px', padding: '8px 16px'}}>
                                
                            <div style={{margin: '0px 16px'}}>
                        
                            <CustomSlider
                                value={currentSettingInEditor.customSetting.promptOpacity}
                                handleChange={(value) => {
                                    if (currentSettingInEditor.customSetting.promptOpacity !== value) {
                                        handleChange('promptOpacity', value)

                                    }
                                }}
                                step={10}
                                marks={promptOpacityMarks}
                                min={0}
                                max={100}
                                
                                />
                       
                                </div>
                                </div>
                        </>
                        }
                        
                        </AccordionDetails>

                    </Accordion>
<div>
<Button
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={deleteConfirmOpen ? 'true' : undefined}
        onClick={handleDeleteConfirmClick}
        sx={{
          color: 'red'
        }}
>Delete</Button>
</div>
          
<Menu
        id="basic-menu"
        anchorEl={deleteConfirmAnchorEl}
        open={deleteConfirmOpen}
        onClose={handleDeleteConfirmClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        
      >
        <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '10px',
          gap: '10px',
          justifyContent: 'center',
          alignItems: 'center',
            maxWidth: '400px'
        }}
        >
        <div
        style={{
          textAlign: 'center'

        }}
        >

        Are you sure you want to delete this saved settings group? Deleting a saved settings group does not change any of your spins settings
        </div>
       
        <div
        >
        <Button 
        disabled={deletingSetting}
        onClick={handleDeleteConfirmClose}
        >Cancel</Button>
        <Button variant="contained"
        disabled={deletingSetting}
        onClick={() => {
          handleDeleteSetting()
        }}
        sx={{
          backgroundColor: deletingSetting ? 'unset' : 'red !important'
        }}
        >{deletingSetting ? 'Deleting...' : 'Yes'}</Button>
        </div>
        </div>
      </Menu>

                    
                
            </div>











    </div>
:
<>
please select a saved custom setting to edit or create a setting
</>

}



      

          </Box>
        </DialogContent>
        <DialogActions>
        
        </DialogActions>
      </Dialog>
    );
  }

export default UpdateCustomSettings;