import React, { useContext, useState, useCallback, useEffect } from "react";
import ViewSpin from '../components/spin/ViewSpin'
import PreviewDialog from "../components/dialogs/PreviewDialog";
import useWindowDimensions from "../custom-hooks/useWindowDimensions"; 
import {Switch, Button, IconButton, TextField, Modal, Box, Dialog} from '@mui/material';
import {OpenInFull} from '@mui/icons-material'

import { useHistory } from "react-router-dom";

const Inspire = () => {

    const history = useHistory();

    const [idsOfSpinsToShow, setIdsOfSpinsToShow] = useState([]);

    const spinDimensions = {width: 300, height: 300}
    const windowDimensions = useWindowDimensions()

    const [currentSpinForPreviewDialog, setCurrentSpinForPreviewDialog] = useState(null)
    const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
    const handleClickOpenPreviewDialog = () => {
        setOpenPreviewDialog(true);
      };
    const handleClosePreviewDialog = () => {
        setOpenPreviewDialog(false);
        setCurrentSpinForPreviewDialog(0);
    }
    const handlePreviewClick = useCallback(
        (item) => {
            console.log(item)
          setCurrentSpinForPreviewDialog(item)
          handleClickOpenPreviewDialog()
        }, [setCurrentSpinForPreviewDialog, handleClickOpenPreviewDialog]
    )

    useEffect(() => {
        fetch('https://localhost:5001/api/Inspire/AllInspireSpins')
        .then(res => {
            if (res.status === 200) {
                return res.json()
            }

            throw new Error(`There was an issue connecting to Octospin's infrastructure. Code 400002`);


        })
        .then(data => setIdsOfSpinsToShow(data))
        .catch((error) => history.push(`/error?message=${error}`))
    },  [])

    return (
        <div>
            This is Inspire. A curated selection of spins.
            <div className="allSpinsWrapper">
{
    idsOfSpinsToShow.map(x => {

        return (
            <div className="inspireViewItemWrapper">
                <div className="inspireItemAction">
                    
                <IconButton onClick={() => handlePreviewClick(x)}><OpenInFull/></IconButton>
                </div>
                <div className="inspireSpinItemWrapper">
            <ViewSpin spinId={x} dimensions={spinDimensions}/>
                    </div>
            </div>
        )
    }
        
    )
}

        </div>

        { currentSpinForPreviewDialog !== 0 && 

                <PreviewDialog
                dimensions={{ width: windowDimensions.width - 80, height: windowDimensions.height - 80}}
                id={currentSpinForPreviewDialog}
                open={openPreviewDialog}
                onClose={handleClosePreviewDialog}
              />
            }
        </div>
    )
};

export default Inspire;