import { width } from "@mui/system";
import React, { useCallback, useEffect, useRef, useState } from "react";



function CustomSlider(props) {
   var {minLabel, maxLabel, min, max, step, value, marks, handleChange, ariaLabel, className, customPadding, customWidth, inputClassName, wrapperClassName} = props

   const processChange = useCallback(
    (e) => {
      if (e.target.value == value) {
        return
      }
      handleChange(e.target.value)
    }, [value]
   )
   console.log('marks', marks)
    return (
        <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          width: customWidth ? customWidth: '100%',
          padding: customPadding ? customPadding : ''
        }}
        aria-label={ariaLabel ? ariaLabel : 'Input range'}
        className={className ? className : ''}
        >
            <div
            className={wrapperClassName ? `customSliderInputWrapper ${wrapperClassName}` : "customSliderInputWrapper"}
            
            >
              <div
            className="customSliderInputBack"
            ></div>
            
              <input
              
              
              className={inputClassName ? `customSliderInput ${inputClassName}` : "customSliderInput"    }      
              type="range" 
              onChange={processChange} 
              value={value} step={step} min={min} max={max}/>
              
            </div>

            <div 
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              userSelect: 'none',
              position: 'absolute',
              width: '100%',
              bottom: 0,
              pointerEvents: 'none' 
            }}
            >
              <div
              style={{
                fontSize: '.9em',
                color: '#333'
              }}
              >
              {marks[0].label}
              </div>
              <div
              style={{
                fontSize: '.9em',
                color: '#333'
              }}
              >
              {marks[1].label}
                
              </div>
            </div>
            
         
        </div>
    );
  }

export default CustomSlider;