import React, {
  useState,
  useCallback,
  useContext,
  useEffect,
} from "react";

import SpinContext from "../../contexts/SpinContext";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

const ZoomView = (props) => {
  const {
    handleMouseDown,
    handleDrag,
    handleMouseUp,
    handleTouchDown,
    handleTouchDrag,
    handleTouchUp,
  } = props;
  const _SpinContext = useContext(SpinContext);

  const [testAllowZoom, setTestAllowZoom] = useState(false)

  useEffect(() => {
    console.log('_SpinContext.currentSpinData', _SpinContext.currentSpinData)
  }, [_SpinContext.currentSpinData])

  const [doubleListening, setDoubleListening] = useState(false);
  const [ignoreClick, setIgnoreClick] = useState(false);
  const [preventMouseEvent, setPreventMouseEvent] = useState(false)
  const [retryCounter, setRetryCounter] = useState([])

  const middlewareDown = useCallback(
    (e, type) => {
        if (_SpinContext.showZoom === false) {
        if (type === 'touch') {
          handleTouchDown(e);
        } else {
          handleMouseDown(e);
        }
        _SpinContext.setMouseIsDown(true)
      }
    }, [_SpinContext.showZoom]
  )

  const processMouseDown = useCallback(
  (e) => {
    if (preventMouseEvent === true) {
      return
    }
    middlewareDown(e, 'mouse')
  }, [preventMouseEvent]
  )

  const processMouseUp = useCallback(
    (e) => {
      if (preventMouseEvent === true) {
        return
      }
      if (_SpinContext.showZoom === false) {
        handleMouseUp(e);
      }
      if (_SpinContext.showZoom === true) {
        _SpinContext.setMouseIsDown(false)
      }
    }, [_SpinContext.showZoom, preventMouseEvent]
  )


  const processTouchStart = (e) => {
    setPreventMouseEvent(true)
    setTimeout(() => {
    setPreventMouseEvent(false)

    }, 100)
    middlewareDown(e, 'touch')
  }

  const processTouchEnd = useCallback(
    (e) => {
    setPreventMouseEvent(true)
    setTimeout(() => {
      setPreventMouseEvent(false)
  
      }, 100)
      if (_SpinContext.showZoom === false) {
        _SpinContext.setMouseIsDown(false)
        handleTouchUp(e);
      }
    }, [_SpinContext.showZoom]
  )


  const processPan = useCallback(
    (ref, e) => {
      if (_SpinContext.showZoom === false) {
        ref.instance.clearPanning();
        return;
      }
    }, [_SpinContext.showZoom]
  )

  const processPinchAndPinchStart = useCallback(
    () => {
      if (_SpinContext.showZoom === false) {
        return;
      }
    }, [_SpinContext.showZoom]
  )

  const processPinchStop = useCallback(
    (ref, e) => {
      
      if (ref.state.scale <= 1) {
        _SpinContext.changeShowZoom(false)
        _SpinContext.setMouseIsDown(false)
      } else {
          _SpinContext.changeShowZoom(true)
          
        
        _SpinContext.setMouseIsDown(false)
      }
    }, [_SpinContext.showZoom]
  )

  const middleHandleDrag = useCallback(
    (e) => {
      //there used to be a comment here that said this needs to be in return, I dont think it does 
      if (_SpinContext.showZoom != false || _SpinContext.mouseIsDown != true) {
        return
      }
          handleDrag(e);
    }, [handleDrag, _SpinContext.showZoom, _SpinContext.mouseIsDown]
  )

  const middleHandleTouchDrag = useCallback(
    (e) => {
      //there used to be a comment here that said this needs to be in return, I dont think it does 
      if (_SpinContext.showZoom === false) {
        if (_SpinContext.mouseIsDown === true) {
        handleTouchDrag(e)
        }
      }
    }, [handleTouchDrag, _SpinContext.showZoom, _SpinContext.mouseIsDown]
  )

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        objectFit: "contain",
        userSelect: "none",
      }}
      
      onMouseMove={middleHandleDrag}
      onMouseDown={processMouseDown}
      onMouseUp={processMouseUp}
      onTouchMove={middleHandleTouchDrag}
      onTouchStart={processTouchStart}
      onTouchEnd={processTouchEnd}
    >
      { _SpinContext.currentSpinData &&
      <TransformWrapper
        defaultScale={1}
        defaultPositionX={200}
        defaultPositionY={100}
        style={{ height: "100%", width: "100%", userSelect: "none" }}
        

        // disabled={ ignoreClick === true ? true : false}
        disabled={ignoreClick === true || _SpinContext.currentSpinData.enableZoom == false ? true : false}
      
        onZoomStop={(ref, e) => {
          if (ref.state.scale <= 1) {
            _SpinContext.changeShowZoom(false)
          } else {
  
            
                    _SpinContext.changeShowZoom(true)
            
          }
        }}
        doubleClick={{
          disabled: true
          
        }}
        pinch={{
          step: 5,
        }}
        onPanningStart={processPan}
        onPanning={processPan}
        onPanningEnd={processPan}
        onPinchingStart={processPinchAndPinchStart}
        onPinching={processPinchAndPinchStart}
        onPinchingStop={processPinchStop}
      >

{({ zoomIn, zoomOut, resetTransform, ...rest }) => {
 if (_SpinContext.zoomOutAction === true) {
  _SpinContext.setZoomOutAction(false)
  resetTransform(300)
}

if (_SpinContext.zoomInAction === true) {
  _SpinContext.setZoomInAction(false)
  setIgnoreClick(true)
  zoomIn(0.667)
  setTimeout(() => {
  _SpinContext.changeShowZoom(true)
  setIgnoreClick(false)

    }, 200)
}

const getProperUrl = (image, quality) => {
  switch (quality) {
    case 'low':
      return image.imageUrlLow;
    case 'medium':
      return image.imageUrlMediuum;
    case 'high':
      return image.imageUrlHigh;
    default:
      return image.imageUrlLow;
  }
}

return (
  <React.Fragment>
        <TransformComponent
          style={{ height: "100%", width: "100%", userSelect: "none" }}
          
        >
          <div
          
          onClick={(e) => {
        // this has to be in the transform wrapper
            if (ignoreClick === true) {
              return
            }
            if (doubleListening === false) {
                  setDoubleListening(true)
                  setTimeout(() => {
                    setDoubleListening(false)
      
                  }, 400)
                } else if (doubleListening === true) {
                  _SpinContext.setMouseIsDown(false)
                  // setZoomMouseIsDown(false)
                  if (_SpinContext.showZoom === false) {
                    if (_SpinContext.currentSpinData.enableZoom == true) {
                      setIgnoreClick(true)
                      zoomIn(0.667)
                      setTimeout(() => {
                      _SpinContext.changeShowZoom(true)
                      setIgnoreClick(false)
        
                        }, 200)
                    }

                  } else if (_SpinContext.showZoom === true) {
                    setIgnoreClick(true)
                    setTimeout(() => {
                    setIgnoreClick(false)
      
                    }, 200)
                    _SpinContext.changeShowZoom(false)
                    resetTransform(300)
      
                  }
                  setDoubleListening(false)
          }}}
          style={{ height: "100%", width: "100%", userSelect: "none", display: 'flex', justifyContent: 'center', alignItems: 'center',
          cursor: _SpinContext.currentSpinData && _SpinContext.showZoom === false && _SpinContext.currentSpinData.cursorStyle ? _SpinContext.currentSpinData.cursorStyle : _SpinContext.showZoom === true ? 'move' : 'ew-resize',
        }}

          >
          {_SpinContext.imageUriArray[_SpinContext.selectedImage] &&
            _SpinContext.imageUriArray[_SpinContext.selectedImage].id != 0 &&
            _SpinContext.imageUriArray.map((x, index) => (
              <img
                draggable={false}
                key={x.id}
                onError={(e) => {
                 
                 //only retry 10 times

                 setRetryCounter(prev => {
                   const existing = prev.findIndex((x) => x.index == index)
                   if ( existing >= 0) {
                    return prev.map(x => x.index == index ? {index: x.index, try: x.try++}: x)
                   }
                   else {
                    return [...prev, {index: index, try: 1}]

                   }
                 })

                 if (retryCounter.length > 0 && retryCounter[0].try > 9) {
                  return
                 } else {
                  const retry = setTimeout(
                    () => {

                      e.target.src = e.target.src
                      clearTimeout(retry)

                    }, 1000
                  )
                 }

                  
                }}
                onLoad={(e) => {
                  if (index === _SpinContext.imageUriArray.length - 1) {
                    _SpinContext.changeLoading(false);

                  }
                }}

                style={{
                  userSelect: "none",
                  maxHeight: "100%",
                  maxWidth: "100%",
                  width: "100%",
                  objectFit: "contain",
                  display:
                    _SpinContext.selectedImage === index ? "flex" : "none",
                }}
                src={
                  _SpinContext.selectedImage === index && 
                  _SpinContext.showZoom === true && _SpinContext.mouseIsDown !== true && navigator.onLine === true
                    ? x.imageUrlZoom
                    : getProperUrl(x, _SpinContext.selectedQuality)
                }

              />
            ))}
            </div>
        </TransformComponent>
        </React.Fragment>
)}}
      </TransformWrapper>
}
    </div>
  );
};

export default ZoomView;
