const TitleAToZ = (a, b) => {
    var nameA = a.spin.nickname.toUpperCase(); // ignore upper and lowercase
  var nameB = b.spin.nickname.toUpperCase(); // ignore upper and lowercase
  console.log(nameA, nameB)
  if (nameA.length === 0) {
    return 0;

}
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }

  // names must be equal
  return 0;
}

export default TitleAToZ;