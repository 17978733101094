import React, { useCallback, useContext, useEffect, useRef, useState } from "react";

import { 
  
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  IconButton,
  FormControlLabel
} from '@mui/material';
import { 
 
  Edit,
} from '@mui/icons-material';
import UpdateCustomSettings from "./UpdateCustomSettings";

import EditContext from "../../contexts/EditContext";

function UseCustomSettings(props) {
   
  const handleSelectChange = (event) => {
    _EditContext.changeSelectedCustomSetting(event.target.value)
  };



  const _EditContext = useContext(EditContext)

  const [openCustomSettingsDialog, setOpenCustomSettingsDialog] = React.useState(false);

  const handleClickOpenCustomSettingsDialog = () => {
    setOpenCustomSettingsDialog(true);
  };
  const handleCloseCustomSettingsDialog = (value) => {
    setOpenCustomSettingsDialog(false);
  };

  const handlePreviewClick = useCallback(
    (item) => {
      handleClickOpenCustomSettingsDialog()
    }, []
)
  
    return (
     <div>         
          <UpdateCustomSettings
            open={openCustomSettingsDialog}
            onClose={handleCloseCustomSettingsDialog}
          />


 
<div
style={{
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '8px'
}}
>
<div
style={{
  flex: 1
}}
>

<FormControl 
sx={{
  width: '100%',
  // maxWidth: '200px'
}}>
        <InputLabel 
        className="customSettingsSelect"
        
        >Use saved setting</InputLabel>
        <Select
          value={_EditContext.selectedCustomSetting && _EditContext.selectedCustomSetting.customSetting && _EditContext.selectedCustomSetting.customSetting.name ? _EditContext.selectedCustomSetting : ""}
          // value=""
          // defaultValue=""
          size="small"
          label="Use saved setting"
          onChange={handleSelectChange}
          sx={{
            minWidth: '200px',
            textOverflow: 'ellipsis'
          }}
          
        >
          { _EditContext.userCustomSettingsFromDB && _EditContext.userCustomSettingsFromDB.length > 0 ?
         
            _EditContext.userCustomSettingsFromDB.map(x => 
              <MenuItem value={x}>{x.customSetting.name}</MenuItem>
              )
        
            
            :
            <MenuItem 
            onClick={handlePreviewClick}
           

            value={{}}>None found. Create new?</MenuItem>

          }
        </Select>
      </FormControl>
</div>
<div
style={{
  transform: 'scale(.8)'
}}
>
      <FormControlLabel
      className="iconButtonLabeled"
      title="Create new saved settings"
      
      control={
        <IconButton
        onClick={handlePreviewClick}
        className="mySpinsActionItemButton basicFocus"
        size="small"
        disableRipple={true}
      >
          {/* this is the icon name */}
        <Edit/>
      </IconButton>
      } label="Manage" />



         </div>
         </div>
         </div>
    );
  }

export default UseCustomSettings;