import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../contexts/AppContext";
import { useParams } from "react-router";
import { Link, useHistory } from "react-router-dom";

const TutorialView = (props) => {

    const _AppContext = useContext(AppContext);
    let { id } = useParams();
    const history = useHistory();

    const [tutorialHtml, setTutorialHtml] = useState({__html: ''})
    const [quickLinks, setQuickLinks] = useState([])
 

    useEffect(() => {
        // setTutorialHtml()
        fetch(`${_AppContext.baseBackendUrl}/api/Tutorial/${id}`)
        .then(res=> {
            if (res.status === 200) {
                return res.json()
            }
            else {
                throw new Error(`Tutorial ${id} not found. Code 400003`);
            }
        })
        .then(data => {
            

                setTutorialHtml({__html: data.cleanContent})
                setQuickLinks(JSON.parse(data.hashtagQuickLinkTitles))
                console.log(data)
            
        })
        .catch(error => {
            if (error.message.toLowerCase() === 'failed to fetch') {
                history.push(`/error?message=There was an issue connecting to Octospin's infrastructure. Code 500002`)
  
                } else {
                    history.push(`/error?message=${error}`)
                }
        })
    }, [])

    return (
        <div>
            
                <div className="tutorialOverarch">
                This is where you view a single tutorial {id}
                <div className="tutorialNav">
                <Link to="/howto">{`< Back to all tutorials`}</Link>

                <div className="tableOfContents">
                {quickLinks && quickLinks.map(x => {
                    return (
                        <div>
                            <a href={`#${x.replace(/\s/g, '')}`}>{x}</a>
                            </div>
                    ) 
                })
                  }
                </div>
                </div>

                <div className="tutorialContentWrapper">
                    <div className="tutorialContent" dangerouslySetInnerHTML={tutorialHtml}>
                        </div>
                    </div>
                </div>
            
            
        </div>
    )
};

export default TutorialView;