import React, { useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import Interact from '../components/Interact'
import useWindowDimensions from "../custom-hooks/useWindowDimensions"; 
import SpinContext from "../contexts/SpinContext";
import ViewSpin from '../components/spin/ViewSpin'

const Embed = (props) => {
    var { id } = useParams();
    const windowDimensions = useWindowDimensions();
    

    return (

        <ViewSpin  spinId={id} dimensions={windowDimensions}/>
        
    )
};

export default Embed;