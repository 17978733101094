import logo from './logo.svg';
import './App.css';
import { useCallback, useEffect, useState } from 'react';
import {

  Switch,
  Route,
  Link,
  useRouteMatch,
  useHistory,
  useParams,
  useLocation,
  BrowserRouter as Router,
} from "react-router-dom";

import Home from './views/Home';
import MySpins from './views/MySpins';
import Embed from './views/Embed';
import Create from './views/Create'
import Account from './views/Account';
import Edit from './views/Edit';
import Payment from './views/Payment';
import Pricing from './views/Pricing';
import Inspire from './views/Inspire';
import ErrorPage from './views/ErrorPage';
import TutorialHome from './views/tutorials/TutorialHome';
import TutorialView from './views/tutorials/TutorialView';
import Demo from './views/Demo';
import NavBar from './components/NavBar';

import AppContext from './contexts/AppContext';

import { useAuth0 } from "@auth0/auth0-react";



function App() {
  const location = useLocation();
  const history = useHistory();



  const [showNav, setShowNav] = useState(true)

  useEffect( () => {
    if (
      location.pathname.includes('embed')
      || location.pathname.includes('view')
      || location.pathname.includes('v/')
      ) {
      setShowNav(false)
    } else {
      setShowNav(true)

    }
  }, [location])


  // APP STATE 
  const [files, setFiles] = useState([])  
  const [imageUploadInProgress, setImageUploadInProgress] = useState(false)

  useEffect(() => {
console.log('process', process.env)
  }, [process.env])
    
    // const baseBackendUrl = `https://dev-backend.prosp.in`
    // const baseBackendUrl = `https://localhost:5001`
    const baseBackendUrl = process.env.REACT_APP_BACKEND_BASE_URL
  
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const [userInfo_Auth, setUserInfo_Auth] = useState(
      //set defaults to null
      {
        email: null,
        email_verified: null,
        name: null,
        nickname: null,
        picture: null,
        sub: null,
        updated_at: null
      }
    )
  const [isAuth, setIsAuth] = useState(false)
  const [loadingAuth, setLoadingAuth] = useState(true)

  const [userInfo_Octospin, setUserInfo_Octospin] = useState(null)
  const [userId, setUserId] = useState(null)

  const refreshUser_Octospin = useCallback(
    async () => {
      
      if (userInfo_Auth) {
        if (userInfo_Auth.sub) {
          setUserId(userInfo_Auth.sub)
          const accessToken = await getAccessTokenSilently({
            audience: `https://dev-backend.prosp.in/`,
          });

          console.log(accessToken)
              console.log(userInfo_Auth.sub)
  
          fetch(`${baseBackendUrl}/api/User/${userInfo_Auth.sub}`, {
            headers: {
              Accept: 'text/plain',
              Authorization: `Bearer ${accessToken}`,
              
            }
          })
          .then(res => {
            if (res.status === 200) {
              return res.json()
          }

          throw new Error(`There was an issue getting user info. Code 400006`);
          })
          .then(data => {
              setUserInfo_Octospin(data)
              console.log(data)
              console.log(accessToken)
              console.log(userInfo_Auth.sub)
          })
          .catch(error => {
            if (error.message.toLowerCase() === 'failed to fetch') {
              history.push(`/error?message=There was an issue connecting to Octospin's infrastructure. Code 500000`)

              } else {
                  history.push(`/error?message=${error}`)
              }
            }
            )
  
        }
      }
    },
    [userInfo_Auth]
  )


  
  

  // use auth user info to pull user from our db
  useEffect( async () => {
    if (
      location.pathname.includes('embed')
      || location.pathname.includes('view')
      || location.pathname.includes('v/')
      ) {
      return
    } else {
      refreshUser_Octospin()

    }
  }, [userInfo_Auth])


  
  // listen for & set auth user info
  useEffect( () => {
    if (
      location.pathname.includes('embed')
      || location.pathname.includes('view')
      || location.pathname.includes('v/')
      ) {
      return
    } else {
      setUserInfo_Auth(user)

    }
  }, [user])

  // listen for & set auth status
  useEffect( () => {
    setIsAuth(isAuthenticated)
  }, [isAuthenticated])
  
  // listen for & set auth load
  useEffect( () => {
    setLoadingAuth(isLoading)
  }, [isLoading])
  
  const changeFiles = (value) => {
    setFiles(value)
  }
  const changeImageUploadInProgress = (value) => {
    setImageUploadInProgress(value)
  }

 
  const [filesStateCount, setFilesStateCount] = useState(0)


  const changeFilesStateCount = (value) => {
    setFilesStateCount(value)
  }

  const editOctospinUser = useCallback(
    (key, value) => {
        if (key == null || value == null) {
            return
        }
        setUserInfo_Octospin( previousState => 
            ({
                ...previousState,
                [key]: value
            })
        )
    },
    [setUserInfo_Octospin]
)

  const appStateObject = {
    test: 77,
    // baseBackendUrl: `https://dev-backend.prosp.in`,
    // baseBackendUrl: `https://localhost:5001`,
    baseBackendUrl: baseBackendUrl,
    files: files,
    changeFiles,
    imageUploadInProgress: imageUploadInProgress,
    changeImageUploadInProgress,
    filesStateCount: filesStateCount,
    changeFilesStateCount,
    userInfo_Auth: userInfo_Auth,
    userInfo_Octospin: userInfo_Octospin,
    editOctospinUser,
    userId: userId,
    isAuth: isAuth,
    loadingAuth: loadingAuth,
    getAccessTokenSilently,
    refreshUser_Octospin,
   
    

  }



  const Routes = () => (
    <>
      
    </>
  )

  return (
      <AppContext.Provider value={appStateObject}>
        <div className="basicContainer">
          <div 
          style={{
            marginTop: showNav === true ? "40px" : "0px"
          }}
          >
            { showNav && 
              <NavBar />
            }
          </div>      
          <div 
          className= {showNav === true ? "mainContentContainer" : "embed_mainContainer"}
          style={{
            alignContent: 'center',
            justifyContent: 'center'
          }}
          >
            {/* <BrowserRouter> */}
            <Switch>
              {/* ROUTE ORDER MATTERS */}
              <Route path="/myspins">
              <MySpins />
              </Route>
              <Route path="/embed/:id/:type?">
                <Embed />
              </Route>
              <Route path="/v/:id/:type?">
                <Embed />
              </Route>
              <Route path="/payment/:session_id">
                <Payment />
              </Route>
              {/* <PrivateRoute path="/edit/:id/:type?" element={<Edit />}/> */}
              <Route path="/edit/:id/:type?">
                <Edit/>
              </Route>
              <Route path="/create">
                <Create />
              </Route>
           
              <Route path="/account">
                <Account />
              </Route>
              <Route path="/pricing">
                <Pricing />
              </Route>
              <Route path="/inspire">
                <Inspire />
              </Route>
              <Route path="/error">
                <ErrorPage />
              </Route>
              <Route path="/howto">
                <TutorialHome />
              </Route>
              <Route path="/demo">
                <Demo />
              </Route>
              <Route path="/howtoarticle/:id?">
                <TutorialView />
              </Route>
              
              
              
              <Route path="/">
                <Home />
              </Route>
            </Switch>
            {/* </BrowserRouter> */}
          </div>
        </div>
      </AppContext.Provider>

  );
}

export default App;




