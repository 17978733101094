import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {
    CircularProgress, 
    Select, 
    MenuItem, 
    Switch, 
    IconButton, 
    Button, 
    TextField, 
    Modal, 
    Box, 
    Dialog,
    FormControlLabel,
    FormControl,
    InputLabel,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from '@mui/material';

import { ExpandMore } from "@mui/icons-material";

import PreviewDialog from "../components/dialogs/PreviewDialog";
import ShareDialog from "../components/dialogs/ShareDialog";

import AppContext from "../contexts/AppContext";
import { StayCurrentLandscapeOutlined , Refresh, ArrowUpwardRounded } from "@mui/icons-material";
import useWindowDimensions from "../custom-hooks/useWindowDimensions"; 
import NoSpinsFoundLoading from "../components/loading/NoSpinsFoundLoading";
import MySpinsItem from "../components/mySpins/MySpinsItem";
import DateNewest from "../functions/mySpinsSort/DateNewest";
import DateOldest from "../functions/mySpinsSort/DateOldest";
import TitleAToZ from "../functions/mySpinsSort/TitleAToZ";
import TitleZToA from "../functions/mySpinsSort/TitleZToA";


const MySpins = () => {

    const _AppContext = useContext(AppContext)

    const windowDimensions = useWindowDimensions()

    const history = useHistory();
   

    const [spins, setSpins] = useState([])
    const [filteredSpins, setFilteredSpins] = useState([])
    const [searchText, setSearchText] = useState('')


    const [open, setOpen] = useState(true);
    const [currentSpinForShareModal, setCurrentSpinForShareModal] = useState(null)

    const [currentSpinForPreviewDialog, setCurrentSpinForPreviewDialog] = useState(null)
    const [currentSpinForShareDialog, setCurrentSpinForShareDialog] = useState(null)

    const [loadingSpins, setLoadingSpins] = useState(true)

    
  const handleOpen = () => {
      setOpen(true)
  };
  const handleClose = () => {
      setOpen(false);
      setCurrentSpinForShareModal(null)
  };

    useEffect(() => {
        console.log(filteredSpins)
    }, [filteredSpins])


    const [openPreviewDialog, setOpenPreviewDialog] = React.useState(false);
    const [openShareDialog, setOpenShareDialog] = React.useState(false);

  const handleClickOpenPreviewDialog = () => {
    setOpenPreviewDialog(true);
  };

  const handleClickOpenShareDialog = () => {
    setOpenShareDialog(true);
  };

  const handleClosePreviewDialog = (value) => {
    setOpenPreviewDialog(false);
    setCurrentSpinForPreviewDialog(null);
  };
  const handleCloseShareDialog = (value) => {
    setOpenShareDialog(false);
    setCurrentSpinForShareDialog(null);
  };

  const [sortFactor, setSortFactor] = useState('dateNewest')
  const [privacyFactor, setPrivacyFactor] = useState('publicAndPrivate')
  
    const filterSpinList = useCallback(
        () => {
            setLoadingSpins(true)


            let filtered = spins.filter(x => {

                // if (x.spin.id.toString().toLowerCase().includes(searchText.toLowerCase())) {
                //     return true
                // }

                let returnValue = true;

                if (searchText.length > 0) {
                    if (x.spin.nickname.toLowerCase().includes(searchText.toLowerCase())) {
                        returnValue = true;
                    } else {
                        returnValue = false;

                    }
                }
                

                switch (privacyFactor) {
                    // case 'publicAndPrivate':
                    //         returnValue = true;
                        
                    //     break;
                    case 'publicOnly':
                        if (x.spin.isActive === false) {
                            returnValue = false;
                        }
                        break;
                    case 'privateOnly':
                        if (x.spin.isActive === true) {
                            returnValue = false;
                        }
                        break;

                    default:
                        break;

                }

                return returnValue;


            })

            let sorted;
            
            switch(sortFactor) {
                
                
                case "dateOldest": 
                    sorted = filtered.sort(DateOldest)
                    break;
                case "dateNewest": 
                    sorted = filtered.sort(DateNewest)
                    break;
                case "titleAToZ": 
                    sorted = filtered.sort(TitleAToZ)
                    break;
                case "titleZToA": 
                    sorted = filtered.sort(TitleZToA)
                    break;
                default:
                    sorted = filtered.sort(DateNewest)
                    break;
            }

            setFilteredSpins(sorted)
            setLoadingSpins(false)

        }, [sortFactor, privacyFactor, spins, searchText, setFilteredSpins]
    )

    useEffect(() => {
        console.log(searchText)
        filterSpinList()
    }, [spins, searchText, sortFactor, privacyFactor])

    const handleSearch = useCallback(
        (e) => {
            console.log(e.target.value)
            setSearchText(e.target.value)
        }, [setSearchText]
    )


    const getUserSpins = useCallback(
        async (type) => {

            if (_AppContext.loadingAuth === true) {
                return
            }

            if (_AppContext.isAuth === false) {
                return
            }


        if (type !== 'refresh') {
            setLoadingSpins(true)

        }


        const accessToken = await _AppContext.getAccessTokenSilently({
            audience: `https://dev-backend.prosp.in/`,
          });

          console.log(accessToken)

        fetch(`${_AppContext.baseBackendUrl}/api/Spin/ByUser`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
    
                },
            })
            .then(res => {
                if (res.status === 200) {
                    // console.log(res.json())
                    return res.json()
                }
                throw new Error(`Issue getting ${_AppContext.userId} from Octospin. Code 400007`);
    
            })
            .then(data => {
                console.log(data)
                setSpins(data)
            setLoadingSpins(false)

            })
            .catch(error => {
                // console.log(error.message)
                if (error.message.toLowerCase() === 'failed to fetch') {
                    history.push(`/error?message=Issue connecting to Octospin infrastructure. Code 500010`)

                } else {
                    history.push(`/error?message=${error}`)
                }

        })
        
      
        


    }, [_AppContext.userId, _AppContext.baseBackendUrl, _AppContext.isAuth, _AppContext.loadingAuth])

    useEffect(() => {

        getUserSpins()
        const checkForNewSpins = setInterval(() => {
            getUserSpins('refresh')

        }, 60000)

        return () => {
            clearInterval(checkForNewSpins)
        }
    }, [_AppContext.userId, _AppContext.baseBackendUrl])



    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      };

      useEffect(() => {
          console.log(currentSpinForShareModal)
      }, [currentSpinForShareModal])

      const handleModelClick = useCallback(
          (item) => {
            setCurrentSpinForShareModal(item)
              handleOpen()
          }, []
      )

      const handlePreviewClick = useCallback(
        (item) => {
          setCurrentSpinForPreviewDialog(item)
          handleClickOpenPreviewDialog()
        }, []
    )

    const handleShareClick = useCallback(
        (item) => {
          setCurrentSpinForShareDialog(item)
          handleClickOpenShareDialog()
        }, []
    )


    const renderItem = useCallback(
        (item, id, nickname, imgSrcId) => {


            return (
                <div className="mySpinsListItem">
                    <img src={`https://dev-backend.prosp.in/api/Image/Photo/${imgSrcId}`} height="40px" width="40px"/>
                    <h3>{id}</h3>
                    <h3>{nickname}</h3>
                    <Button 
                    onClick={() => {handlePreviewClick(item)}}
                    >View</Button>
                    <Button component={Link} to={`/edit/${id}`}>Edit</Button>
                    <Button
                    onClick={() => {handleShareClick(item)}}
                    >Share</Button>
                </div>
            )
            
        }, [])

        const [sortOptions, setSortOptions] = useState([
            {value: 'dateNewest', title: 'Date Created | New to Old'},
            {value: 'dateOldest', title: 'Date Created | Old to New'},
            {value: 'titleAToZ', title: 'Title | A to Z'},
            {value: 'titleZToA', title: 'Title | Z to A'},

        ])

        const [privacyOptions, setPrivacyOptions] = useState([
            {value: 'publicAndPrivate', title: 'Show Public & Private'},
            {value: 'publicOnly', title: 'Show Public Only'},
            {value: 'privateOnly', title: 'Show Private Only'},

        ])

        const handleSortSelect = (e) => {
            console.log(e.target.value)
            setSortFactor(e.target.value)
        }

        const handlePrivacySelect = (e) => {
            console.log(e.target.value)
            setPrivacyFactor(e.target.value)
        }

        


    return (
        <div >
            
          
           
            <div className="mySpinsListOverarch">
            <div
            className="mySpinsFilter"
           
            >
                <div id="top" style={{display: 'none'}}>
                    </div>

                    
                    <Accordion
                    className="filterWrapperAccordion"
                    >

{windowDimensions.width <= 799 &&
                    <AccordionSummary
                    className="filterAccordionSummary"
                    expandIcon={<ExpandMore />}
                  > Filters
                  </AccordionSummary>
                    }
        
        <AccordionDetails>
        <div className="filterActionsWrapper">
                

                

                <div>
                    <TextField 
                    id="outlined-basic" 
                    label="Search" 
                    variant="outlined" 
                    size="small"
                    onChange={handleSearch}
                    value={searchText}
                    />
                </div>
                <div>

                <FormControl className="mySpinsFilterSelect" sx={{ m: 1, minWidth: 80 }}>
        <InputLabel >Sort</InputLabel>
        <Select
                    onChange={handleSortSelect}
                    value={sortFactor}
                    size="small"
                    label="Sort"
                    >

                        {sortOptions.map( x => <MenuItem value={x.value} >{x.title}</MenuItem>)}

                    </Select>
                    </FormControl>

                    
                </div>
                <div>
                <FormControl className="mySpinsFilterSelect" sx={{ m: 1, minWidth: 80 }}>
        <InputLabel >Show</InputLabel>
                    <Select
                    onChange={handlePrivacySelect}
                    value={privacyFactor}
                    label="Show"
                    size="small"
                    >

                        {privacyOptions.map( x => <MenuItem value={x.value} >{x.title}</MenuItem>)}

                    </Select>
                    </FormControl>
                </div>
                <div>
                    <FormControlLabel
                        className="iconButtonLabeled"
                        disabled={loadingSpins ? true : false}

                        control={
                            <IconButton
                            id="refresh-button"   
                            size="small"
                            title="Click to refresh spin list"
                            disabled={loadingSpins ? true : false}
                            className="mySpinsRefreshButton"
                            onClick={() => {
                                console.log('click')
                                setLoadingSpins(true)
                                let refreshDelay = setTimeout(() => {
                                    getUserSpins()
                                    clearTimeout(refreshDelay)
                                }, 1000)



                            }}    
                            >
                                <Refresh/>
                            </IconButton>
                        } label="Refresh" />

               
                </div>

                    
                    </div>
        </AccordionDetails>
      </Accordion>
                
                    
                    <div className="mySpinsLabelBar">
                     { ["Preview", "Title", "Public", "Created", "Actions"].map(x => 
                                
                                    <div >
                                    {x}
                                    </div>
                                
                            
                                
                            )
                     }
                        
                    </div>

            </div>
            <div className="mySpinsListWrapper">
                { loadingSpins === false &&
                filteredSpins.length > 0 ? filteredSpins.map( s => 
                <MySpinsItem 
                passed={s} 
                handlePreview={handlePreviewClick}
                handleShare={handleShareClick}
                /> 
                ) : 
                
                <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingTop: '20px'
                }}
                >
                

                { filteredSpins.length <= 0 && loadingSpins === false ?

                    <NoSpinsFoundLoading
                    color="#1976d2"
                    width="24px"
                    height="24px"
                    />
                
                :
                <CircularProgress
                        style={{color: '#1976d2', width: '24px', height: '24px'}}
                        />
                }
                </div>
                }
                </div>
                </div>
            

            { currentSpinForShareModal !== null && 
                <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style}>

                            <div>
                                {currentSpinForShareModal.spin.id}
                            </div>
                        </Box>
                    </Modal>
            }
            { currentSpinForPreviewDialog !== null && 
                <PreviewDialog
                dimensions={{ width: windowDimensions.width - 80, height: windowDimensions.height - 80}}
                id={currentSpinForPreviewDialog.spin.id}
                open={openPreviewDialog}
                onClose={handleClosePreviewDialog}
              />
            }

            { currentSpinForShareDialog !== null && 
                <ShareDialog
                dimensions={{ width: windowDimensions.width - 80, height: windowDimensions.height - 80}}
                spinId={currentSpinForShareDialog.spin.id}
                open={openShareDialog}
                onClose={handleCloseShareDialog}
              />
            }
        


        </div>
    )
};

export default MySpins;